/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNonCachedRoute } from '../js/util';
import ExperienceContext from './ExperienceContext';

const ExperienceProvider = ({ value, children, disableLocalization = false }) => {
  const isServer = !((
    typeof window !== 'undefined'
    && window.document
    && window.document.createElement));

  let initialDeliveryZipValue = null;

  if (isNonCachedRoute(value.path)) {
    initialDeliveryZipValue = value.deliveryZip;
  }

  const [store, setStore] = useState(null);
  const [deliveryZip, setDeliveryZip] = useState(initialDeliveryZipValue);
  const [nearByStores, setNearByStores] = useState([]);
  const [adobeCookie, setAdobeCookie] = useState(null);
  const [mounted, setMounted] = useState(false);
  const [nativeFlags, setNativeFlags] = useState({});

  const cntx = {
    isServer,
    cookie: value.cookie,
    channel: value.channel,
    configService: value.configService,
    hosts: value.hosts,
    path: value.path,
    store: value.store,
    clientStore: value.clientStore,
    segment: value.segment,
    dataType: value.dataType,
    isDebugMode: typeof value.isDebugMode !== 'undefined'
      ? value.isDebugMode
      : false,
    isLocal: value.isLocal,
    customer: value.customer,
    localStoreZip: value.localStoreZip,
    deliveryZip,
    isConsumerApp: value.isConsumerApp,
    mounted,
    nativeFlags,
    nearByStores,
    referer: value.referer
  };

  const handleVisibilityChange = () => {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('page.visibilitychange');
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const Localizer = window?.THD_LOCALIZER_AUTO_INIT?.Localizer;
    const localize = Localizer?.localize;
    const { DELIVERY_ZIP_UPDATED, LOCALIZED } = Localizer?.event || {};

    if (!localize || disableLocalization) {
      return;
    }

    const expHandleLocalization = (methods) => {
      const newStore = methods?.getLocalizedStore();
      const newDeliveryZip = Localizer.getDeliveryZipcodeDetails().zipcode;
      if (newStore) {
        setStore({
          storeHours: newStore.storeHours,
          storeId: newStore.storeId,
          storeName: newStore.name,
          storeZip: newStore.zipcode
        });
      }
      if (newDeliveryZip) setDeliveryZip(newDeliveryZip);
      return methods;
    };

    localize().then(expHandleLocalization);

    const deliveryZipCallback = ({ output }) => {
      const newDeliveryZip = output?.zipCode;
      if (newDeliveryZip) setDeliveryZip(newDeliveryZip);
    };

    const nearByStoresCallback = ({ output }) => {
      const updatedNearByStores = output?.nearByStores;
      if (updatedNearByStores) setNearByStores(updatedNearByStores);
    };

    const storeInfoCallback = ({ output }) => {
      const newStoreId = output?.storeID;
      const nativeNearByStores = output?.nearByStores;
      setNearByStores(nativeNearByStores);
      const { getLocalizedStore } = window.THD_LOCALIZER_AUTO_INIT?.Localizer || {};
      if (newStoreId && newStoreId !== getLocalizedStore().storeId) {
        localize({ storeId: newStoreId })
          .then(expHandleLocalization)
          .then(() => {
            window.location.reload();
          });
      }
    };
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('experience-context.ready', cntx);

    LIFE_CYCLE_EVENT_BUS.lifeCycle.on('NATIVE_APP.STORE_INFO', storeInfoCallback);
    LIFE_CYCLE_EVENT_BUS.lifeCycle.on('NEARBY_STORES.update', nearByStoresCallback);
    LIFE_CYCLE_EVENT_BUS.lifeCycle.on('NATIVE_APP.DELIVERY_ZIP', deliveryZipCallback);
    Localizer.on([DELIVERY_ZIP_UPDATED, LOCALIZED], expHandleLocalization);

    return () => {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.off('NATIVE_APP.STORE_INFO', storeInfoCallback);
      LIFE_CYCLE_EVENT_BUS.lifeCycle.off('NEARBY_STORES.update', nearByStoresCallback);
      LIFE_CYCLE_EVENT_BUS.lifeCycle.off('NATIVE_APP.DELIVERY_ZIP', deliveryZipCallback);
      Localizer.off([DELIVERY_ZIP_UPDATED, LOCALIZED], expHandleLocalization);
    };
  }, []);

  useEffect(() => {
    try {
      const mcsvid = window.THDCustomer?.default?.mcvisID

      const ADOBE_COOKIE = {
        'MCMID': mcsvid
      };
      setAdobeCookie(ADOBE_COOKIE);
    } catch (error) {
      setAdobeCookie({});
    }
  }, []);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (value.isConsumerApp) {
      const setNativeAppFlagsCallback = ({ output }) => {
        const newNativeFlags = output?.nativeFlags;
        setNativeFlags({
          ...(nativeFlags || {}),
          ...(newNativeFlags || {}),
        });
      };

      LIFE_CYCLE_EVENT_BUS.on('setNativeAppFlags', setNativeAppFlagsCallback);

      return () => {
        LIFE_CYCLE_EVENT_BUS.off('setNativeAppFlags', setNativeAppFlagsCallback);
      };
    }
  }, [value.isConsumerApp, nativeFlags]);

  if (store) {
    cntx.clientStore = store;
  }
  if (nearByStores) {
    cntx.nearByStores = nearByStores;
  }
  if (adobeCookie) {
    if (!cntx.cookie) {
      cntx.cookie = {};
    }
    cntx.cookie.adobeCookie = adobeCookie;
  }

  return (
    <ExperienceContext.Provider value={cntx}>
      {children}
    </ExperienceContext.Provider>
  );
};

ExperienceProvider.propTypes = {
  value: PropTypes.shape([
    PropTypes.any,
    PropTypes.node
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  disableLocalization: PropTypes.bool
};

ExperienceProvider.defaultProps = {
  value: null,
  children: null,
  disableLocalization: false
};

export default ExperienceProvider;
