import digitalData from 'digital-data';
import { RECOMMENDATION_VERSION, STASH, NOT_APPLICABLE } from '../constants';

export const endcap = () => {
  const publishAnalytics = (output, apiType) => {
    const displayPosition = Number(output) + 1;
    const { pageType } = digitalData?.page?.pageInfo;
    const ddoEvent = {
      category: {
        primaryCategory: STASH,
      },
      eventInfo: {
        eventName: 'recommendation pip load',
      },
      eventMode: STASH,
      stash: {
        recommendation: {
          recommendationType: apiType,
          pageType,
          displayPosition,
          anchorProductSku: NOT_APPLICABLE,
          strategy: NOT_APPLICABLE,
          recommendationVersion: RECOMMENDATION_VERSION,
          featureVersion: NOT_APPLICABLE,
          interval: NOT_APPLICABLE,
        },
      },
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  };

  window.LIFE_CYCLE_EVENT_BUS.on('topDeals.click', ({ output = {} }) => {
    publishAnalytics(output, 'top deals');
  });

  window.LIFE_CYCLE_EVENT_BUS.on('personalizedDeals.click', ({ output = {} }) => {
    publishAnalytics(output, 'personalized deals');
  });
};