import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';
import { NUCLEUS, KITCHEN_VISUALIZER, BUTTON_SUBMISSION, ERROR } from '../../../constants';

export const KITCHEN_EVENTS = {
  DOWNLOAD: 'download',
  EMAIL: 'email',
  GENERATE_IMAGE: 'generate-image',
  SCHEDULE_APPOINTMENT: 'schedule-appointment',
};

const getPageNameForId = (pageId) => {
  if (typeof pageId !== 'string' || pageId === '') {
    return KITCHEN_VISUALIZER;
  }
  return `${KITCHEN_VISUALIZER}>${pageId.toLocaleLowerCase()}`;
};

const getSessionType = (refId) => {
  if (typeof refId !== 'string' || refId === '') {
    return 'anonymous';
  }
  return 'referred';
};

const publishEvent = (event) => {
  digitalData.event = digitalData.event || [];
  digitalData.event.push(event);
};

const handlePageLoad = ({ output = {} }) => {
  const {
    pageId,
    refId,
  } = output;

  digitalData.page = {
    pageInfo: {
      pageName: getPageNameForId(pageId),
      pageLoadEvent: 'content',
      platform: NUCLEUS,
      pageType: 'content',
    },
    category: {
      primaryCategory: KITCHEN_VISUALIZER,
      subCategory1: KITCHEN_VISUALIZER,
      subCategory2: getSessionType(refId),
    }
  };

  setAnalytics(digitalData);
};

const handleEvent = ({ output = {} }) => {
  const {
    analyticsEventName,
    refId,
    styleId,
    customizationData,
  } = output;

  const event = {
    category: {
      primaryCategory: BUTTON_SUBMISSION,
      subCategory1: analyticsEventName,
      subCategory2: getSessionType(refId),
    },
    eventInfo: {
      eventName: output.analyticsEventName,
    },
  };

  switch (analyticsEventName) {
  case KITCHEN_EVENTS.DOWNLOAD:
    break;
  case KITCHEN_EVENTS.EMAIL:
    break;
  case KITCHEN_EVENTS.GENERATE_IMAGE:
    event.generation = {
      styleId: styleId || '',
      customizationData: customizationData || '',
    };
    break;
  case KITCHEN_EVENTS.SCHEDULE_APPOINTMENT:
    break;
  default:
    console.warn('Unable to find kitchen analytics event of \'' + analyticsEventName + '\'');
    return;
  }

  publishEvent(event);
};

const handleError = ({ output = {} }) => {
  const {
    message,
    code,
  } = output;
  const event = {
    category: {
      primaryCategory: ERROR,
    },
    eventInfo: {
      eventName: 'kitchen visualizer error',
      error: [{
        displayType: 'advisory',
        pageSection: KITCHEN_VISUALIZER,
        message,
        code,
      }]
    }
  };
  publishEvent(event);
};

export function kitchenVisualizer() {
  window.LIFE_CYCLE_EVENT_BUS.on('kitchen-visualizer.page-load', handlePageLoad);
  window.LIFE_CYCLE_EVENT_BUS.on('kitchen-visualizer.event', handleEvent);
  window.LIFE_CYCLE_EVENT_BUS.on('kitchen-visualizer.error', handleError);
}
