import digitalData from 'digital-data';
import { PIP_SUB_CONTENT, INTERACTION, NOT_APPLICABLE } from '../constants';

export const augmentedReality = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('augmented-reality.click', () => {
    const ddoEvent = {
      eventInfo: {
        eventName: PIP_SUB_CONTENT
      },
      category: {
        primaryCategory: INTERACTION
      },
      subContent: {
        section: 'see it in your space',
        component: 'augmented reality',
        target: NOT_APPLICABLE
      },
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};