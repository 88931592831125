import digitalData from 'digital-data';
import {
  PIP,
  INTERACTION
} from '../constants';

export const collectionList = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('collection-list.interaction', ({ output = {} }) => {
    const { item } = output;
    digitalData.event.push({
      category: {
        primaryCategory: INTERACTION
      },
      eventInfo: {
        eventName: 'product options'
      },
      pageType: PIP,
      item
    });
  });

  window.LIFE_CYCLE_EVENT_BUS.on('collection-list.data', ({ output = {} }) => {
    const { products } = output;

    digitalData.content = digitalData.content || {};
    digitalData.content.product = (products || []).map((product) => {
      return {
        productInfo: {
          sku: product?.itemId,
          position: product?.podPosition
        }
      };
    });
  });
};