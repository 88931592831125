import digitalData from 'digital-data';

const getProductInfoArray = (itemIds = []) => (
  itemIds.map((itemId) => ({
    productInfo: {
      sku: itemId
    }
  }))
);

const reportSpecialBuyCategory = ({ output: itemIds }) => {
  const products = getProductInfoArray(itemIds);
  const thdAnalyticsEvent = {
    category: {
      primaryCategory: 'interaction'
    },
    eventInfo: {
      eventName: 'category click'
    },
    content: {
      product: products
    }
  };

  if (!digitalData.content?.product) {
    digitalData.content = {
      ...digitalData.content,
      product: products
    };
  } else if (Array.isArray(digitalData.event)) {
    digitalData.event.push(thdAnalyticsEvent);
  } else {
    digitalData.event = [thdAnalyticsEvent];
  }
};

export const specialBuyPromos = () => {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('special-buy-promos.category', reportSpecialBuyCategory);
};