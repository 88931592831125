import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';
import { ERROR, INSTANT_CHECKOUT } from '../../../constants';

// https://github.com/one-thd/ocm_pd_experience_customer-account-pojob-proallowance
export function proAllowancePage() {
  const trackEvent = (event) => {
    digitalData.event = digitalData.event || [];
    digitalData.event.push(event);
  };

  const proallowanceEvents = {
    error: ({ output }) => {
      const {
        type, displayType, pageSection, message, code
      } = output;

      digitalData.page = {
        error: {
          type,
          displayType,
          pageSection,
          message,
          code,
        },
      };
      setAnalytics(digitalData);
    },

    postPageErrors: ({ output }) => {
      const DDOEvent = {
        category: {
          primaryCategory: ERROR,
        },
        eventInfo: {
          eventName: 'post page error',
          error: [
            {
              ...output,
            },
          ],
        },
      };
      trackEvent(DDOEvent);
    }
  };

  window.LIFE_CYCLE_EVENT_BUS.on('pro-allowance.page-load-error', proallowanceEvents.error);
  window.LIFE_CYCLE_EVENT_BUS.on('pro-allowance.post-page-error', proallowanceEvents.postPageErrors);
}
