import digitalData from 'digital-data';

const reportSearchStore = () => {
  const thdAnalyticsEvent = {
    category: {
      primaryCategory: 'button submission'
    },
    eventInfo: {
      eventName: 'find a store'
    }
  };

  digitalData.event = digitalData.event || [];
  digitalData.event.push(thdAnalyticsEvent);
};

export const storeSearch = () => {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('store-search.search', reportSearchStore);
};