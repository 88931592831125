import React, { useState, useEffect, useContext } from 'react';
import { shape, string } from 'prop-types';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { useHeaderObserver } from '@thd-olt-functional/utils';
import { displayComponent } from './dataService';
import {
  setCheckoutAnalytics,
  publishEvent,
  isCheckoutPage
} from './util';

const CheckoutPrompts = ({
  parentDisplayInfo = null
}) => {
  const {
    channel,
    customer
  } = useContext(ExperienceContext);
  const [entry] = useHeaderObserver();

  const checkoutPromptsKillSwitch = useConfigService('fs:checkoutPromptsKillSwitch');
  const shouldRender = (
    channel === 'mobile'
    && customer?.type === 'b2c'
    && !isCheckoutPage()
    && !checkoutPromptsKillSwitch
  );
  const [displayInfo, setDisplayInfo] = useState(parentDisplayInfo || null);

  useEffect(() => {
    if (!parentDisplayInfo && shouldRender) {
      displayComponent(setDisplayInfo);
    }
  }, []);

  if (parentDisplayInfo || (shouldRender && (displayInfo && !displayInfo.displayedTime))) {
    let marginBottom = '0px';
    if (entry?.visible && entry.navigation?.positionBottom) {
      marginBottom = `${entry.navigation.clientRect?.height}px`;
    }

    return (
      <div
        id="checkout-prompts-container"
        className={
          'sui-flex sui-flex-col sui-gap-3 sui-gap sui-w-full sui-bottom-0 sui-left-0 sui-fixed'
          + ' sui-bg-primary sui-p-3 sui-duration-500 sui-transition-[margin-bottom]'
        }
        data-component="CheckoutPrompts"
        style={{ boxShadow: '0 -2px 10px rgba(0,0,0,.2)', marginBottom, zIndex: '99999999' }}
      >
        <div className="sui-w-full sui-flex sui-flex-row">
          <div>
            <img
              height="71px"
              width="71px"
              className="sui-w-full"
              src={displayInfo.image}
              alt="Product"
              style={{ maxWidth: '75px' }}
            />
          </div>
          <div className="sui-pb-3 sui-w-full">
            <div className="sui-flex">
              <h3 className="sui-pb-3 sui-font-bold sui-text-3xl">Ready to checkout?</h3>
              <button
                type="button"
                className={
                  'sui-p-3 sui-m-0 sui-float-right sui-cursor-pointer'
                  + ' sui-z-10 sui-inline-block sui-ml-auto sui-fill-brand'
                }
                onClick={() => {
                  publishEvent({
                    name: 'Checkout_Prompt_Dismissed'
                  });
                  setDisplayInfo(null);
                }}
              >
                <img
                  height="20px"
                  width="20px"
                  src="https://assets.homedepot-static.com/images/v1/close-symbol-orange.svg"
                  alt="Close"
                />
              </button>
            </div>
            <div>{`You have ${displayInfo.cartCount} items in your cart`}</div>
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="bttn bttn--primary"
            onClick={(event) => {
              event.preventDefault();
              setCheckoutAnalytics();
              window.location.href = '/cart';
            }}
          >
            <span className="bttn__content">Checkout {displayInfo.cartTotal}</span>
          </button>
        </div>
        <div className="sui-text-center sui-pt-0">Taxes and delivery charges are calculated during checkout</div>
      </div>
    );
  }

  return null;
};

CheckoutPrompts.propTypes = {
  parentDisplayInfo: shape({
    image: string,
    cartCount: string,
    cartTotal: string,
    orderId: string,
    customerId: string,
    userId: string,
  })
};

CheckoutPrompts.displayName = 'CheckoutPrompts';

export { CheckoutPrompts };