import digitalData from 'digital-data';

export const layoutManager = () => {

  window.LIFE_CYCLE_EVENT_BUS.on('layout-manager.error', ({ output }) => {
    const {
      error,
      name
    } = output || {};
    let errorType = 'inline';
    if (!/reactjs/i.test(error?.message)) {
      if (typeof window.__SSR_ERROR !== 'undefined' && window.__SSR_ERROR) {
        errorType = 'page';
      }
      const pageErrors = [];
      const errorDetails = {
        eventName: 'post page error',
        type: 'error',
        displayType: errorType,
        pageSection: name,
        message: error?.message,
        code: null,
        output
      };
      pageErrors.push(errorDetails);
      const ddoEvent = {
        category: {
          primaryCategory: 'error'
        },
        eventInfo: {
          eventName: 'post page error',
          error: pageErrors
        }
      };
      digitalData.event = digitalData.event || [];
      (digitalData.event).push(ddoEvent);
    }
  });

};
