import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';
import { CONTENT, OPT_OUT } from '../../../constants';

export function optOut() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('optOut.page-load', () => {
    const pageName = OPT_OUT;
    const pageType = CONTENT;
    const primaryCategory = OPT_OUT;
    const subCategory1 = OPT_OUT;
    const subCategory2 = OPT_OUT;

    digitalData.page = {
      pageInfo: {
        pageName,
        pageType,
      },
      category: {
        primaryCategory,
        subCategory1,
        subCategory2,
      },

    };

    setAnalytics(digitalData);
  });
}