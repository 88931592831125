import digitalData from 'digital-data';
import { BUTTON_SUBMISSION } from '../constants';

export const homeServicesCollection = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('COLLECTION-HOME-SERVICE/DUAL-PATH-CTA', ({ output = {} }) => {
    const dualPathCtaEvent = {
      category: { primaryCategory: BUTTON_SUBMISSION },
      eventInfo: { eventName: 'request a quote' },
      form: { formName: output?.programCategory, formType: 'installation services and repair' },
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(dualPathCtaEvent);
  });
};