import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';
import { NUCLEUS } from '../../../constants';

const reportWorkshopsLoad = () => {
  digitalData.page = {
    category: {
      primaryCategory: 'How To',
      subCategory1: 'How To>Free Weekly Workshops & Home Improvement Workshop',
      subCategory2: 'How To>Free Weekly Workshops & Home Improvement Workshop'
    },
    pageInfo: {
      pageName: 'How To>Free Weekly Workshops & Home Improvement Workshop',
      pageType: 'tool:workshop:landing',
      platform: NUCLEUS
    }
  };

  setAnalytics(digitalData);
};

export const workshops = () => {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('nucleus-exp-workshops.page-load', reportWorkshopsLoad);
};