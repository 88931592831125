import digitalData from 'digital-data';
import {
  BUTTON_SUBMISSION, MY_ACCOUNT_SETTINGS, STASH, ACCOUNT_DASHBOARD_CLICK, PROJECTS_ENTRY_POINT
} from '../constants';

export const projectSummary = () => {
  const PROJECT_SUMMARY_VIEW_TYPE = 'projects: project summary view type';
  const PROJECT_SORT_AND_SEARCH = 'projects: sort and search';
  const PROJECT_PAGINATION = 'projects: pagination';

  // 7.51.7 Projects Card or Table View
  LIFE_CYCLE_EVENT_BUS.on(PROJECT_SUMMARY_VIEW_TYPE, ({ output }) => {
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS,
      },
      myAccount: {
        settings: output?.viewType
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.8 Projects Sort and Search
  LIFE_CYCLE_EVENT_BUS.on(PROJECT_SORT_AND_SEARCH, ({ output }) => {
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS,
      },
      myAccount: {
        settings: output?.filterType,
        searchTerm: output?.searchFormat,
        searchBy: output?.searchValue
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.9 Projects Page Pagination
  LIFE_CYCLE_EVENT_BUS.on(PROJECT_PAGINATION, ({ output }) => {
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS,
      },
      myAccount: {
        settings: output?.paginationType,
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.24 Projects Entry Points
  LIFE_CYCLE_EVENT_BUS.on(PROJECTS_ENTRY_POINT, ({ output }) => {
    const ddoEvent = {
      category: {
        primaryCategory: STASH,
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS,
      },
      eventMode: STASH,
      stash: {
        myAccount: {
          settings: `${PROJECTS_ENTRY_POINT}: ${ACCOUNT_DASHBOARD_CLICK}: ${output?.entryPoint}`,
        },
      },
    };
    digitalData.event.push(ddoEvent);
  });
};