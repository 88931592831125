import React from 'react';

export const sanitize = (obj, type = 'Manager') => {
  const children = Array.isArray(obj.children)
    ? obj.children.filter((child) => child)
    : [obj.children].filter((child) => child);

  const ret = {};
  ret.type = type;
  ret.name = obj.name || (obj.props || {}).name || `Anonymous ${type}`;

  if (ret.name === 'Anonymous Group') return null;

  ret.props = {
    ...obj.props
  };

  Object.keys(ret.props).forEach((propName) => {
    if (typeof ret.props[propName] === 'function') {
      ret.props[propName] = null;
    }
  });

  if (type === 'Manager') {
    ret.input = obj.input;
  }

  delete ret.props.children;
  if (type === 'Manager') {
    ret.groups = (children || []).map((child) => sanitize(child, 'Group')).filter((val) => val);
  }
  return ret;
};

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {//eslint-disable-line
    let r = Math.random() * 16 | 0; let v = c == 'x' ? r : (r & 0x3 | 0x8);//eslint-disable-line
    return v.toString(16);
  });
};

export const fsToRules = (expConfig) => {
  if (!expConfig) return [];

  const fields = Object.keys(expConfig)
    .filter((key) => (/^fs-/.test(key)))
    .map((key) => {
      let val = expConfig[key];
      if (val === 'true') val = true;
      if (val === 'false') val = false;
      if (/^\d+$/.test(val)) val = parseInt(val, 10);
      if (/^\d+\.\d+$/.test(val)) val = parseFloat(val);

      if (/^fs-disable/.test(key)) {
        const parts = /^fs-disable:([\w-]+)__([\w-]+)/.exec(key);
        if (parts && parts.length === 3) {
          const [, managerName, groupName] = parts;
          return {
            type: 'disable',
            key,
            value: val,
            managerName,
            groupName
          };
        }
      }

      if (/^fs-prop/.test(key)) {
        const parts = /^fs-prop:([\w-]+)__([\w-]+)--(\w+)/.exec(key);
        if (parts && parts.length === 4) {
          const [, managerName, groupName, propName] = parts;
          return {
            type: 'prop',
            key,
            value: val,
            managerName,
            groupName,
            propName
          };
        }
      }

      return null;
    })
    .filter((val) => val);
  return fields;
};
