/* global newrelic */
export const componentSelectorListener = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('component-selector.selected', ({ output }) => {
    const {
      renderDefault, isVariation, componentName, componentId, isSSR, isSeoBot, noVariations, loadTime
    } = output;
    if (typeof componentId !== 'undefined') {
      newrelic.addPageAction('ComponentSelectorRenderData', {
        cs_renderDefault: renderDefault,
        cs_isVariation: isVariation,
        cs_componentName: componentName,
        cs_componentId: componentId,
        cs_isSSR: isSSR,
        cs_isSeoBot: isSeoBot,
        cs_noVariations: noVariations,
        cs_loadTime: loadTime
      });
    }
  });
};
