/* eslint-disable no-plusplus */
let traceId = '0';
if (typeof window !== 'undefined' && window.crypto && window.crypto.randomUUID) {
  traceId = window.crypto.randomUUID().split('-').join('');
}
/*
 * SpandId start index should start from the index 1, OpenTelemetry Context Propagator
 * is expecting a strict pattern for x-cloud-trace-context. which is
 * (?<traceid>[0-9a-f]{32})\/(?<spanid>[\d]{1,20});o=(?<sampled>\d+)
 *
 */
let spanId = 1;

export const getClientTraceId = () => {
  /*
   * OpenTelemetry Context Propagator is expecting a strict pattern for x-cloud-trace-context. which is
   * (?<traceid>[0-9a-f]{32})\/(?<spanid>[\d]{1,20});o=(?<sampled>\d+)
   * so appending the ";o=1" to the traceid so it will
   * be propagated to the API. Instead of writing a custom text formatter for the
   * traceid in each API application layer, it's easier to append it here.
   */
  return `${traceId}/${spanId++};o=1`;
};

export const initializeTraceID = () => {
  return traceId;
};
