import digitalData from 'digital-data';
import { BROWSE, SEARCH, FILTER } from '../constants';
import { getState, setState } from '../analytics-state';
import { getCategoryBreadCrumbs, getHeaderCrumb } from '../thd-analytics-utils';

export const getFiltersRefinementsAnalytics = ({ breadCrumbs, searchReport = {}, pageFilters = [] }) => {
  let { sortOrder = '', sortBy, startIndex, keyword } = searchReport || {};
  const pageNumber = (startIndex / 24) + 1;
  const { filters = [] } = getState();
  const digitalDataPages = digitalData?.page.displayAttributes;
  digitalData.page.category = getCategoryBreadCrumbs(breadCrumbs) || {};
  if (!digitalData.page.category.headerCrumb) {
    const categoryCrumb = breadCrumbs?.[0]?.dimensionName
      ? breadCrumbs.filter((crumb) => crumb?.dimensionName.toLowerCase() === 'category') : breadCrumbs;
    digitalData.page.category.headerCrumb = getHeaderCrumb(categoryCrumb);
  }

  function filterItems() {
    let crumbs = [];
    if (pageFilters.length) {
      crumbs = (breadCrumbs || []).filter((crumb) => {
        const { dimensionName, label } = crumb;
        return pageFilters.some((pageFilter) => {
          const { type, value } = pageFilter;
          return (
            type?.toLowerCase() === dimensionName?.toLowerCase()
            && value?.toLowerCase() === label?.toLowerCase()
          );
        });
      });
    } else if (filters.length > 0) {
      crumbs = filters;
    } else {
      crumbs = (breadCrumbs || []).filter((crumb) => {
        return crumb?.deselectUrl && crumb?.dimensionName;
      });
    }
    const selectedFilters = [];
    crumbs.forEach((crumb = {}, index) => {
      if (!crumb?.dimensionName) return;

      const lowercaseDimensionLabel = crumb?.dimensionName?.toLowerCase();
      const lowercaseRefinementLabel = crumb?.label?.toLowerCase();
      const refinementIndex = crumb?.refinementIndex || null;

      selectedFilters.push({
        type: lowercaseDimensionLabel,
        value: lowercaseRefinementLabel,
        position: crumb.position || `left rail:${lowercaseDimensionLabel}:${refinementIndex || index + 1}`,
        selectionMethod: crumb.selectionMethod || 'all'
      });
    });

    return selectedFilters;
  }

  switch (sortBy?.toUpperCase()) {
  case 'PRICE':
    if (sortOrder.toUpperCase() === 'ASC') {
      sortBy = 'price low to high';
    } else if (sortOrder.toUpperCase() === 'DESC') {
      sortBy = 'price high to low';
    }
    break;
  case 'MOSTPOPULAR':
    sortBy = 'most popular';
    break;
  case 'TOPSELLERS':
    sortBy = 'top sellers';
    break;
  case 'TOPRATED':
    sortBy = 'top rated products';
    break;
  case 'BESTMATCH':
    sortBy = 'best match';
    break;
  default:
    sortBy = '';
  }

  const ddoEvent = {
    category: {
      primaryCategory: keyword ? SEARCH : BROWSE
    },

    displayAttributes: {
      pageNumber,
      productsAvailability: (digitalDataPages) ? digitalDataPages?.productsAvailability : 'in store',
      sortBy,
      viewType: (digitalDataPages) ? digitalDataPages?.viewType : 'grid'
    },

    eventInfo: {
      eventName: 'filter by'
    },

    filter: filterItems() || [],

    isReported: true,
    sourceIndex: 0
  };

  return ddoEvent;
};

export const filtersAndRefinements = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('filters-refinements.publish', ({ output = {} }) => {
    digitalData.event.push(getFiltersRefinementsAnalytics(output));
  });

  window.LIFE_CYCLE_EVENT_BUS.on('filters-refinements.click', function ({ output = {} }) {
    let { filters = [] } = getState();
    if (output?.clear) {
      filters = [];
    } else if (output?.dimensionName && filters.findIndex((filter) => filter.label === output?.label) === -1) {
      filters.push(output);
    } else {
      filters = filters.filter((filter) => filter.label !== output?.label);
    }
    setState({
      ...getState(),
      pageTransitionEvent: FILTER,
      filters
    });
  });
};