import digitalData from 'digital-data';
import { getState, setState } from '../../../analytics-state';
import {
  PEP,
  PAGE_AB_TEST,
  PRODUCTS_AVAILABILITY,
  PLP_GRID,
  NUCLEUS,
  NO_EVENT,
  PAGINATION,
  FILTER,
  SORT
} from '../../../constants';
import {
  getContentProducts,
  setAnalytics,
  getProductAnalytics,
  getCategoryBreadCrumbs,
  getCurrentPage,
  shouldReInitAnalytics,
  getFilters
} from '../../../thd-analytics-utils';
import { productPromise, browsePromise } from './pep.utils';

export function pep() {
  let cachedPepModel;
  let cachedProduct;

  const initPEP = () => {
    Promise.all([productPromise({ cachedProduct }), browsePromise({ cachedPepModel })]).then((dataList) => {
      if (dataList?.length < 2) return;
      const assignedData = dataList.reduce((acc, data, index) => {
        const newAcc = acc;
        newAcc[data?.__typename || index] = data;
        return newAcc;
      }, {});

      const { BaseProduct: product, SearchModel: pepModel } = assignedData;
      const { breadCrumbs = [] } = pepModel?.taxonomy || {};
      const { products, searchReport, metadata, defaultPageSize } = pepModel || {};
      const { startIndex, pageSize, sortBy } = searchReport || {};
      const { pageType = '', pageTransitionEvent = NO_EVENT } = getState() || {};

      if (products?.length) {
        digitalData.content = {
          ...digitalData.content,
          product: getContentProducts(products, pageType) || [],
          feature: {
            plpModule: products?.length ? 'y' : 'n'
          }
        };
      }

      const currentPage = getCurrentPage(startIndex, pageSize, defaultPageSize);
      
      setState({
        ...getState(),
        pageTransitionEvent: undefined,
        pageSize,
        currentPage
      });

      if (!shouldReInitAnalytics(pageTransitionEvent)) {
        if (pageTransitionEvent === FILTER) {
          LIFE_CYCLE_EVENT_BUS.trigger('filters-refinements.publish', {
            breadCrumbs,
            searchReport,
            metadata,
            pageFilters: getFilters({ appliedDimensions: pepModel?.appliedDimensions })
          });
        } else if (pageTransitionEvent === SORT) {
          LIFE_CYCLE_EVENT_BUS.trigger('sort-refinements.publish');
        }
        return;
      }

      digitalData.page = {
        pageInfo: {
          pageName: 'product entry page>' + product?.identifiers?.itemId,
          pageLoadEvent: pageTransitionEvent === PAGINATION ? PAGINATION : 'pep view',
          pageType: PEP,
          platform: NUCLEUS,
          abTest: PAGE_AB_TEST
        },
        category: getCategoryBreadCrumbs(breadCrumbs) || {},
        displayAttributes: {
          pageNumber: getCurrentPage(startIndex, pageSize, defaultPageSize),
          productsAvailability: PRODUCTS_AVAILABILITY,
          sortBy,
          viewType: PLP_GRID
        },
        filter: getFilters({ appliedDimensions: pepModel?.appliedDimensions })
      };

      // let serviceAddons; // Todo: Kalee killed the service addons, will fix it
      // if (info.hasServiceAddOns) {
      //   serviceAddons = yield selectState('dataSources.serviceAddons', { timeout: 3000 });
      // }

      digitalData.product = getProductAnalytics({
        product
      });

      digitalData.event = digitalData.event || [];

      setAnalytics(digitalData);
    });
  };

  initPEP();

  const onEventClick = ({ skipProduct = false, skipBrowse = false } = {}) => {
    initPEP(productPromise({ skipProduct, cachedProduct }), browsePromise({ skipBrowse, cachedPepModel }));
  };

  window.LIFE_CYCLE_EVENT_BUS.on('pagination.click', () => {
    onEventClick({ skipProduct: true });
  });
  window.LIFE_CYCLE_EVENT_BUS.on('filters-refinements.click', () => {
    onEventClick({ skipProduct: true });
  });
  window.LIFE_CYCLE_EVENT_BUS.on('sort-refinements.click', () => {
    onEventClick({ skipProduct: true });
  });
  window.LIFE_CYCLE_EVENT_BUS.on('super-sku.click-swatch', () => {
    onEventClick({ skipBrowse: true });
  });
}