import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import {
  STASH,
  MODULE_CLICK,
  BUTTON_SUBMISSION,
  NOT_APPLICABLE,
  ATC,
  CART_ADD,
  PRODUCT_POD,
  CART_ADD_LOCATION,
  ADD_TO_CART_CLICK
} from '../constants';

export const bathRenovation = () => {

  window.LIFE_CYCLE_EVENT_BUS.on('bath-renovation-design.click', ({ output = {} }) => {
    const {
      position,
      templateId,
      templateName,
      element,
      designStudioID
    } = output;
    const state = getState();
    const { pageType } = state || {};
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: MODULE_CLICK
      },
      bathroomDesignStudioID: designStudioID,
      pageType,
      content: {
        widgets: [
          {
            action: NOT_APPLICABLE,
            id: templateId,
            name: templateName,
            templates: NOT_APPLICABLE,
            component: position,
            element
          }
        ]
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('bath-renovation.add-to-cart', () => {
    const { pageType = '' } = getState() || {};

    const ddoEvent = {
      eventInfo: {
        eventName: CART_ADD_LOCATION
      },
      category: {
        primaryCategory: STASH
      },
      eventMode: STASH,
      stash: {
        component: PRODUCT_POD,
        pageType,
        section: 'product',
        sharedSection: NOT_APPLICABLE,
        target: ATC
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('bath-renovation.add-to-cart-success', ({ output = {} }) => {
    const {
      products = [],
    } = output;
    const items = products.map((product) => ({
      productInfo: {
        sku: product.itemId,
        partnerCompany: 'made'
      },
      quantity: product.quantity,
      price: {
        basePrice: product.price
      },
      fulfillment: {
        method: 'sth',
        store: NOT_APPLICABLE,
        zip: product.zipCode
      }
    }));
    const ddoEvent = {
      category: {
        primaryCategory: ADD_TO_CART_CLICK,
      },
      eventInfo: {
        eventName: CART_ADD,
      },
      item: items
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};
