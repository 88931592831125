import digitalData from 'digital-data';
import { STASH } from '../constants';

export const relatedSearch = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('related-search.click', ({ output }) => {
    const ddoEvent = {
      eventInfo: {
        eventName: 'related term',
      },
      eventMode: STASH,
      category: {
        primaryCategory: STASH,
      },
      stash: {
        searchMedium: 'related term',
        searchTerm: output,
      },
    };

    if (digitalData && digitalData.event) digitalData.event.push(ddoEvent);
  });
};