import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import {
  BUTTON_SUBMISSION,
  CLICKTHROUGH,
  INTERACTION,
  MODULE_CLICK,
  NOT_APPLICABLE,
  PIP_SUB_CONTENT,
  STASH,
  SUBCONTENTDECOR
} from '../constants';

export const productDetails = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('product-details.click', ({ output = {} }) => {

    const { element: clickedElement = 'n/a' } = output;
    let component = 'product details';
    let element = NOT_APPLICABLE;
    const { pageType } = getState() || {};
    let ddoEvent;
    // @todo need analytics to validate this business logic
    // @todo streamline this file, there has to be a better way to do this logic
    // (also there is duplicate primaryCategories in star rating and media)
    let primaryCategory = BUTTON_SUBMISSION;

    if (pageType === 'pep') {
      primaryCategory = STASH;
    }
    if (clickedElement === 'questions and answers') {
      primaryCategory = INTERACTION;
      component = 'bazzarvoice';
    }
    if (clickedElement === 'star rating') {
      primaryCategory = BUTTON_SUBMISSION;
      component = clickedElement;
    }
    if (clickedElement === 'media') {
      primaryCategory = BUTTON_SUBMISSION;
      component = clickedElement;
      element = 'gallery';
    }
    if (clickedElement === 'brandRating') {
      component = 'brand rating';
    }
    if (clickedElement === 'writeAReview') {
      component = 'write a review';
    }
    if (clickedElement === 'clickable brand link') {
      primaryCategory = INTERACTION;
      component = 'clickable brand link';
    }

    const target = clickedElement === 'questions and answers'
      ? 'questions-and-answers-link'
      : NOT_APPLICABLE;

    switch (primaryCategory) {
    case STASH: {
      ddoEvent = {
        eventInfo: {
          eventName: MODULE_CLICK
        },
        category: {
          primaryCategory: STASH
        },
        eventMode: STASH,
        stash: {
          pageType,
          content: {
            widgets: [{
              action: CLICKTHROUGH,
              id: 'null',
              name: SUBCONTENTDECOR,
              templates: 'product marquee',
              component,
              element: clickedElement
            }]
          },
        }
      };
      break;
    }
    case BUTTON_SUBMISSION: {
      ddoEvent = {
        eventInfo: {
          eventName: 'ugc click'
        },
        category: {
          primaryCategory: BUTTON_SUBMISSION
        },
        content: {
          ugc: [{
            module: 'zone a',
            component,
            element,
          }]
        },
      };
      break;
    }

    case INTERACTION: {
      ddoEvent = {
        eventInfo: {
          eventName: PIP_SUB_CONTENT
        },
        category: {
          primaryCategory: INTERACTION
        },
        subContent: {
          section: 'buybox',
          component,
          target,
          position: NOT_APPLICABLE
        }
      };
      break;
    }
    default:
      break;
    }
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('product-details.accordion-open', () => {
    digitalData.event = digitalData.event || [];
    digitalData.event.push({
      category: {
        primaryCategory: 'interaction'
      },
      eventInfo: {
        eventName: 'pip sub-content'
      },
      subContent: {
        component: 'accordion',
        section: 'zone-b',
        target: 'product-details'
      },
    });
  });
};