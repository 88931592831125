import digitalData from 'digital-data';
import { STASH, NOT_APPLICABLE } from '../constants';
import { getState } from '../analytics-state';

export const dpd = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('dpd.click', ({ output = {} }) => {
    const state = getState();
    const { pageType, location } = state || {};

    const match = /\/N-(\w+)/.exec(location?.path);
    let nValue = '';
    if (match && match.length) {
      nValue = match[1];
    }

    const {
      carouselName = '', // @todo needs to be done via path
      displayPosition = '' // @todo needs to be done via path
    } = output;

    const anchorProductSku = nValue;

    digitalData.event.push({
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: 'recommendation pip load'
      },
      eventMode: STASH,
      stash: {
        recommendation: {
          recommendationType: 'curated aisles',
          pageType,
          displayPosition,
          anchorProductSku,
          strategy: carouselName.toLowerCase(),
          recommendationVersion: NOT_APPLICABLE,
          featureVersion: NOT_APPLICABLE,
          interval: NOT_APPLICABLE,
        },
      }
    });
  });
};