import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';

export function cart() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('cart.atc-page-load', ({ output = null }) => {

    if (output?.cart) {
      digitalData.cart = output.cart;
    }
    // update DDO when it fires from parent page
    if (!output?.isATC) {
      setAnalytics(digitalData);
    }
  });
}