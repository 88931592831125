import digitalData from 'digital-data';
import {
  INTERACTION,
  PIP_SUB_CONTENT,
} from '../constants';

export const productOverview = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('product-overview.guide-click', ({ output = {} }) => {
    const { target = '' } = output;

    const ddoEvent = {
      category: {
        primaryCategory: INTERACTION
      },
      eventInfo: {
        eventName: PIP_SUB_CONTENT
      },
      subContent: {
        component: 'info-and-guides',
        position: null,
        section: 'product-overview',
        target
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('product-overview.card-click', ({ output = {} }) => {
    const { target = '' } = output;

    const ddoEvent = {
      category: {
        primaryCategory: INTERACTION
      },
      eventInfo: {
        eventName: PIP_SUB_CONTENT
      },
      subContent: {
        component: 'right-rail-link',
        position: null,
        section: 'highlights',
        target
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};