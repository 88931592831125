import digitalData from 'digital-data';
import {
  makeEverythingLowerCase,
  setAnalytics
} from '../../../thd-analytics-utils';

export function content() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('content.page-load', ({ output = {} }) => {
    const {
      heading,
    } = output;

    digitalData.page = {
      pageInfo: {
        abTest: 'content:version:gen2',
        pageLoadEvent: 'content',
        pageName: heading,
        pageTemplate: 'default',
        pageType: 'content',
      },
      category: {
        primaryCategory: heading,
        subCategory1: heading,
        subCategory2: heading,
      }
    };

    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);

    setAnalytics(digitalData);
  });
}