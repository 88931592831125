import { setState, getState } from '../analytics-state';
import { getPageType } from '../thd-analytics-utils';

const { take, put } = LIFE_CYCLE_EVENT_BUS.effects;

export const routeListenerAnalytics = () => {
  window.LIFE_CYCLE_EVENT_BUS.runEventSequence(function* route() {
    while (true) {
      const { output } = yield take('router.change');
      const { location } = output || {};
      const previousState = getState();

      const pageType = getPageType({ path: location.pathname });
      const experienceName = window?.experienceMetadata?.name;
      const pageItemId = (
        pageType === 'pip'
        || pageType === 'tool:productrental:pip'
        || pageType === 'tool:productrental:reservation'
      ) ? location?.pathname?.split('/')?.pop() : null;
      const updatedState = { location, pageType, experienceName, pageItemId };

      const { pathname: previousPage } = previousState?.location || {};
      updatedState.previousPage = previousPage;

      if (pageType !== previousState.pageType) {
        updatedState.sskuInteraction = null;
        updatedState.superDuperSkuInteraction = null;
      }
      setState(updatedState);
      yield put({ type: 'thd-analytics.router-change', location, pageType });
    }
  });
};