/* global newrelic */
export const errorBoundary = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('error-boundary.error', ({ output }) => {
    const { error, id, name, componentStack } = output;
    if (typeof newrelic !== 'undefined' && error) {
      newrelic.noticeError(error);
      window.newrelic.addPageAction('ComponentError', {
        id: id || name,
        message: error.message,
        name: error.name,
        stack: error.stack,
        componentStack
      });
    }
  });
};
