export const Info = {
  isBrioSku: {
    // eslint-disable-next-line id-length
    read(_, { readField }) {
      const productSubType = readField('productSubType');
      return !!(productSubType?.name === 'SFI');
    }
  },
  isCustomKitchenCabinet: {
    // eslint-disable-next-line id-length
    read(_, { readField }) {
      const productSubType = readField('productSubType');
      const categoryHierarchy = readField('categoryHierarchy');

      return !!(productSubType?.name === 'SFI'
        && categoryHierarchy?.find((category) => category === 'Custom Kitchen Cabinets'));
    }
  }
};
