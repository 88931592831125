import digitalData from 'digital-data';
import { setAnalytics, getPageType } from '../../../thd-analytics-utils';
import { getState } from '../../../analytics-state';

export function configuratorProjects() {
  window.LIFE_CYCLE_EVENT_BUS.on('configuratorProjects.visit', () => {
    let { pageType = '' } = getState() || {};
    pageType = pageType === '' ? getPageType() : pageType;

    const ddoPage = {
      category: {
        primaryCategory: 'flooring',
        subCategory1: 'flooring>carpet',
        subCategory2: 'flooring>carpet'
      },
      pageInfo: {
        pageName: 'flooring>carpet>installed carpet',
        pageType
      }
    };

    digitalData.page = ddoPage;
    setAnalytics(digitalData);
  });
}
