import digitalData from 'digital-data';
import { getState, setState } from '../analytics-state';
import {
  PIP,
  BUTTON_SUBMISSION,
  MODULE_CLICK,
  CLICKTHROUGH,
  SUBCONTENTDECOR,
  SSKU,
  INTERACTION,
  PIP_SUB_CONTENT
} from '../constants';

export const superSku = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('super-sku.click-swatch', ({ output = {} }) => {
    const {
      attributeLabel
    } = output;

    const templates = 'zone a';// @todo need to determine based on path (could be `zone b`)// or?

    const { pageInfo = {} } = digitalData.page;
    const { pageType = PIP } = pageInfo;
    setState({
      ...getState(),
      sskuInteraction: true,
      pageTransitionEvent: SSKU
    });
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: MODULE_CLICK
      },
      pageType,
      content: {
        widgets: [
          {
            action: CLICKTHROUGH,
            id: 'n/a',
            name: SUBCONTENTDECOR, // @TODO migrated from decor, whats the rest of the site use
            templates,
            component: 'product swatch',
            element: attributeLabel || 'color' // this is not 1-1 was "color" now Color/Finish, does that matter?
          }
        ]
      }
    };
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('super-sku.click-super-duper-sku', () => { // TODO: Check if this is needed
    setState({ superDuperSkuInteraction: true });
  });

  window.LIFE_CYCLE_EVENT_BUS.on('super-sku.click-help-me-choose', ({ output }) => {
    const { component, target, section } = output || {};

    const ddoEvent = {
      category: {
        primaryCategory: INTERACTION
      },
      eventInfo: {
        eventName: PIP_SUB_CONTENT
      },
      subContent: {
        component,
        section,
        target
      }
    };

    digitalData.event.push(ddoEvent);
  });
};