import digitalData from 'digital-data';
import {
  ARTICLE_RECOMMENDATIONS,
  STASH
} from '../constants';

export const articleGuides = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('article-guide.click', ({ output = {} }) => {
    const { guid, pageType, articleRecommendationType } = output;
    const ddoEvent = {
      category: {
        primaryCategory: STASH,
      },
      eventInfo: {
        eventName: ARTICLE_RECOMMENDATIONS
      },
      eventMode: STASH,
      stash: {
        recommendation: {
          articleRecommendationType,
          pageType: pageType || 'homepage',
          guid
        },
      },
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};