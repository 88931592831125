const rfdc = require('rfdc');

export const copy = (data, { time = false, allowEnumerablesOnPrototype = true, trackReferences = false } = {}) => {
  const clone = rfdc({ proto: allowEnumerablesOnPrototype, circles: trackReferences });
  if (time) {
    const start = performance.now();
    const copied = clone(data);
    const end = performance.now();
    const totalTime = end - start;
    return { start, end, totalTime, data: copied };
  }
  return { data: clone(data) };
};
