import { useEffect, useState, useRef } from 'react';
import { NON_CACHED_ROUTES_REGEX_WHITELIST } from './constants';

const buildPath = (node, result = [], attributes = []) => {
  const type = node?.type || {};
  const name = type.displayName || type.name || '';
  let details = attributes.map((key) => {
    if (!node.pendingProps?.[key]) return null;
    return `${key}=${node.pendingProps?.[key]}`;
  })
    .filter((val) => val)
    .join('&');

  if (details) {
    details = '#' + details;
  }
  if (typeof type === 'string') {
    // real dom element
    result.unshift(`${type}[${node.index}]${details}`);
  } else if (name) {
    result.unshift(`${name}[${node.index}]${details}`);
  }
  if (node.return) {
    return buildPath(node.return, result, attributes);
  }

  return result;
};

const useDomPath = ({ attributes } = {}) => {
  const ref = useRef(null);
  const [path, setPath] = useState([]);
  useEffect(() => {
    const { current } = ref;
    if (current && current._reactInternalFiber) {
      // @todo should it use .return initially?
      const _path = buildPath(current._reactInternalFiber.return);
      if (_path.join('') !== path.join('')) {
        setPath(_path);
      }
    } else if (current) {
      const _key = Object.keys(current).find((key) => /^__reactInternalInstance\$/.test(key));
      if (_key) {
        const _path = buildPath(current[_key], [], attributes);
        setPath(_path);
      }
    }
  }, [ref.current]);

  return [path, ref];
};

const getDomPath = (ref, { attributes } = {}) => {
  const { current } = ref;
  let path = [];
  if (current && current._reactInternalFiber) {
    // @todo should it use .return initially?
    path = buildPath(current._reactInternalFiber.return);
  } else if (current) {
    const _key = Object.keys(current).find((key) => /^__reactInternalInstance\$/.test(key));
    if (_key) {
      path = buildPath(current[_key], [], attributes);
    }
  }
  return path;
};

const isNonCachedRoute = (route) => {
  return NON_CACHED_ROUTES_REGEX_WHITELIST.some((regex) => regex.test(route));
};

export {
  useDomPath,
  getDomPath,
  isNonCachedRoute
};
