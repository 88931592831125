import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import { STASH, RECOMMENDATION_EVENTNAME, NOT_APPLICABLE, PACKAGES_PIP } from '../constants';

export const packagesMini = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('visual-packages-mini.click', ({ output = {} }) => {
    const { itemId = '', index = '' } = output;
    const ddoEvent = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: RECOMMENDATION_EVENTNAME
      },
      eventMode: STASH,
      stash: {
        recommendation: {
          recommendationType: 'gen merch package',
          pageType: PACKAGES_PIP,
          displayPosition: index || NOT_APPLICABLE,
          anchorProductSku: itemId || NOT_APPLICABLE,
          strategy: NOT_APPLICABLE,
          recommendationVersion: NOT_APPLICABLE,
          featureVersion: NOT_APPLICABLE,
          interval: NOT_APPLICABLE
        }
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('packages-mini.title-click', ({ output }) => {
    const { itemId, packageCategory, position = 1 } = output;
    const { pageType = '' } = getState() || {};

    const ddoEvent = {
      category: {
        primaryCategory: STASH,
      },
      eventInfo: {
        eventName: RECOMMENDATION_EVENTNAME,
      },
      eventMode: STASH,
      stash: {
        recommendation: {
          recommendationType: packageCategory === 'ma' ? 'kitchen package' : 'product package',
          pageType,
          displayPosition: position,
          anchorProductSku: itemId,
          recommendedProduct: NOT_APPLICABLE,
          strategy: NOT_APPLICABLE,
          recommendationVersion: NOT_APPLICABLE,
          featureVersion: NOT_APPLICABLE,
          interval: NOT_APPLICABLE
        },
      },
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};