import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';
import { CONTENT, EXERCISE_MY_PRIVACY_RIGHTS } from '../../../constants';

export function exerciseMyPrivacyRights() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('exerciseMyPrivacyRights.page-load', () => {
    const pageName = EXERCISE_MY_PRIVACY_RIGHTS;
    const pageType = CONTENT;
    const primaryCategory = EXERCISE_MY_PRIVACY_RIGHTS;
    const subCategory1 = EXERCISE_MY_PRIVACY_RIGHTS;
    const subCategory2 = EXERCISE_MY_PRIVACY_RIGHTS;

    digitalData.page = {
      pageInfo: {
        pageName,
        pageType,
      },
      category: {
        primaryCategory,
        subCategory1,
        subCategory2,
      },

    };

    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('exerciseMyPrivacyRights.static.page-load', ({ output = {} }) => {
    const {
      pageName, pageType, primaryCategory, subCategory1, subCategory2
    } = output;

    digitalData.page = {
      pageInfo: {
        pageName,
        pageType,
      },
      category: {
        primaryCategory,
        subCategory1,
        subCategory2,
      },

    };

    setAnalytics(digitalData);
  });
}