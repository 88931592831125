/* eslint-disable no-console */
/* eslint-disable no-param-reassign */

const DISPLAY_INFO_KEY = 'checkoutPromptsDisplayInfo';
// {
//   orderId: 'HF100058814021',
//   cartTotal: '$1,798.87',
//   cartCount: '2',
//   image: 'https://images.thdstatic.com/productImages/aa69d9df-e08b-...',
//   customerId: '041927455ED6F12C0S',
//   userId: '041927455ED8F12C0U'
// }

export const getDisplayInfoFromStorage = () => {
  const requiredProperties = ['cartCount', 'image', 'cartTotal', 'orderId', 'customerId', 'userId'];
  let displayData = null;
  try {
    displayData = JSON.parse(localStorage.getItem(DISPLAY_INFO_KEY)) || {};
    const hasAllRequiredProps = requiredProperties.every(
      (prop) => Object.prototype.hasOwnProperty.call(displayData, prop)
    );
    if (!hasAllRequiredProps) {
      displayData = null;
    }
  } catch (error) {
    // console.error(error);
  }
  return displayData;
};

export const isCheckoutPage = () => {
  try {
    if (typeof window !== 'undefined') {
      return window.location.pathname.includes('/checkout');
    }
  } catch (err) {
    console.error(err);
  }
  return false;
};

const setPageObjectFields = (pageAction) => {
  try {
    const displayData = getDisplayInfoFromStorage();
    if (displayData) {
      const availableProperties = ['cartCount', 'cartTotal', 'orderId', 'customerId', 'userId'];
      availableProperties.forEach((prop) => {
        pageAction[prop] = displayData[prop];
      });
    }
  } catch (error) {
    // console.error(error);
  }
  return pageAction;
};

export const publishEvent = ({ name, value }) => {
  try {
    if (!window?.newrelic?.addPageAction) return;

    let pageAction = {
      name
    };

    if (value && (typeof value !== 'string')) {
      value = JSON.stringify(value);
    }

    if (value) {
      pageAction.value = value;
    }

    pageAction = setPageObjectFields(pageAction);

    window.newrelic.addPageAction('CheckoutPrompts', pageAction);

  } catch (error) {
    // console.error(error);
  }
};

export const setDisplayAnalytics = () => {
  try {
    const thdAnalyticsEvent = {};
    thdAnalyticsEvent.category = thdAnalyticsEvent.category || {};
    thdAnalyticsEvent.category.primaryCategory = thdAnalyticsEvent.category.primaryCategory || {};
    thdAnalyticsEvent.category.primaryCategory = 'overlay';
    thdAnalyticsEvent.eventInfo = thdAnalyticsEvent.eventInfo || {};
    thdAnalyticsEvent.eventInfo.eventName = thdAnalyticsEvent.eventInfo.eventName || {};
    thdAnalyticsEvent.eventInfo.eventName = 'checkout prompt';
    const digitalData = window.digitalData || {};
    digitalData.event.push(thdAnalyticsEvent);
    // LIFE_CYCLE_EVENT_BUS.trigger('checkoutPrompt.display', {
    //   thdAnalyticsEvent
    // });
  } catch (error) {
    // console.error(error);
  }

  publishEvent({
    name: 'Checkout_Prompt_Displayed'
  });
};

export const setCheckoutAnalytics = () => {
  try {
    const thdAnalyticsEvent = {};
    thdAnalyticsEvent.category = thdAnalyticsEvent.category || {};
    thdAnalyticsEvent.category.primaryCategory = thdAnalyticsEvent.category.primaryCategory || {};
    thdAnalyticsEvent.category.primaryCategory = 'button submission';
    thdAnalyticsEvent.eventInfo = thdAnalyticsEvent.eventInfo || {};
    thdAnalyticsEvent.eventInfo.eventName = thdAnalyticsEvent.eventInfo.eventName || {};
    thdAnalyticsEvent.eventInfo.eventName = 'cart checkout';
    const digitalData = window.digitalData || {};
    digitalData.event.push(thdAnalyticsEvent);
    // LIFE_CYCLE_EVENT_BUS.trigger('checkoutPrompt.checkout', {
    //   thdAnalyticsEvent
    // });
  } catch (error) {
    // console.error(error);
  }

  publishEvent({
    name: 'Checkout_Prompt_Button_Clicked'
  });
};

export const serviceCall = async ({ userId, customerId, orderId }) => {
  // https://www.homedepot.com/v1/insight/cart/predict
  const url = '/v1/insight/cart/predict'
    + `?userId=${userId}`
    + `&customerId=${customerId}`
    + `&cartId=${orderId}`;
  let resp = null;
  try {
    const res = await fetch(url);
    // e.g.
    // {
    //   "customerId": "73161109-e88c-4ebc-bdd4-59bd9a1fb084",
    //   "userId": "73161109-e88c-4ebc-bdd4-59bd9a1fb084",
    //   "validTill": 522,
    //   "checkoutPrompt": {
    //       "cartId": "hf100055777984",
    //       "display": true,
    //       "timeToShow": 139
    //   }
    // }
    const data = await res.json();
    if (data && data.validTill) {
      resp = data;
    }
  } catch (error) {
    publishEvent({
      name: 'error with service call',
      value: {
        url,
        res: error
      }
    });
  }
  return resp;
};

function mockTrigger(delay = 8000) {
  try {
    console.log('Set mock trigger delay to ', delay);
    const displayInformation = {
      orderId: 'HF100058814021',
      cartTotal: '$1,798.87',
      cartCount: '2',
      image: 'https://images.thdstatic.com/productImages/aa69d9df-e08b-4c80-a2c8-276b1c0bbe16/'
      + 'svn/fingerprint-resistant-stainless-steel-samsung-side-by-side-refrigerators-rs22t5201sr-64_400.jpg',
      customerId: '041927455ED6F12C0S',
      userId: '041927455ED8F12C0U'
    };

    const operationTime = {
      validTill: (Date.now() + 135000),
      displayedTime: null,
      showTime: (Date.now() + delay),
    };

    localStorage.setItem(
      'checkoutPromptsDisplayInfo',
      localStorage.getItem('checkoutPromptsDisplayInfo') || JSON.stringify(displayInformation));
    localStorage.setItem('checkoutPromptsOperationTime', JSON.stringify(operationTime));
  } catch (error) {
    console.error(error);
  }
}

try {
  window.mockTriggerOn = (delay = 8000) => {
    localStorage.setItem('checkoutPromptsMockTriggerDelay', delay);
  };
  window.mockTriggerOff = () => {
    localStorage.removeItem('checkoutPromptsMockTriggerDelay');
  };
  const delay = localStorage.getItem('checkoutPromptsMockTriggerDelay');
  if (delay) {
    mockTrigger((typeof delay === 'number') ? delay : 8000);
  }
} catch (error) {
  // console.error(error);
}