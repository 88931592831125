import digitalData from 'digital-data';

export const rentalReservationForm = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('rental-reservation-form.date-time-event.error', ({ output = {} }) => {
    const { pageSection = '', message = '', code = 'n/a' } = output;
    const pageErrors = [];
    const errorDetails = {
      eventName: 'post page error',
      type: 'advisory',
      displayType: 'inline',
      pageSection,
      message,
      code
    };
    pageErrors.push(errorDetails);
    const DDOEvent = {
      category: {
        primaryCategory: 'error'
      },
      eventInfo: {
        eventName: 'post page error',
        error: pageErrors
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });
};