import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';

const pageType = 'special buy';

const primaryCategories = {
  sbotd: 'special buy of the day',
  decor: 'special buy of the week',
  pro: 'special buy of the week',
  '': 'unspecified special buy'
};

const pageNames = {
  sbotd: 'special buy of the day',
  decor: 'special buy of the week decor',
  pro: 'special buy of the week pro',
  '': 'unspecified special buy'
};

const reportSpecialBuyPageLoad = ({ output: promoType = '' }) => {
  const pageName = pageNames[promoType];

  digitalData.page = {
    category: {
      primaryCategory: primaryCategories[promoType],
      subCategory1: pageName,
      subCategory2: pageName
    },
    pageInfo: {
      pageName,
      pageType
    }
  };

  setAnalytics(digitalData);
};

export const promotions = () => {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('specialBuy.page-load', reportSpecialBuyPageLoad);
};