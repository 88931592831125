/* global newrelic */
import { setState, getState } from '../state';

export const cartListener = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('cart.add-to-cart', ({ output }) => {
    const { cartReqParams, path } = output;
    if (cartReqParams) {
      const {
        fulfillmentLocation,
        fulfillmentMethod,
        itemId,
        quantity,
      } = cartReqParams;

      window.newrelic.addPageAction('AddToCart', {
        itemId,
        quantity,
        fulfillmentMethod,
        fulfillmentLocation,
        domPath: (path || []).join('>')
      });
    }

  });

  window.LIFE_CYCLE_EVENT_BUS.on('cart.cartPageAction', ({ output }) => {
    const { actionName, actionObject } = output;
    if (actionName && actionObject) {
      window.newrelic.addPageAction(actionName, actionObject);
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('cart.cartCustomTag', ({ output }) => {
    const { tagName, tagValue } = output;
    if (tagName && tagValue) {
      window.newrelic.setCustomAttribute(tagName, tagValue);
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('cart.cartNoticeError', ({ output }) => {
    const { err } = output;
    if (err) {
      window.newrelic.noticeError(err);
    }
  });

};
