import { ONLINE_STORE } from '../../../constants';
import { getDomainList } from '../../../thd-analytics-utils';

export const browsePromise = ({ skipBrowse = false, cachedPepModel } = {}) => {
  if (skipBrowse) return new Promise((res) => res(cachedPepModel));
  return new Promise((resolve) => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.off('pep.new-products');
    LIFE_CYCLE_EVENT_BUS.lifeCycle.on('pep.new-products', ({ output: browseModel }) => {
      resolve(browseModel);
      cachedPepModel = browseModel; //eslint-disable-line
    });
  });
};

export const productPromise = ({ skipProduct = false, cachedProduct } = {}) => {
  if (skipProduct) return new Promise((res) => res(cachedProduct));
  let hasInitedProduct = false;
  return new Promise((resolve) => {

    LIFE_CYCLE_EVENT_BUS.on('thd-analytics.state-change', ({ output = {} }) => {
      if (hasInitedProduct) { return; }

      const {
        experienceName,
        storeId,
        dataSources,
        pageItemId,
        pageType,
      } = output;
      if (pageType !== 'pep') { return; }

      const productId = Object.keys(dataSources?.product)[0];
      const product = dataSources?.product?.[productId] || {};
      const domains = getDomainList(experienceName);
      let keys = Object.keys(product);
      if (product) {
        keys = keys.concat(Object.keys(product) || {});
      }
      keys = keys.filter((key) => key !== '__typename');
      const hasDomains = domains.every((domain) => keys.indexOf(domain) > -1);
      if (hasDomains && storeId !== ONLINE_STORE && product?.__typename === 'BaseProduct') {
        hasInitedProduct = true;
        resolve(product);
        cachedProduct = product; //eslint-disable-line
      }
    });
  });
};