import digitalData from 'digital-data';
import { BUTTON_SUBMISSION, BIA_MY_ACCOUNT, BIA_FILTER_BY, BIA_SEARCH_BUTTON } from '../constants';

export const buyItAgain = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('buy-it-again.timeFilter', ({ output }) => {
    const { timeFilter = '' } = output;

    const DDOEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: BIA_MY_ACCOUNT
      },
      myAccount: {
        settings: BIA_FILTER_BY + `${timeFilter}`
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('buy-it-again.searchButton', ({ output }) => {
    const { searchFilter = '', searchTerm = '' } = output;

    const DDOEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: BIA_MY_ACCOUNT
      },
      myAccount: {
        settings: BIA_SEARCH_BUTTON,
        searchTerm,
        searchBy: searchFilter
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });
};