import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';
import { ACCOUNT, PO_JOB_NAMES, ERROR } from '../../../constants';

// https://github.com/one-thd/ocm_pd_experience_customer-account-pojob-proallowance
export function poJobsPage() {
  const pageEvents = {
    load: () => {
      const category = `${ACCOUNT}>${PO_JOB_NAMES}`;

      digitalData.page = {
        pageInfo: {
          pageName: category,
          pageType: `maml:${PO_JOB_NAMES}`,
        },
        category: {
          primaryCategory: ACCOUNT,
          subCategory1: category,
          subCategory2: category,
        },
      };
      setAnalytics(digitalData);
    },

    error: ({ output }) => {
      const { displayType, message, code } = output;

      digitalData.page = {
        error: {
          type: ERROR,
          displayType, // 'inline' || 'page'
          pageSection: PO_JOB_NAMES,
          message,
          code,
        },
      };
      setAnalytics(digitalData);
    },
  };

  window.LIFE_CYCLE_EVENT_BUS.on('po-job.page-load', pageEvents.load);
  window.LIFE_CYCLE_EVENT_BUS.on('po-job.error', pageEvents.error);
}
