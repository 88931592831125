import React, { useState, useEffect } from 'react';
import {
  node, arrayOf, oneOfType, string, bool,
} from 'prop-types';
import { useLocation } from 'react-router-dom';
import useAxios from 'axios-hooks';
import RouterContext from '../RouterContext';

const stringifyParams = (params) => {
  return Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
};

export const CrossExperience = ({ children, experience, disableCrossExpCheck }) => {
  const location = useLocation();
  const [pathHistory, setPathHistory] = useState(
    [location.pathname + location.search]
  );

  const [_, execute] = useAxios(location, { manual: true });

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.trigger('router.ready', {});
  }, []);

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.trigger('router.change', {
      location
    });
  }, [location]);

  useEffect(() => {
    // if the experience does not provide `experience`
    // its opting out of checking cross exp functionality
    if (!experience || disableCrossExpCheck) return () => {};

    const loc = location.pathname + location.search;

    // dont call api for first page load
    if (pathHistory[0] === loc && pathHistory.length === 1) return () => {};

    const bypassExp = !!location?.state?.bypassExperienceCheck;
    if (!bypassExp) {
      (async () => {
        const headers = {
          'x-rules-engine': 'query'
        };
        const response = await execute({ url: `${loc}`, headers });

        if (experience) {
          const _path = location.pathname;
          const queryString = location.search;

          let experienceName = response.data?.experience;
          if (Array.isArray(experienceName)) {
            experienceName = experienceName[0];
          }
          // an empty string may be present if its forced
          // and dont want it to redirect in that case
          if (typeof experienceName !== 'undefined') {
            if (experienceName !== experience && experienceName !== '') {
              const url = `${_path}${queryString}`;
              window.LIFE_CYCLE_EVENT_BUS.trigger('router.cross-experience', {
                fromExperience: experience,
                toExperience: experienceName,
                toExperienceUrl: url,
              });
              window.location.replace(url);
            }
          } else {
            const url = `${_path}${queryString}`;
            window.LIFE_CYCLE_EVENT_BUS.trigger('router.cross-experience', {
              fromExperience: experience,
              toExperience: 'non-nucleus',
              toExperienceUrl: url,
            });
            window.location.replace(url);
          }
        }
      })();
    }

    return () => {};

  }, [location]);

  useEffect(() => {
    const loc = location.pathname + location.search;
    // dont push first page load url
    if (loc !== pathHistory[0] || pathHistory > 1) {
      setPathHistory((paths) => [...paths, loc]);
    }
  }, [location]);

  const contextValue = {
    paths: pathHistory,
  };

  return (
    <RouterContext.Provider value={contextValue}>
      {children}
    </RouterContext.Provider>
  );
};

CrossExperience.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  /**
   * The current nucleus experience. This is used to call full page reloads
   * when there is a pushState or replaceState SPA transition.
   *
   * Leave null to prevent window.location change on SPA transitions cross experience
   */
  experience: string,
  disableCrossExpCheck: bool,
};

CrossExperience.defaultProps = {
  experience: null,
  disableCrossExpCheck: false
};