import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';
import { parseCategoryInfo } from '../landingPage/breadCrumbUtils';

export function customerLoyalty() {
  const pageEvents = {
    loyaltyPageLoad: ({ output }) => {
      const { breadCrumbLabels, pageType } = output;
      const categoryInfo = parseCategoryInfo(breadCrumbLabels);
      const { primaryCategory, subCategory1, subCategory2 } = categoryInfo || {};
      digitalData.page = {
        pageInfo: {
          pageLoadEvent: 'content',
          pageName: subCategory2,
          pageTemplate: 'default',
          pageType,
        },
        category: {
          primaryCategory,
          subCategory1,
          subCategory2,
        },
      };
      setAnalytics(digitalData);
    },

    loyaltyButton: ({ output }) => {
      const { settings } = output;
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        eventInfo: {
          eventName: 'my account settings',
        },
        category: {
          primaryCategory: 'button submission',
        },
        myAccount: {
          settings,
        },
      });
      setAnalytics(digitalData);
    },

    loyaltyStash: ({ output }) => {
      const { settings } = output;
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        eventInfo: {
          eventName: 'my account settings',
        },
        eventMode: 'stash',
        category: {
          primaryCategory: 'stash',
        },
        stash: {
          myAccount: {
            settings,
          },
        },
      });
      setAnalytics(digitalData);
    },

    loyaltyOverlay: ({ output }) => {
      const { settings } = output;
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        eventInfo: {
          eventName: settings,
        },
        category: {
          primaryCategory: 'overlay',
        },
      });
      setAnalytics(digitalData);
    },
  };

  window.LIFE_CYCLE_EVENT_BUS.on('customer-loyalty.loyalty-page-load', pageEvents.loyaltyPageLoad);
  window.LIFE_CYCLE_EVENT_BUS.on('customer-loyalty.loyalty-button', pageEvents.loyaltyButton);
  window.LIFE_CYCLE_EVENT_BUS.on('customer-loyalty.loyalty-stash', pageEvents.loyaltyStash);
  window.LIFE_CYCLE_EVENT_BUS.on('customer-loyalty.loyalty-overlay', pageEvents.loyaltyOverlay);
}
