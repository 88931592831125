/* eslint-disable no-param-reassign */
import digitalData from 'digital-data';
import { fulfillment } from './fulfillment.listener.analytics';

let override = null;

const EVENTS = {
  ADD_TO_CART_HDPP: 'add-to-cart-hdpp',
};

const publishEvent = ({ eventObject }) => {
  try {
    // if override function exists
    if (override && typeof override === 'function') {
      override(eventObject);
    }
    digitalData.event.push(eventObject);
  } catch (error) {
    console.error('Error in publishEvent()', error);
  }
};

export const getLocation = () => {
  try {
    if (typeof window === 'undefined') {
      return '';
    }
    const path = window?.top?.location?.pathname?.split('/')?.[1] || '';
    switch (path) {
    case 's':
      return 'search';
    case 'b':
      return 'plp';
    case 'p':
      if (/^\/p\/sets/.test(window?.top?.location?.pathname)) {
        return 'bip';
      }
      return 'pip';
    case 'c':
      return 'content';
    case 'collection':
      return 'collections';
    case 'SpecialBuy':
      return 'specialBuy';
    case 'mycart':
    case 'cart':
      return 'cart';
    case 'myaccount':
    case 'account':
      return 'account';
    case 'order':
      return 'order';
    case 'quickadd':
      return 'quickadd';
    case 'order-confirmation':
    case 'confirmation':
      return 'confirmation';
    default:
      return '';
    }
  } catch (err) {
    console.error('Error in getLocation()', err);
  }
  return '';
};

const handleAddToCartHdppStash = (output) => {
  try {
    const itemId = output?.itemId || '';
    const _pageType = output?.pageType || '';
    const pageType = _pageType || getLocation();
    const eventObject = {
      category: {
        primaryCategory: 'stash'
      },
      eventInfo: {
        eventName: 'cart add location'
      },
      eventMode: 'stash',
      stash: {
        pageType,
        sharedSection: 'hdpp',
        section: 'n/a',
        component: 'n/a',
        target: 'atc',
        items: [{
          index: 'n/a',
          productID: itemId
        }]
      }
    };
    publishEvent({ eventObject });
  } catch (err) {
    console.error('Error in handleAddToCartHdppStash()', err);
  }
};

const handleAddToCartHdpp = (output) => {
  try {
    handleAddToCartHdppStash(output);

    const cartID = output?.cartId || '';
    const basePrice = output?.total || '';
    const sku = output?.itemId || '';
    const quantity = output?.quantity || '';

    const eventObject = {
      cart: {
        cartID
      },
      category: {
        primaryCategory: 'overlay'
      },
      eventInfo: {
        abTest: '',
        eventName: 'cart add'
      },
      item: [{
        fulfillment: {
          changeType: '',
          method: 'sth'
        },
        price: {
          basePrice
        },
        productInfo: {
          sku
        },
        quantity
      }],
      overlayLocation: getLocation()
    };
    publishEvent({ eventObject });
  } catch (err) {
    console.error('Error in handleAddToCartHdpp()', err);
  }
};

export const hdppAddProtectionPlan = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('hdpp-add-protectionplan-analytics', ({ output = {} }) => {
    try {
      const analyticsEventName = output?.analyticsEventName || '';
      override = output?.override || null;

      switch (analyticsEventName) {
      case EVENTS.ADD_TO_CART_HDPP:
        handleAddToCartHdpp(output);
        break;
      default:
        console.warn('Unable to find hdpp add protection plan analytics event of \'' + analyticsEventName + '\'');
        break;
      }
    } catch (error) {
      console.error('Error in hdpp add protection plan analytics', error);
    }
  });
};