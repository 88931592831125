import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';
import { ACCOUNT, MILITARY_DISCOUNT, ERROR } from '../../../constants';

export function militaryDiscount() {
  const pageEvents = {
    load: ({ output }) => {
      const { isEnrolled, hasMaxDiscount } = output;

      const category = `${ACCOUNT}>${MILITARY_DISCOUNT} benefit${isEnrolled ? ' enrolled' : ''}`;

      digitalData.page = {
        pageInfo: {
          pageName: `${category}${hasMaxDiscount ? ' max discount' : ''}`,
          pageType: 'maml:military discount',
        },
        category: {
          primaryCategory: ACCOUNT,
          subCategory1: category,
          subCategory2: category,
        },
      };
      setAnalytics(digitalData);
    },
  };

  window.LIFE_CYCLE_EVENT_BUS.on('military-discount.page-load', pageEvents.load);
}
