import digitalData from 'digital-data';
import {
  BUTTON_SUBMISSION, MY_ACCOUNT_SETTINGS, STASH, OVERLAY
} from '../constants';

export const projectForms = () => {

  const PROJECT_CREATE_BUTTON_CLICKED = 'projects: create new project button';
  const PROJECT_CREATE_OVERLAY = 'projects: create new project overlay';
  const PROJECT_CREATE_CLICK = 'projects: create new project overlay: create project button';
  const PROJECT_CREATE_SUCCESS = 'new project created';
  const PROJECT_STATUS_MENU_CLICK = 'projects: status menu click';
  const PROJECT_STATUS_OVERLAY = 'projects: status overlay';
  const PROJECT_STATUS_CHANGE = 'projects: status change';
  const PROJECT_DETAILS_SHARE_YOUR_PROJECT = 'projects: project details: share your project';
  const PROJECT_DETAILS_SHARE_YOUR_PROJECT_SEND = 'projects: project details: share your project: send button';
  const PROJECT_DETAILS_REPLY_TO_MESSAGE_OVERLAY = 'projects: project details: reply to message overlay';
  const PROJECT_DETAILS_REPLY_TO_MSG_OVERLAY = 'projects: reply to message overlay';
  const PROJECT_DETAILS_SHARE_YOUR_PROJECT_STEP = 'projects: project details: share your project: step';
  const PROJECT_DETAILS_SHARE_YOUR_PROJECT_CONTINUE = 'projects: project details: share your project: continue';
  const PROJECT_NEW_MESSAGE_CLICK = 'projects: project details: new message click';
  const PROJECT_REPLY_BTN_CLICK = 'projects: project details: reply button';

  // 7.51.2 Create New Project button click
  LIFE_CYCLE_EVENT_BUS.on(PROJECT_CREATE_BUTTON_CLICKED, () => {
    const ddoEvent = {
      category: {
        primaryCategory: STASH
      },
      eventMode: STASH,
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS,
      },
      stash: {
        myAccount: {
          settings: PROJECT_CREATE_BUTTON_CLICKED
        }
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.3 Create New Project overlay
  LIFE_CYCLE_EVENT_BUS.on(PROJECT_CREATE_OVERLAY, () => {
    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY
      },
      eventInfo: {
        eventName: PROJECT_CREATE_OVERLAY
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.6 Create Project button
  LIFE_CYCLE_EVENT_BUS.on(PROJECT_CREATE_CLICK, () => {
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS
      },
      myAccount: {
        settings: PROJECT_CREATE_CLICK
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.4 Project Created
  LIFE_CYCLE_EVENT_BUS.on(PROJECT_CREATE_SUCCESS, ({ output }) => {
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: PROJECT_CREATE_SUCCESS
      },
      myAccount: {
        settings: output?.settings
      },
      projectId: output?.projectId
    };
    digitalData.event.push(ddoEvent);
  });
  // 7.51.11 Projects Meatball Menu: Set Active, Delete, Archive
  LIFE_CYCLE_EVENT_BUS.on(PROJECT_STATUS_MENU_CLICK, ({ output }) => {
    const ddoEvent = {
      category: {
        primaryCategory: STASH
      },
      eventMode: STASH,
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS,
      },
      stash: {
        myAccount: {
          settings: output?.settings
        }
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.12 Projects Delete, Archive overlays
  LIFE_CYCLE_EVENT_BUS.on(PROJECT_STATUS_OVERLAY, ({ output }) => {
    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY
      },
      eventInfo: {
        eventName: output?.overlay
      },
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.13 Project Status Actions
  LIFE_CYCLE_EVENT_BUS.on(PROJECT_STATUS_CHANGE, ({ output }) => {
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS
      },
      myAccount: {
        settings: output?.settings
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.31 Share Your Project Overlay
  LIFE_CYCLE_EVENT_BUS.on(PROJECT_DETAILS_SHARE_YOUR_PROJECT, () => {
    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY
      },
      eventInfo: {
        eventName: PROJECT_DETAILS_SHARE_YOUR_PROJECT
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.34 Successful Share
  LIFE_CYCLE_EVENT_BUS.on(PROJECT_DETAILS_SHARE_YOUR_PROJECT_SEND, ({ output }) => {
    const status = output?.status || '';
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS
      },
      myAccount: {
        settings: `${PROJECT_DETAILS_SHARE_YOUR_PROJECT_SEND}: ${status}`
      }
    };
    digitalData.event.push(ddoEvent);
  });

  LIFE_CYCLE_EVENT_BUS.on(PROJECT_DETAILS_REPLY_TO_MESSAGE_OVERLAY, ({ output }) => {
    const action = output?.action || '';
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS
      },
      myAccount: {
        settings: `${PROJECT_DETAILS_REPLY_TO_MESSAGE_OVERLAY}: ${action}`
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.33 Share Your Project Overlay Actions
  LIFE_CYCLE_EVENT_BUS.on(PROJECT_DETAILS_SHARE_YOUR_PROJECT_STEP, ({ output }) => {
    const { step, action } = output || {};
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS
      },
      myAccount: {
        settings: `${PROJECT_DETAILS_SHARE_YOUR_PROJECT_STEP} ${step}: ${action}`
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.32 Share Your Project Continue Button
  LIFE_CYCLE_EVENT_BUS.on(PROJECT_DETAILS_SHARE_YOUR_PROJECT_CONTINUE, ({ output }) => {
    const shared = output?.shared || '';
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS
      },
      myAccount: {
        settings: `${PROJECT_DETAILS_SHARE_YOUR_PROJECT_CONTINUE}: ${shared}`
      }
    };
    digitalData.event.push(ddoEvent);
  });

  //   LIFE_CYCLE_EVENT_BUS.on('list-forms.list-delete', ({ output }) => {
  //     const ddoEvent = {
  //       category: {
  //         primaryCategory: 'button submission'
  //       },
  //       eventInfo: {
  //         eventName: 'delete list'
  //       }
  //     };
  //     digitalData.event.push(ddoEvent);
  //   });

  //   LIFE_CYCLE_EVENT_BUS.on('list-forms.create-without-products', ({ output }) => {
  //     const ddoEvent = {
  //       category: {
  //         primaryCategory: INTERACTION
  //       },
  //       eventInfo: {
  //         eventName: 'create list'
  //       },
  //       myList: {
  //         creationLocation: 'my lists'
  //       }
  //     };
  //     digitalData.event.push(ddoEvent);
  //   });

  //   LIFE_CYCLE_EVENT_BUS.on('list-forms.save-a-shared-list', () => {
  //     const ddoEvent = {
  //       category: {
  //         primaryCategory: 'button submission'
  //       },
  //       eventInfo: {
  //         eventName: 'my account settings'
  //       },
  //       myAccount: {
  //         settings: 'lists: shared list: save to lists link'
  //       }
  //     };
  //     digitalData.event.push(ddoEvent);
  //   });

  // 7.51.40 Reply to Message Overlay
  LIFE_CYCLE_EVENT_BUS.on(PROJECT_DETAILS_REPLY_TO_MSG_OVERLAY, () => {
    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY
      },
      eventInfo: {
        eventName: PROJECT_DETAILS_REPLY_TO_MSG_OVERLAY
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.39 New Message/Reply Click
  LIFE_CYCLE_EVENT_BUS.on(PROJECT_NEW_MESSAGE_CLICK, () => {
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS
      },
      myAccount: {
        settings: PROJECT_NEW_MESSAGE_CLICK
      }
    };
    digitalData.event.push(ddoEvent);
  });

  LIFE_CYCLE_EVENT_BUS.on(PROJECT_REPLY_BTN_CLICK, () => {
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS
      },
      myAccount: {
        settings: PROJECT_REPLY_BTN_CLICK
      }
    };
    digitalData.event.push(ddoEvent);
  });

};
