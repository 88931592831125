import digitalData from 'digital-data';
import { setState } from '../../../analytics-state';
import { setAnalytics, isDuplicatePageError, getContentProducts } from '../../../thd-analytics-utils';
import {
  MY_LIST_DETAILS,
  MY_LIST_DETAILS_PAGE_TYPE,
  MY_LIST_SHARED,
  MY_LIST_SUMMARY
} from '../../../constants';

export function myList() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('list-details.page-load', (output) => {
    const pageType = MY_LIST_DETAILS_PAGE_TYPE;
    setState({ pageType });
    digitalData.page = {
      pageInfo: {
        pageName: MY_LIST_DETAILS,
        pageType,
      },
      category: {
        primaryCategory: MY_LIST_DETAILS,
        subCategory1: MY_LIST_DETAILS,
        subCategory2: MY_LIST_DETAILS,
      }
    };
    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('list-shared.page-load', (output) => {
    const pageType = MY_LIST_DETAILS_PAGE_TYPE;
    setState({ pageType });
    digitalData.page = {
      pageInfo: {
        pageName: MY_LIST_SHARED,
        pageType,
      },
      category: {
        primaryCategory: MY_LIST_SHARED,
        subCategory1: MY_LIST_SHARED,
        subCategory2: MY_LIST_SHARED,
      }
    };
    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('list-summary.page-load', (output) => {
    const pageType = 'maml: list index';
    setState({ pageType });
    digitalData.page = {
      pageInfo: {
        pageName: MY_LIST_SUMMARY,
        pageType,
      },
      category: {
        primaryCategory: MY_LIST_SUMMARY,
        subCategory1: MY_LIST_SUMMARY,
        subCategory2: MY_LIST_SUMMARY,
      }
    };
    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('my-list.page-error', ({ output }) => {
    const {
      type = 'error',
      displayType = 'page',
      pageSection = '',
      message = '',
      code = ''
    } = output || {};

    const pageError = {
      type,
      displayType,
      pageSection,
      message,
      code
    };
    digitalData.page = digitalData.page || {};
    digitalData.page.error = digitalData.page.error || [];
    if (!isDuplicatePageError(digitalData, pageError)) {
      digitalData.page.error.push(pageError);
    }
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('my-list.products-load', ({ output }) => {
    const { products } = output;
    digitalData.content = {
      ...digitalData.content,
      product: getContentProducts(products)
    };
  });
}