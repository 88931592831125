import digitalData from 'digital-data';
import {
  FIT_COMPATIBILITY_DRAWER,
  FIT_COMPATIBILITY_STEP4,
  OVERLAY
} from '../constants';

export const fitCompatibility = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('fit-compatibility-drawer.drawerOpen', () => {
    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY
      },
      eventInfo: {
        eventName: FIT_COMPATIBILITY_DRAWER
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('fit-compatibility-drawer.step4', ({ output }) => {
    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY
      },
      eventInfo: {
        eventName: FIT_COMPATIBILITY_STEP4
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};
