import digitalData from 'digital-data';
import {
  BUTTON_SUBMISSION, OVERLAY, ADD_TO_QUOTE, CREATE_A_QUOTE, QUOTE_ADD, NEW_QUOTE_ADD, DELIVERY_ZIP_OVERLAY_EVENT
} from '../constants';
import { getPageType } from '../thd-analytics-utils';

export const addToQuote = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('add-to-quote.addToQuoteOverlay', () => {
    const DDOEvent = {
      category: {
        primaryCategory: OVERLAY
      },
      eventInfo: {
        eventName: ADD_TO_QUOTE
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('add-to-quote.createAQuoteOverlay', () => {
    const DDOEvent = {
      category: {
        primaryCategory: OVERLAY
      },
      eventInfo: {
        eventName: CREATE_A_QUOTE
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('add-to-quote.quoteAdd', ({ output }) => {
    const { quoteType, quoteId, item } = output;
    const { quantity, price, productInfo } = item;
    const { basePrice } = price;
    const { sku } = productInfo;
    const quoteLocation = getPageType();

    const DDOEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: QUOTE_ADD
      },
      quoteType,
      quoteId,
      item: [{
        quoteLocation,
        quantity,
        price: {
          basePrice
        },
        productInfo: {
          sku
        }
      }]
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('add-to-quote.newQuoteAdd', ({ output }) => {
    const { quoteType, quoteId, item } = output;
    const { quantity, price, productInfo } = item;
    const { basePrice } = price;
    const { sku } = productInfo;
    const quoteLocation = getPageType();

    const DDOEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: NEW_QUOTE_ADD
      },
      quoteType,
      quoteId,
      item: [{
        quoteLocation,
        quantity,
        price: {
          basePrice
        },
        productInfo: {
          sku
        }
      }]
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('add-to-quote.deliveryZipCodeOverlay', () => {
    const deliveryZipOverlayEventData = {
      category: {
        primaryCategory: 'button submission',
      },
      eventInfo: { eventName: 'my account settings' },
      myAccount: { settings: 'add to quote overlay: delivery zip changed' },
    };
    digitalData.event.push(deliveryZipOverlayEventData);
  });
};
