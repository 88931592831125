export default {
  Product: [
    'BaseProduct',
    'CollectionProduct',
    'DynamicRecsProduct',
    'FbrProduct',
    'EndCapProduct'
  ],
  Store: [
    'StoreDetails',
    'StoreDetailsWithNearByStores'
  ]
};