import digitalData from 'digital-data';
import { NUCLEUS } from '../../../constants';
import { makeEverythingLowerCase, setAnalytics, getCategoryBreadCrumbs } from '../../../thd-analytics-utils';

export const getCollectionPageType = ({ type = '' }) => {
  switch (type) {
  case 'Integrated Brand Template':
    return 'integrated';
  case 'Grouped View':
    return 'patio';
  case 'List View Template':
    return 'list view';
  case 'Space':
    return 'space';
  default:
    return '';
  }
};

export function collections() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('collections.data', ({ output: collectionDetail }) => {
    if (!collectionDetail) return;
    const category = getCategoryBreadCrumbs(collectionDetail?.taxonomy?.breadCrumbs);
    const { type, name } = collectionDetail?.metadata || {};

    const extraPageInfo = {};
    const extraProductData = {};
    const extraContentData = {};
    if (type === 'Space') {
      const { metadata, collectionId } = collectionDetail || {};
      const { primaryCurationId } = metadata || {};
      extraPageInfo.catalog = 'n/a';
      extraProductData.curationID = primaryCurationId;
      extraContentData.collection = {
        collectionID: collectionId
      };
    }

    digitalData.page = {
      pageInfo: {
        collectionVariance: '',
        pageName: `${category?.subCategory2}>${name}`,
        pageType: `collections:${getCollectionPageType({ type })}`,
        platform: NUCLEUS,
        ...extraPageInfo
      },
      category
    };

    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);
    const products = (collectionDetail?.products || []).map((product, i) => {
      return {
        productInfo: {
          position: i,
          sku: product?.identifiers?.itemId
        },
        ...extraProductData
      };
    });

    digitalData.content = {
      product: products,
      ...extraContentData
    };
    setAnalytics(digitalData);
  });
}