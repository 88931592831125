import digitalData from 'digital-data';
import { makeEverythingLowerCase, setAnalytics, getCategoryBreadCrumbs } from '../../../thd-analytics-utils';

export function vendorCollections() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('vendorCollections.data', ({ output: data }) => {
    const category = getCategoryBreadCrumbs(data?.fetchCollectionsContent?.taxonomy?.breadCrumbs);
    const title = data?.fetchCollectionsContent?.title;

    digitalData.page = {
      pageInfo: {
        pageName: `${category?.subCategory2}>${title}`,
        pageType: 'content',
        pageTemplate: 'default'
      },
      category,
    };

    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);
    setAnalytics(digitalData);
  });
}