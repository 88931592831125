import digitalData from 'digital-data';
import {
  makeEverythingLowerCase,
  setAnalytics
} from '../../../thd-analytics-utils';

export function localAd() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('local-ad.page-load', (data) => {
    digitalData.page = {
      pageInfo: {
        abTest: 'localad|v1',
        pageName: 'localad>weeklyad>' + data?.output?.version,
        pageType: 'content',
      },
      category: {
        primaryCategory: 'localad',
        subCategory1: 'localad>weeklyad',
        subCategory2: 'localad>weeklyad>' + data?.output?.version,
      }
    };
    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);
    setAnalytics(digitalData);
  });
}