import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import { STASH } from '../constants';

export const visualCategoryNav = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('visual-category-nav.click', ({ output = {} }) => {
    const {
      position,
      categoryId
    } = output;

    const { experienceName } = getState() || {};

    const ddoEvent = {
      eventInfo: {
        eventName: 'visual nav click'
      },
      eventMode: STASH,
      category: {
        primaryCategory: STASH
      },
      stash: {
        clickInfo: {
          podPosition: position,
          categoryId,
          experience: experienceName
        }
      }
    };

    if (digitalData && digitalData.event) {
      digitalData.event.push(ddoEvent);
    }
  });
};