import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import {
  NOT_APPLICABLE, PIP, STASH, ATC, PRODUCT_POD
} from '../constants';

export const irg = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('product-pod-irg.add-to-cart', ({ output = {} }) => {
    const {
      sku,
      displayPosition,
      irgSectionName
    } = output || {};

    const { pageItemId = '' } = getState() || {};

    const ddoEvent = {
      eventInfo: {
        eventName: 'cart addition info'
      },
      category: {
        primaryCategory: STASH
      },
      eventMode: STASH,
      stash: {
        component: PRODUCT_POD,
        item: [
          {
            productInfo: {
              sku
            },
            recommendation: {
              displayPosition,
              recommendationType: 'irg',
              pageType: PIP,
              anchorProductSku: pageItemId,
              strategy: irgSectionName || NOT_APPLICABLE,
              recommendationVersion: NOT_APPLICABLE,
              featureVersion: NOT_APPLICABLE,
              interval: NOT_APPLICABLE
            }
          }
        ],
        pageType: PIP,
        section: 'irg',
        sharedSection: NOT_APPLICABLE,
        target: ATC
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};