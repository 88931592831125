import digitalData from 'digital-data';
import {
  COLLECTION,
  INTERACTION,
  PIP_SUB_CONTENT,
  SHARING,
  MODULE_CLICK,
  CLICKTHROUGH,
  SUBCONTENTDECOR,
  NOT_APPLICABLE,
  BUTTON_SUBMISSION
} from '../constants';
import { getState } from '../analytics-state';
import { getCollectionPageType } from '../experience/pages/collections/collections';

export const sharebelt = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('thd-sharebelt.click', ({ output = {} }) => {
    const {
      component,
      target
    } = output;
    let primaryCategory = INTERACTION;
    let eventName = PIP_SUB_CONTENT;
    let subContent = {
      section: SHARING,
      component,
      target
    };
    let extraFields = {};

    let { pageType = null, dataSources } = getState() || {};
    const isCollection = pageType === COLLECTION;

    if (isCollection) {
      const type = dataSources?.collection?.metadata?.type;
      const collectionType = getCollectionPageType({ type });
      if (collectionType === 'space') {
        primaryCategory = BUTTON_SUBMISSION;
        eventName = MODULE_CLICK;
        subContent = {};
        extraFields = {
          pageType: `collections:${collectionType}`,
          content: {
            widgets: [
              {
                action: CLICKTHROUGH,
                id: NOT_APPLICABLE,
                name: SUBCONTENTDECOR,
                templates: 'zone a',
                component: 'sharebelt',
                element: target
              }
            ]
          }
        };
      }
    }

    const ddoEvent = {
      category: {
        primaryCategory
      },
      eventInfo: {
        eventName
      },
      subContent,
      ...extraFields
    };
    digitalData.event = digitalData.event || [];
    (digitalData.event).push(ddoEvent);
  });
};