/* global newrelic */

export const proCustomerQuoteListener = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('quote.quotePageAction', ({ output }) => {
    const { actionName, actionObject } = output;
    if (actionName && actionObject) {
      newrelic.addPageAction(actionName, actionObject);
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('quote.quoteNoticeError', ({ output }) => {
    const { err } = output;
    if (err) {
      newrelic.noticeError(err);
    }
  });
};
