import digitalData from 'digital-data';
import { setState } from '../../../analytics-state';
import {
  NUCLEUS,
  PACKAGES_PIP,
  PRODUCT_VIEW
} from '../../../constants';
import {
  setAnalytics,
  makeEverythingLowerCase,
  getMapPrice
} from '../../../thd-analytics-utils';

export function packages() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('packages.page-data', ({ output: packagesData }) => {
    if (!packagesData?.packages?.length) return;

    const {
      products = []
    } = packagesData?.packages?.[0] || {};
    const isBrandPricingPolicyCompliant = packagesData?.isBrandPricingPolicyCompliant;
    const packageCategory = packagesData?.packageCategory;
    const { levelOne, levelTwo, levelThree } = packagesData?.breadcrumbs || {};
    const headerCrumb = [levelOne?.title, levelTwo?.title, levelThree?.title].filter((title) => !!title).join('>');

    setState({
      pageType: PACKAGES_PIP,
      packageCategory,
      isBrandPricingPolicyCompliant
    });

    digitalData.page = {
      pageInfo: {
        pageName: headerCrumb,
        pageLoadEvent: PRODUCT_VIEW,
        pageType: PACKAGES_PIP,
        platform: NUCLEUS
      },
      category: {
        primaryCategory: levelOne?.title,
        subCategory1: `${levelOne?.title}>${levelTwo?.title}`,
        subCategory2: headerCrumb
      }
    };
    digitalData.product = products.map((product) => {
      const {
        brandName: manufacturer,
        productLabel: productName,
        productType,
        itemId: sku
      } = product?.identifiers || {};
      return {
        productInfo: {
          manufacturer,
          productName,
          productType,
          sku,
          mapPrice: getMapPrice({ product, isBrandPricingPolicyCompliant })
        }
      };
    });

    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData.page.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData.page.category);

    setAnalytics(digitalData);
  });
}