const MAX_DEPTH = 10;

const getDataComponent = ({ node }) => {
  const dataComponent = node?.attributes?.['data-component'];
  if (!dataComponent) return null;
  return { dataComponent: dataComponent.value };
};

const parentTraversalForDataComponent = ({ node = {}, depth = 0 }) => {
  if (depth >= MAX_DEPTH || !node) return null;

  const dataComponent = getDataComponent({ node });
  if (dataComponent) return { ...dataComponent, depth };

  return parentTraversalForDataComponent({
    node: node.parentElement,
    depth: depth + 1
  });
};

const bfsTraversalForDataComponent = ({ queue = [] }) => {
  while (queue.length) {
    const { node, depth } = queue.shift() || {};

    const dataComponent = getDataComponent({ node });
    if (dataComponent) return { ...dataComponent, depth };

    if (depth > MAX_DEPTH || !node) break;

    if (node?.hasChildNodes()) {
      // eslint-disable-next-line no-restricted-syntax
      for (const childNode of node.childNodes) {
        queue.push({ node: childNode, depth: depth + 1 });
      }
    }
  }

  return null;
};

export const findDataComponent = ({ node }) => {
  const firstNode = { node, depth: 0 };
  const parent = parentTraversalForDataComponent(firstNode);
  const child = bfsTraversalForDataComponent({ queue: [firstNode] });

  if (!parent && !child) return null;

  if (parent && child) {
    if (parent.depth <= child.depth) return parent.dataComponent;
    return child.dataComponent;
  }

  return { ...parent, ...child }.dataComponent;
};