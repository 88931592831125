import digitalData from 'digital-data';
import { PIP_SUB_CONTENT, INTERACTION, SYSTEM } from '../constants';

export const calculator = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('calculator.click', ({ output }) => {
    const ddoEvent = {
      eventInfo: {
        eventName: PIP_SUB_CONTENT
      },
      category: {
        primaryCategory: INTERACTION
      },
      subContent: {
        section: 'buybox',
        component: SYSTEM,
        target: 'quantity calculator',
        position: ''
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('calculator.click-tips', ({ output }) => {
    const {
      eventName, primaryCategory
    } = output;
    const thdAnalyticsEvent = {
      eventInfo: {
        eventName
      },
      category: {
        primaryCategory
      },
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(thdAnalyticsEvent);
  });
};