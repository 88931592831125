import digitalData from 'digital-data';
import { setAnalytics, getCategoryBreadCrumbs, getHeaderCrumb } from '../../../thd-analytics-utils';
import { PRODUCT_VIEW, BIP_PAGE, PAGE_DEFAULT_TEMPLATE, NUCLEUS } from '../../../constants';
import { getProductInfo } from './bip.utils';

export function bip() {
  window.LIFE_CYCLE_EVENT_BUS.on('bip.page-load', ({ output }) => {
    const { product, products } = output;
    const parentBreadCrumbs = product?.taxonomy?.breadCrumbs;
    digitalData.page = {
      pageInfo: {
        pageName: getHeaderCrumb(parentBreadCrumbs),
        pageLoadEvent: PRODUCT_VIEW,
        platform: NUCLEUS,
        pageType: BIP_PAGE,
        pageTemplate: PAGE_DEFAULT_TEMPLATE,
        packageID: product?.itemId
      },
      category: getCategoryBreadCrumbs(parentBreadCrumbs)
    };
    digitalData.product = getProductInfo(products);
    setAnalytics(digitalData);
  });
}