import digitalData from 'digital-data';
import {
  OVERLAY, NOT_APPLICABLE, ORDER_SAMPLES
} from '../constants';
import { getState } from '../analytics-state';

export const orderSample = () => {

  const getProductData = (itemId, parent) => {
    const { productpods = [] } = getState();
    const key = `${itemId}-${parent}`;
    return productpods.find((item) => item.key === key);
  };

  window.LIFE_CYCLE_EVENT_BUS.on('order-sample.click', () => {
    const ddoEvent = {
      eventInfo: {
        eventName: ORDER_SAMPLES
      },
      category: {
        primaryCategory: OVERLAY
      },
      event: [{
        subContent: {
          section: 'order fabric sample',
          component: NOT_APPLICABLE,
          target: NOT_APPLICABLE
        }
      }]
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('order-sample.add-to-cart', ({ output }) => {

    const {
      podAnchorSku,
      parent,
      section = 'gridview',
      sharedSection = 'search results',
      sampleId
    } = output || {};

    const {
      pageType = '',
    } = getState() || {};

    const productData = getProductData(podAnchorSku, parent);

    const {
      position: podPosition,
    } = productData || {};

    const items = [{ sampleId, index: podPosition }];

    let stash = {
      component: 'order sample',
      item: items,
      pageType: pageType === 'search' ? 'search results' : 'plp',
      section,
      sharedSection,
      target: 'atc'
    };

    let eventInfo = {
      eventName: 'cart add location'
    };

    const ddoEvent = {
      eventInfo,
      category: {
        primaryCategory: 'stash'
      },
      stash
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};