import digitalData from 'digital-data';
import {
  BUTTON_SUBMISSION, OVERLAY, MILITARY_DISCOUNT, STASH
} from '../constants';

export const militaryDiscount = () => {
  const trackEvent = (event) => {
    digitalData.event = digitalData.event || [];
    digitalData.event.push(event);
  };

  const militaryDiscountEvents = {
    startLink: () => {
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        myAccount: {
          settings: `${MILITARY_DISCOUNT}: get started link`,
        },
      };
      trackEvent(DDOEvent);
    },
    faq: ({ output }) => {
      const { value } = output;

      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        myAccount: {
          settings: `${MILITARY_DISCOUNT}: faq: ${value}`,
        },
      };
      trackEvent(DDOEvent);
    },
    virtualIdBtn: () => {
      const DDOEvent = {
        category: {
          primaryCategory: STASH,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        eventMode: STASH,
        myAccount: {
          settings: 'military discount: benefit page: view virtual id button',
        },
      };
      trackEvent(DDOEvent);
    },
    virtualIdOverlay: () => {
      const DDOEvent = {
        category: {
          primaryCategory: OVERLAY,
        },
        eventInfo: {
          eventName: 'virtual id checkout instructions',
        },
      };
      trackEvent(DDOEvent);
    },
    checkoutInstructionOverlays: ({ output }) => {
      const { checkoutType } = output;
      const DDOEvent = {
        category: {
          primaryCategory: OVERLAY,
        },
        eventInfo: {
          eventName: `virtual id ${checkoutType}`,
        },
      };
      trackEvent(DDOEvent);
    },
    faqLinks: ({ output }) => {
      const { value } = output;

      const DDOEvent = {
        category: {
          primaryCategory: STASH,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        eventMode: STASH,
        myAccount: {
          settings: `military discount: faq: link: ${value}`,
        },
      };
      trackEvent(DDOEvent);
    },
    restartAppLink: () => {
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION
        },
        eventInfo: {
          eventName: 'my account settings'
        },
        myAccount: {
          settings: `${MILITARY_DISCOUNT}: restart application link`
        },
      };
      trackEvent(DDOEvent);
    },
  };

  window.LIFE_CYCLE_EVENT_BUS.on('military-discount-start-link', militaryDiscountEvents.startLink);
  window.LIFE_CYCLE_EVENT_BUS.on('military-discount-faq', militaryDiscountEvents.faq);
  window.LIFE_CYCLE_EVENT_BUS.on(
    'military-discount-virtual-id-btn',
    militaryDiscountEvents.virtualIdBtn
  );
  window.LIFE_CYCLE_EVENT_BUS.on(
    'military-discount-virtual-id-overlay',
    militaryDiscountEvents.virtualIdOverlay
  );
  window.LIFE_CYCLE_EVENT_BUS.on(
    'military-discount-checkout-overlays',
    militaryDiscountEvents.checkoutInstructionOverlays
  );
  window.LIFE_CYCLE_EVENT_BUS.on('military-discount-faq-links', militaryDiscountEvents.faqLinks);
  window.LIFE_CYCLE_EVENT_BUS.on('military-discount-restart-app-link', militaryDiscountEvents.restartAppLink);
};