import digitalData from 'digital-data';
import {
  BUTTON_SUBMISSION, ERROR, OVERLAY, PO_JOB_NAMES, STASH
} from '../constants';

// https://github.com/one-thd/ocm_pd_experience_customer-account-pojob-proallowance
export const poJob = () => {
  const trackEvent = (event) => {
    digitalData.event = digitalData.event || [];
    digitalData.event.push(event);
  };

  const poJobEvents = {
    delete: () => {
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        myAccount: {
          settings: `${PO_JOB_NAMES}: meatball: delete`,
        },
      };
      trackEvent(DDOEvent);
    },

    deleteOverlay: ({ output }) => {
      const { isMultiple } = output;
      const DDOEvent = {
        category: {
          primaryCategory: OVERLAY,
        },
        eventInfo: {
          eventName: `delete po job name${isMultiple ? 's' : ''}`,
        },
      };
      trackEvent(DDOEvent);
    },

    deleteSuccess: ({ output }) => {
      const { count } = output;
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        myAccount: {
          settings: `${PO_JOB_NAMES}: deletion: ${count}`,
        },
      };
      trackEvent(DDOEvent);
    },

    viewPurchases: () => {
      const DDOEvent = {
        category: {
          primaryCategory: STASH,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        eventMode: STASH,
        stash: {
          myAccount: {
            settings: `${PO_JOB_NAMES}: meatball: view purchases`,
          },
        },
      };
      trackEvent(DDOEvent);
    },

    settingsToggle: ({ output }) => {
      const { setting, value } = output;
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        myAccount: {
          settings: `${PO_JOB_NAMES}: checkout settings: ${String(setting).toLowerCase()}: ${String(
            value
          ).toLowerCase()}`,
        },
      };
      trackEvent(DDOEvent);
    },

    addOverlay: () => {
      const DDOEvent = {
        category: {
          primaryCategory: OVERLAY,
        },
        eventInfo: {
          eventName: `add ${PO_JOB_NAMES}`,
        },
      };
      trackEvent(DDOEvent);
    },

    addSuccess: ({ output }) => {
      const { count } = output;
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        myAccount: {
          settings: `${PO_JOB_NAMES}: add: ${count}`,
        },
      };
      trackEvent(DDOEvent);
    },

    multiselect: ({ output }) => {
      const { action } = output;
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        myAccount: {
          settings: `po job names: multi: ${action}`,
        },
      };
      trackEvent(DDOEvent);
    },

    search: ({ output }) => {
      const { count } = output;
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        myAccount: {
          settings: `po job names: search: ${count}`,
          searchBy: 'po job names: search'
        },
      };
      trackEvent(DDOEvent);
    },

    sort: ({ output }) => {
      const { sortedBy, sortDirection } = output;
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        myAccount: {
          settings: 'po job names: sort',
          searchTerm: `${sortedBy} : ${sortDirection}`,
          searchBy: 'po job names: sort'
        },
      };
      trackEvent(DDOEvent);
    },

    tableViewRows: ({ output }) => {
      const { target } = output;
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        myAccount: {
          settings: `po job names: view: ${target}`,
        },
      };
      trackEvent(DDOEvent);
    },

    pagination: ({ output }) => {
      const { rows, pageNumber } = output;
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        myAccount: {
          settings: `po job names: pagination: view ${rows} : page ${pageNumber}`,
        },
      };
      trackEvent(DDOEvent);
    },

    postPageErrors: ({ output }) => {
      const DDOEvent = {
        category: {
          primaryCategory: ERROR,
        },
        eventInfo: {
          eventName: 'post page error',
          error: [
            {
              ...output,
            },
          ],
        },
      };
      trackEvent(DDOEvent);
    }
  };

  window.LIFE_CYCLE_EVENT_BUS.on('po-job-delete', poJobEvents.delete);
  window.LIFE_CYCLE_EVENT_BUS.on('po-job-delete-overlay', poJobEvents.deleteOverlay);
  window.LIFE_CYCLE_EVENT_BUS.on('po-job-delete-success', poJobEvents.deleteSuccess);
  window.LIFE_CYCLE_EVENT_BUS.on('po-job-view-purchases', poJobEvents.viewPurchases);
  window.LIFE_CYCLE_EVENT_BUS.on('po-job-settings-toggle', poJobEvents.settingsToggle);
  window.LIFE_CYCLE_EVENT_BUS.on('po-job-add-overlay', poJobEvents.addOverlay);
  window.LIFE_CYCLE_EVENT_BUS.on('po-job-add-success', poJobEvents.addSuccess);
  window.LIFE_CYCLE_EVENT_BUS.on('po-job-multiselect', poJobEvents.multiselect);
  window.LIFE_CYCLE_EVENT_BUS.on('po-job-sort', poJobEvents.sort);
  window.LIFE_CYCLE_EVENT_BUS.on('po-job-table-view-rows', poJobEvents.tableViewRows);
  window.LIFE_CYCLE_EVENT_BUS.on('po-job-pagination', poJobEvents.pagination);
  window.LIFE_CYCLE_EVENT_BUS.on('po-job-post-page-error', poJobEvents.postPageErrors);
  window.LIFE_CYCLE_EVENT_BUS.on('po-job-search', poJobEvents.search);
};
