import digitalData from 'digital-data';
import {
  BUTTON_SUBMISSION,
  MY_ACCOUNT_SETTINGS,
  OVERLAY,
  DELIVERY_ZIP_UPDATE_EVENT,
  DELIVERY_ZIP_OVERLAY_EVENT
} from '../constants';

export const quoteDetails = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('delivery-zip-update.event', () => {
    const deliveryZipUpdateEventData = {
      category: {
        primaryCategory: BUTTON_SUBMISSION,
      },
      eventInfo: { eventName: MY_ACCOUNT_SETTINGS },
      myAccount: { settings: DELIVERY_ZIP_UPDATE_EVENT },
    };
    digitalData.event.push(deliveryZipUpdateEventData);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('delivery-zip-overlay.event', () => {
    const deliveryZipOverlayEventData = {
      category: {
        primaryCategory: OVERLAY,
      },
      eventInfo: { eventName: DELIVERY_ZIP_OVERLAY_EVENT },
    };
    digitalData.event.push(deliveryZipOverlayEventData);
  });
};
