export const Product = {
  isBrowseOnly: {
    // eslint-disable-next-line id-length
    read(_, { readField }) {
      const fulfillment = readField('fulfillment');
      const availabilityType = readField('availabilityType');

      if (!fulfillment || !availabilityType) return null;

      const fulfillable = !!((fulfillment?.fulfillmentOptions || []).find((option) => option.fulfillable));
      const availableOnline = availabilityType?.type === 'Online';

      return availableOnline && !fulfillable;
    }
  },
  fulfillmentMethod: {
    read() {
      console.log('fulfillmentMethod resolver is deprecated');
      return null;
    }
  }
};
