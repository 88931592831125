/* global newrelic */

export const checkoutListener = () => {

  window.LIFE_CYCLE_EVENT_BUS.on('checkout.pageAction', ({ output }) => {
    const { actionName, actionObject } = output;
    if (actionName && actionObject) {
      window.newrelic.addPageAction(actionName, actionObject);
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('checkout.customTag', ({ output }) => {
    const { tagName, tagValue } = output;
    if (tagName && tagValue) {
      window.newrelic.setCustomAttribute(tagName, tagValue);
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('checkout.noticeError', ({ output }) => {
    const { err } = output;
    if (err) {
      window.newrelic.noticeError(err);
    }
  });

};
