import digitalData from 'digital-data';

export const recsTntFallback = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('recs-tnt.fallback-analytics', ({ output = {} }) => {
    const {
      testApiValue = '',
      fallbackValue = '',
      errorValue = '',
      productsCntValue = 0
    } = output;

    const ddoEvent = {
      category: {
        primaryCategory: 'batch'
      },
      eventInfo: {
        eventName: 'target rec impression'
      },
      recsTntInfo: {
        testApi: testApiValue,
        fallback: fallbackValue,
        error: errorValue,
        productsCnt: productsCntValue
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};