import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollToTop() {

  const location = useLocation();

  useEffect(() => {
    if (!location?.state?.disableScroll) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      });
    }
  }, [location.pathname]);

  return null;
}
