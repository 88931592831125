import digitalData from 'digital-data';
import { isDuplicatePageError, pathToPageSection } from '../thd-analytics-utils';

export const buybox = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('buybox.advisory-message', ({ output }) => {
    const {
      message = '',
      path = []
    } = output || {};

    const pageError = {
      type: 'advisory',
      displayType: 'inline',
      pageSection: pathToPageSection(path),
      message,
      code: null
    };

    digitalData.page = digitalData.page || {};
    digitalData.page.error = digitalData.page.error || [];
    if (!isDuplicatePageError(digitalData, pageError)) {
      // eslint-disable-next-line no-unused-expressions
      digitalData.page.error.push(pageError);
    }
  });
};