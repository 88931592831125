import React from 'react';
import { shape, node, string, bool } from 'prop-types';
import { RedirectContext } from './RedirectContext';

export const RedirectProvider = (props) => {

  const { children } = props;

  return (
    <RedirectContext.Provider value={props}>
      {children}
    </RedirectContext.Provider>
  );

};

RedirectProvider.propTypes = {
  children: node.isRequired
};
RedirectProvider.defaultProps = {
};
