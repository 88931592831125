import digitalData from 'digital-data';
import { CATEGORY_RECOMMENDATION, STANDARD, STASH } from '../constants';

export const topCategories = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('top-categories.click', (output) => {
    const ddoEvent = {
      eventInfo: {
        eventName: CATEGORY_RECOMMENDATION
      },
      category: {
        primaryCategory: STASH
      },
      eventMode: STASH,
      stash: {
        recommendation: {
          categoryRecommendationType: STANDARD
        }
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};