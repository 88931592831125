import digitalData from 'digital-data';
import { BUTTON_SUBMISSION } from '../constants';

export const dualPath = () => {
  window.LIFE_CYCLE_EVENT_BUS.on(
    'dualPath.request-a-quote-action', ({ output: { programCategory = '' } = {} }) => {
      const dualPathRequestAQuoteEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION
        },
        eventInfo: {
          eventName: 'request a quote'
        },
        form: {
          formName: programCategory,
          formType: 'installation services and repair'
        },
      };
      digitalData.event = digitalData.event || [];
      (digitalData.event).push(dualPathRequestAQuoteEvent);
    }
  );
};