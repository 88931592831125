import digitalData from 'digital-data';
import { STASH } from '../constants';

const track = (str) => {
  const thdAnalyticsEvent = {
    category: {
      primaryCategory: STASH,
    },
    eventInfo: {
      eventName: 'my account settings',
    },
    eventMode: STASH,
    stash: {
      myAccount: {
        settings: str,
      },
    },
  };
  digitalData.event = digitalData.event || [];
  digitalData.event.push(thdAnalyticsEvent);
};

const linkClicked = ({ output = {} }) => {
  const { route } = output;

  switch (route) {
  case 'paintPurchaseHistory':
    track('my account: left nav click: paint purchase history');
    break;
  case 'projects':
    track('projects: entry point: left nav click');
    break;
  default:
    break;
  }
};

export const customerAccountNavigation = () => {
  window.LIFE_CYCLE_EVENT_BUS.on(
    'customer-account-navigation.link-click',
    linkClicked,
  );
};
