/* global newrelic */

export const shouldIgnoreBinding = () => {
  newrelic.setErrorHandler(function (err) {
    if (err && err.message) {
      const message = err.message;
      if (message === 'ResizeObserver loop limit exceeded') {
        return true;
      }
    }
    return false;
  });
};
