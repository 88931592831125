/* global newrelic */

export const giftCardsListener = () => {

  window.LIFE_CYCLE_EVENT_BUS.on('gift-cards.pageAction', ({ output }) => {
    const { actionName, actionObject } = output;
    if (actionName && actionObject) {
      window.newrelic.addPageAction(actionName, actionObject);
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('gift-cards.customTag', ({ output }) => {
    const { tagName, tagValue } = output;
    if (tagName && tagValue) {
      window.newrelic.setCustomAttribute(tagName, tagValue);
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('gift-cards.noticeError', ({ output }) => {
    const { err } = output;
    if (err) {
      window.newrelic.noticeError(err);
    }
  });

};