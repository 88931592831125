/* global newrelic */
import { setState, getState } from '../state';

const shouldSuppressError = ({ message }) => {
  if (/-\scancelled$/.test(message)) {
    return true;
  }

  return false;
};

export const dataSources = () => {
  // this is happening way too much and need to put in some fix
  // window.LIFE_CYCLE_EVENT_BUS.on('data-sources.data', ({ output }) => {
  //   const { variables: { itemId }, data, queryName } = output;
  //   if (itemId) {
  //     const products = {
  //       [itemId]: data,
  //       currentItemId: itemId
  //     };
  //     setState({ products });
  //   }
  //
  //   if (queryName) {
  //     newrelic.addPageAction('operationsClient', {
  //       queryName
  //     });
  //   }
  //
  // });

  window.LIFE_CYCLE_EVENT_BUS.on('data-sources.trace', ({ output }) => {
    const { parentTraceId, traceId, mcvsId } = output;
    if (typeof traceId !== 'undefined' && typeof parentTraceId !== 'undefined' && typeof mcvsId !== 'undefined') {
      newrelic.addPageAction('operationsClient', {
        parentTraceId,
        traceId,
        mcvsId
      });
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('data-sources.error', ({ output }) => {
    const {
      message, variables, parentTraceId = '', traceId = '', mcvsId = ''
    } = output;
    if (typeof newrelic !== 'undefined' && message) {
      // newrelic.noticeError(message);
      const cookieSize = document.cookie.length;
      const queryString = document.location.search;
      const shouldLog = !shouldSuppressError({ message, variables });
      if (shouldLog) {
        newrelic.addPageAction('GQLError', {
          message,
          cookieSize,
          queryString,
          gqlVariables: JSON.stringify(variables || {}),
          parentTraceId,
          traceId,
          mcvsId
        });
      }
    }
  });
};
