import digitalData from 'digital-data';
import { setAnalytics } from '../thd-analytics-utils';
import { STYLEQUIZ_PAGETYPE_COLLECTION, STYLEQUIZ_PRIMARY_CATEGORY } from '../constants';

export const quiz = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('result.load', ({ output = {} }) => {
    const {
      pageName = '',
      subCategory1 = '',
      subCategory2 = ''
    } = output;

    const ddoEvent = {
      page: {
        pageInfo: {
          pageName,
          pageType: STYLEQUIZ_PAGETYPE_COLLECTION
        },
        category: {
          primaryCategory: STYLEQUIZ_PRIMARY_CATEGORY,
          subCategory1,
          subCategory2
        }
      }
    };

    if (digitalData) {
      digitalData.page = ddoEvent.page;
      setAnalytics(digitalData);
    }
  });
};