import digitalData from 'digital-data';
import { getState, setState } from '../analytics-state';
import { PRIMARY_FILTER } from '../constants';
import { getFilters } from '../thd-analytics-utils';

export const getPageInfoFilters = (arr) => {
  let appliedFilters = [];
  arr.forEach((item, index) => {
    item = item.map(({ filterType, ...rest }) => ({ ...rest }));
    appliedFilters.push(item);
  });
  return appliedFilters;
};

export const processFilters = (newLayout, filterType) => {
  const state = getState();
  const { pageType, currentPage, sortBy } = state || {};
  let finalSelectedFilters = [];
  digitalData.event = digitalData.event || [];
  digitalData.page = digitalData.page || [];

  // Setting pageTransitionEvent as PRIMARY_FILTER, ReInitAnalytics won't trigger
  setState({
    ...getState(),
    pageTransitionEvent: PRIMARY_FILTER,
  });

  // Find all pre-selected filters, if present
  let lastevent = digitalData.event[digitalData.event.findLastIndex((item) => item.hasOwnProperty('filter'))];

  // Check if Clear All btn is clicked
  if (!newLayout?.output?.clear) {
    if (lastevent && lastevent.filter) {
        // loop through the event passed args
        newLayout?.output?.forEach((filterIndex = {}, indexf) => {
          const selectedFilters = [];
          newLayout?.output?.[indexf]?.refinements.forEach((crumb = {}, index) => {
            // compare lastevent(previous selected filters) and current event(new filters)
            // If the filter already present in lastevent then push the same
            // otherwise create new with given filterType for new event
            let _rowItem;
            lastevent.filter.map((arr) => arr.map((item) => {
              if (item.value === crumb?.label) return _rowItem = item;
            }));

            if (_rowItem) {
              selectedFilters.push(_rowItem);
            } else {
              selectedFilters.push({
                type: newLayout?.output?.[indexf].label,
                value: crumb?.label,
                filterType,
              });
            }
          });
          // append the new filters into previous selected filters
          finalSelectedFilters.push(selectedFilters);
        });
    } else {
        // If first filter selection is done
        newLayout?.output?.forEach((filterIndex = {}, indexf) => {
          const selectedFilters = [];
          newLayout?.output?.[indexf]?.refinements.forEach((crumb = {}, index) => {
            if (!crumb?.label) return;
            selectedFilters.push({
              type: newLayout?.output?.[indexf].label,
              value: crumb?.label,
              filterType,
            });
          });
          finalSelectedFilters.push(selectedFilters);
        });
    }
  }

  digitalData.event.push({
    category: { primaryCategory: pageType || '' },
    eventInfo: { eventName: 'filter By' },
    displayAttributes: {
      pageNumber: currentPage || 0,
      productsAvailabilty: 'all products',
      sortBy: sortBy || 'bestmatch',
      viewType: 'grid',
    },
    filter: finalSelectedFilters,
  });

  digitalData.page.filter = finalSelectedFilters.length ? getPageInfoFilters(finalSelectedFilters) : getFilters(finalSelectedFilters);
  // update state with addition/removal of filters
  setState({
    ...getState(),
    filters: finalSelectedFilters
  });
};

/* Format and arrange the refinements passed down
 into new data structure i.e Arrays of arrays */
export const transformArray = (input) => {
  const result = [];

  // A helper object to group items by dimension
  const groups = {};

  // Process each array in the input
  input.forEach((subArray) => {
    subArray.forEach((item) => {
      const dimensionName = item.type || item.dimensionName;
      const label = item.value || item.label;

      if (!groups[dimensionName]) {
        groups[dimensionName] = [];
      }

      groups[dimensionName].push({ dimensionName, label });
    });
  });

  // Convert grouped items into the required output format
  for (const [dimensionName, items] of Object.entries(groups)) {
    result.push({
      label: dimensionName,
      refinements: items
    });
  }

  return result;
};

export const updateFilters = (filters, output) => {
  const { dimensionName, label, selected } = output;

  // Find the group that matches the output's dimensionName
  const group = filters.find((group) => group.some((filter) => filter.type === dimensionName || filter.dimensionName === dimensionName));

  if (selected === null) {
    // Add the filter to the group or create a new group if it doesn't exist
    group ? group.push({ ...output }) : filters.push([{ ...output }]);
  } else if (group) {
    // Remove the filter from the group
    group.splice(group.findIndex((filter) => filter.value === label), 1);
    // Remove the group if empty
    if (!group.length) filters.splice(filters.indexOf(group), 1);
  }

  return filters;
};

export const formatFilters = (newLayout) => {
  let { filters = [] } = getState();
  let { output = {} } = newLayout;

  if (output?.clear) {
    filters = [];
  } else if (output?.dimensionName) {
    filters = updateFilters(filters, output);
  }
  newLayout.output = transformArray(filters);
  processFilters(newLayout, 'others');
};

export const primaryFilters = () => {
  // when user selects from primary filters
  window.LIFE_CYCLE_EVENT_BUS.on('product-results.change-filters-refinements', (newLayout = {}) => {
    processFilters(newLayout, 'primary');
  });

  // when user selects from secondary filters
  window.LIFE_CYCLE_EVENT_BUS.on('product-results.change-filters-refinements-secondary', (newLayout = {}) => {
    processFilters(newLayout, 'secondary');
  });

  // when user removes any one filters from chips
  window.LIFE_CYCLE_EVENT_BUS.on('product-results.change-filters-refinements-shortings', (newLayout = {}) => {
    processFilters(newLayout, 'secondary');
  });

  // when user clicks on clear all (removes all filters from chips)
  window.LIFE_CYCLE_EVENT_BUS.on('product-results.change-filters-refinements-clear-all', (newLayout = {}) => {
    processFilters(newLayout, 'clearAllFilters');
  });

  // when user selects from visual-Nav (getItFast filters)
  window.LIFE_CYCLE_EVENT_BUS.on('filters-refinements-visualNav.click', (newLayout = {}) => {
    formatFilters(newLayout);
  });
};
