/* global newrelic */
import { setState, getState } from '../state';

export const buyboxListener = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('buybox.loaded', ({ output }) => {
    const { start, end } = output;
    if (typeof start !== 'undefined') {
      newrelic.addPageAction('BuyboxLoaded', {
        buyboxStart: start,
        buyboxEnd: end,
      });
    }
  });
};
