export const AvailabilityType = {
  isFulfillableIRGSku: {
    // eslint-disable-next-line id-length
    read(_, { readField }) {
      const buyable = readField('buyable');
      const discontinued = readField('discontinued');
      const status = readField('status');
      return !!(buyable && status && !discontinued);
    }
  }
};
