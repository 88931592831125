import digitalData from 'digital-data';
import { MY_HOMEPAGE, STASH, MODULE_CLICK, NOT_APPLICABLE } from '../constants';

export const marketingContent = () => {
  const publishAnalytics = (output, widgetElement = 'banner') => {
    const {
      widgetId,
      widgetTemplates,
      widgetCampaign,
      widgetComponent,
    } = output;
    const ddoEvent = {
      category: {
        primaryCategory: STASH,
      },
      eventInfo: {
        eventName: MODULE_CLICK,
      },
      eventMode: STASH,
      stash: {
        pageType: MY_HOMEPAGE,
        content: {
          widgets: [{
            action: NOT_APPLICABLE,
            id: widgetId,
            name: NOT_APPLICABLE,
            templates: widgetTemplates,
            campaign: widgetCampaign,
            component: widgetComponent,
            element: widgetElement
          }]
        }
      },
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  };

  window.LIFE_CYCLE_EVENT_BUS.on('spotlight.content-click', ({ output = {} }) => {
    publishAnalytics(output);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('hero-flatten.content-click', ({ output = {} }) => {
    publishAnalytics(output);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('capabilities-tiles-click', ({ output = {} }) => {
    publishAnalytics(output, 'capabilities');
  });
};