import React from 'react';
import {
  node, arrayOf, oneOfType, string, shape, bool
} from 'prop-types';

import {
  BrowserRouter as ReactBrowserRouter,
} from 'react-router-dom';
import { CrossExperience } from './CrossExperience';

const BrowserRouter = ({ children, experience, disableCrossExpCheck }) => {

  return (
    <ReactBrowserRouter>
      <CrossExperience experience={experience} disableCrossExpCheck={disableCrossExpCheck}>
        {children}
      </CrossExperience>
    </ReactBrowserRouter>
  );
};

BrowserRouter.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  /**
   * The current nucleus experience. This is used to call full page reloads
   * when there is a pushState or replaceState SPA transition.
   *
   * Leave null to prevent window.location change on SPA transitions cross experience
   */
  experience: string,
  disableCrossExpCheck: bool,
};

BrowserRouter.defaultProps = {
  experience: null,
  disableCrossExpCheck: false
};
export { BrowserRouter };
