import digitalData from 'digital-data';

export const deliveryOptions = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('delivery-options.click', ({ output = {} }) => {
    const {
      eventName,
      primaryCategory
    } = output;

    const ddoEvent = {
      eventInfo: {
        eventName
      },
      category: {
        primaryCategory
      }
    };
    digitalData.event.push(ddoEvent);
  });
};