import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';
import { BIA, BIA_PAGE_TYPE } from '../../../constants';

export function buyItAgain() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('buyItAgain.page-load', () => {
    digitalData.page = {
      pageInfo: {
        pageName: BIA,
        pageType: BIA_PAGE_TYPE
      },
      category: {
        primaryCategory: BIA,
        subCategory1: BIA,
        subCategory2: BIA

      }
    };
    setAnalytics(digitalData);
  });
}