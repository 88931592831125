/* eslint-disable no-param-reassign */
import {
  getDisplayInfoFromStorage,
  setDisplayAnalytics,
  serviceCall
} from './util';

const DISPLAY_TIME_KEY = 'checkoutPromptsOperationTime';
// {
//   "showTime": 1658952112665,
//   "validTill": 1658952112665,
//   "displayedTime": 1658952112670
// }

const getTimesFromService = async (displayData) => {
  let operationTimes = {};
  try {
    const timingResponse = await serviceCall(displayData);
    // create operationTimes
    const duration = timingResponse?.validTill ? (timingResponse.validTill * 60 * 1000) : 3600000; // default to 1 hour
    operationTimes.validTill = (Date.now() + duration);

    if (timingResponse?.checkoutPrompt?.timeToShow) {
      const delay = (timingResponse.checkoutPrompt.timeToShow * 1000);
      // store the time at which checkout prompts should be displayed
      operationTimes.showTime = (Date.now() + delay);
    }

    // put in localStorage
    localStorage.setItem(DISPLAY_TIME_KEY, JSON.stringify(operationTimes));
  } catch (error) {
    console.error(error);
  }
  return operationTimes;
};

const getOperationTimes = async (displayData) => {
  let operationTimes = null;
  try {
    operationTimes = JSON.parse(localStorage.getItem(DISPLAY_TIME_KEY));
    if (
      !operationTimes
      || !operationTimes.validTill
      || (operationTimes.validTill && (Date.now() > operationTimes.validTill))
    ) {
      operationTimes = await getTimesFromService(displayData);
    }
  } catch (error) {
    console.error(error);
  }
  return operationTimes;
};

const setDisplayTime = () => {
  try {
    let operationTimes = JSON.parse(localStorage.getItem(DISPLAY_TIME_KEY)) || {};
    // store the time at which checkout prompts is displayed
    operationTimes.displayedTime = Date.now();
    localStorage.setItem(DISPLAY_TIME_KEY, JSON.stringify(operationTimes));
  } catch (error) {
    console.error(error);
  }
};

export const displayComponent = async (updateState) => {
  const displayData = getDisplayInfoFromStorage();
  if (displayData) {
    const operationTimes = await getOperationTimes(displayData);
    if (operationTimes && operationTimes.showTime && !operationTimes.displayedTime) {
      const delay = (operationTimes.showTime - Date.now());
      setTimeout(() => {
        updateState(displayData);
        setDisplayTime();
        setDisplayAnalytics();
      }, delay);
    }
  }
};

// export const displayComponent = async (updateState) => {
//   const storedValues = getStoredValues();
//   if (storedValues) {
//     const { displayData, operationTime, expired } = storedValues;
//     const { showTime, displayedTime } = operationTime;
//     if (expired || !showTime) {
//       const { validTill, timeToShow } = await serviceCall(displayData);
//       if (validTill && timeToShow) {
//         setShowTime(timeToShow, validTill);
//       }
//     }
//     if (!displayedTime && showTime) {
//       triggerTimerForDisplay(displayData, updateState, showTime - Date.now());
//     }
//   }
// };