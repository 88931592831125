import digitalData from 'digital-data';
import { BUTTON_SUBMISSION } from '../constants';

const searchChange = ({ output = {} }) => {
  const {
    searchBy,
    searchValue
  } = output;
  const thdAnalyticsEvent = {
    category: {
      primaryCategory: BUTTON_SUBMISSION
    },
    eventInfo: {
      eventName: 'my account settings'
    },
    myAccount: {
      settings: 'paint history: search',
      searchBy: `${searchBy.toLowerCase()}:${searchValue.toLowerCase()}`
    }
  };

  digitalData.event = digitalData.event || [];
  digitalData.event.push(thdAnalyticsEvent);
};

export const searchDropdown = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('search-dropdown.search-change', searchChange);
};