import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';
import {
  MY_ACCOUNT, ACCOUNT,
  COMMUNICATION_PREFERENCES
} from '../../../constants';

export function communicationPreferences() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('communicationPreferences.page-load', () => {
    const pageName = COMMUNICATION_PREFERENCES;
    const pageType = MY_ACCOUNT;
    const primaryCategory = ACCOUNT;
    const subCategory1 = COMMUNICATION_PREFERENCES;
    const subCategory2 = COMMUNICATION_PREFERENCES;

    digitalData.page = {
      pageInfo: {
        pageName,
        pageType,
      },
      category: {
        primaryCategory,
        subCategory1,
        subCategory2,
      },

    };

    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('communicationPreferences.static.page-load', ({ output = {} }) => {
    const {
      pageName, pageType, primaryCategory, subCategory1, subCategory2
    } = output;

    digitalData.page = {
      pageInfo: {
        pageName,
        pageType,
      },
      category: {
        primaryCategory,
        subCategory1,
        subCategory2,
      },
    };

    setAnalytics(digitalData);
  });
}