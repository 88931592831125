import digitalData from 'digital-data';

export const promotion = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('promotion.click', () => {
    const ddoEvent = {
      eventInfo: {
        eventName: 'promotion details'
      },
      category: {
        primaryCategory: 'tooltip'
      }
    };
    digitalData.event = digitalData.event || [];
    (digitalData.event).push(ddoEvent);
  });
};