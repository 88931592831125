import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import { CLICKTHROUGH, MODULE_CLICK, STASH } from '../constants';

export const categoryList = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('category-list.click', ({ output }) => {
    const {
      category,
      linkText
    } = output;
    const state = getState();
    const { pageType } = state || {};
    const ddoEvent = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: MODULE_CLICK
      },
      eventMode: STASH,
      stash: {
        pageType,
        content: {
          widgets: [
            {
              action: CLICKTHROUGH,
              id: null,
              name: 'subcontent_decor',
              templates: 'left rail attribute',
              component: category,
              element: linkText
            }
          ]
        }
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};