import digitalData from 'digital-data';
import { MY_HOMEPAGE, STASH, MODULE_CLICK, NOT_APPLICABLE } from '../constants';

export const userListsCarousel = () => {
  const publishAnalytics = (output) => {
    const {
      widgetTemplates,
      widgetCampaign,
      widgetComponent,
    } = output;
    const ddoEvent = {
      category: {
        primaryCategory: STASH,
      },
      eventInfo: {
        eventName: MODULE_CLICK,
      },
      eventMode: STASH,
      stash: {
        pageType: MY_HOMEPAGE,
        content: {
          widgets: [{
            action: NOT_APPLICABLE,
            id: 'lists',
            name: NOT_APPLICABLE,
            templates: widgetTemplates,
            campaign: widgetCampaign,
            component: widgetComponent,
            element: 'lists'
          }]
        }
      },
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  };

  window.LIFE_CYCLE_EVENT_BUS.on('user-lists-carousel.viewSelectedLists', ({ output = {} }) => {
    publishAnalytics(output);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('user-lists-carousel.viewAllLists', ({ output = {} }) => {
    publishAnalytics(output);
  });
};