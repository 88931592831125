import digitalData from 'digital-data';
import { INTERACTION, PIP_SUB_CONTENT } from '../constants';

export const stashEvent = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('zone-b.click', ({ output = {} }) => {
    const {
      component, target, section = 'highlights'
    } = output;

    const ddoStashEvent = {
      eventInfo: {
        eventName: PIP_SUB_CONTENT
      },
      category: {
        primaryCategory: INTERACTION
      },
      subContent: {
        component,
        target,
        section
      }
    };
    digitalData.event = digitalData.event || [];
    (digitalData.event).push(ddoStashEvent);
  });
};