import digitalData from 'digital-data';
import { STASH } from '../constants';
import { getState } from '../analytics-state';

export const rentalIntent = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('rental-intent-pod.check-availability', ({ output = {} }) => {
    const {
      podType,
      podAction,
      podPosition,
      podInteractionNumber
    } = output;

    const { pageSize, currentPage } = getState() || {};

    const ddoEvent = {
      eventInfo: {
        eventName: 'product pod click'
      },
      category: {
        primaryCategory: STASH
      },
      eventMode: STASH,
      stash: {
        podType,
        podAction,
        podPosition,
        podPageNumber: currentPage,
        podInteractionNumber,
        podPageResults: pageSize
      },
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};