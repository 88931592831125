/* global newrelic */
export const rental = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('rental-api-sources.error', ({ output }) => {
    if (typeof newrelic !== 'undefined') {
      newrelic.addPageAction('RentalApiError', output);
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('rental-api-sources.success', ({ output }) => {
    if (typeof newrelic !== 'undefined') {
      newrelic.addPageAction('RentalApiSuccess', output);
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('rental-reservation-form.add-to-cart', ({ output }) => {
    if (typeof newrelic !== 'undefined') {
      const { cartReqParams, path } = output;

      if (cartReqParams) {
        newrelic.addPageAction('RentalAddToCart', {
          ...cartReqParams,
          domPath: (path || []).join('>')
        });
      }
    }
  });
};
