import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';

export function projectSharing() {
  const projectSharingEvents = {
    load: ({ output }) => {
      const isLoggedIn = output?.isLoggedIn || false;
      const customerType = output?.customerType || '';
      const hasProjectBenefit = output?.hasProjectBenefit || false;

      if (customerType === 'B2B' && isLoggedIn && hasProjectBenefit) {
        digitalData.page = {
          pageInfo: {
            pageName: 'account>project details',
            pageType: 'maml:projects',
          },
          category: {
            primaryCategory: 'account',
            subCategory1: 'account>projects',
            subCategory2: 'account>projects>project details',
          },
        };
        return setAnalytics(digitalData);
      }

      if (customerType === 'B2B' && isLoggedIn && !hasProjectBenefit) {
        digitalData.page = {
          pageInfo: {
            pageName: 'account>project details',
            pageType: 'maml:projects',
          },
          category: {
            primaryCategory: 'account',
            subCategory1: 'account>project details',
            subCategory2: 'account>project details',
          },
        };
        return setAnalytics(digitalData);
      }

      if (customerType === 'B2C' && isLoggedIn) {
        digitalData.page = {
          pageInfo: {
            pageName: 'account>project details',
            pageType: 'maml:projects',
          },
          category: {
            primaryCategory: 'account',
            subCategory1: 'account>project details',
            subCategory2: 'account>project details',
          },
        };
        return setAnalytics(digitalData);
      }

      digitalData.page = {
        pageInfo: {
          pageName: 'project details',
          pageType: 'maml:projects',
        },
        category: {
          primaryCategory: 'project details',
          subCategory1: 'project details',
          subCategory2: 'project details',
        },
      };
      return setAnalytics(digitalData);
    },
  };

  // 7.51.35 Project Details Page - Collaborator B2B/B2C View
  LIFE_CYCLE_EVENT_BUS.on('project-details-page-view-analytics-b2b-b2c', projectSharingEvents.load);
}
