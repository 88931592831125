import digitalData from 'digital-data';

const sponsoredTracking = (sponsoredViewBeacons) => {
  const imgs = sponsoredViewBeacons
    .filter(Boolean)
    .map((trackingLink) => {
      const img = document.createElement('img');
      img.src = trackingLink;
      return img;
    });

  if (imgs.length > 0) {
    const impressionDiv = document.createElement('div');
    impressionDiv.className = 'onviewbeacon';
    impressionDiv.style = 'display: none';
    impressionDiv.append(...imgs);
    document.body.appendChild(impressionDiv);
  }
};

export const impressionProvider = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('impression.trigger', ({ output }) => {
    const { sponsoredViewBeacons, sponsoredViewBeacon } = output?.impression || {};
    const sponsoredTrackingLinks = sponsoredViewBeacons || [sponsoredViewBeacon];
    sponsoredTracking(sponsoredTrackingLinks);

    const ddoEvent = {
      category: {
        primaryCategory: 'batch'
      },
      eventInfo: {
        eventName: 'viewport impression'
      },
      clickID: output?.clickID,
      container: output?.container,
      impression: output?.impression,
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};