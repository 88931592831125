import digitalData from 'digital-data';

export const loyaltyBenefits = () => {
  const pageEvents = {
    buttonClick: ({ output }) => {
      const { settings } = output;
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        eventInfo: {
          eventName: 'my account settings',
        },
        category: {
          primaryCategory: 'button submission',
        },
        myAccount: {
          settings,
        },
      });
    },
    stash: ({ output }) => {
      const { settings } = output;
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        eventInfo: {
          eventName: 'my account settings',
        },
        eventMode: 'stash',
        category: {
          primaryCategory: 'stash',
        },
        stash: {
          myAccount: {
            settings,
          },
        },
      });
    },
    overlay: ({ output }) => {
      const { settings } = output;
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        eventInfo: {
          eventName: settings,
        },
        category: {
          primaryCategory: 'overlay',
        },
      });
    },
  };

  window.LIFE_CYCLE_EVENT_BUS.on('loyalty-benefits.loyalty-button', pageEvents.buttonClick);
  window.LIFE_CYCLE_EVENT_BUS.on('loyalty-benefits.loyalty-stash', pageEvents.stash);
  window.LIFE_CYCLE_EVENT_BUS.on('loyalty-benefits.loyalty-overlay', pageEvents.overlay);
};
