import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, initAsyncRouting } from '@thd-olt-component-react/router';
import { LayoutManagerProvider } from '@thd-olt-component-react/layout-manager';
import { RedirectProvider } from '@thd-nucleus/redirector';
import { CustomerProvider } from '@thd-olt-functional/customer-information';
import { ThemeProvider } from '@thd-olt-component-react/theme-provider';
import { CheckoutPrompts } from '@thd-olt-component-react/checkout-prompts';
import { authHeadersForAPI, getHeadersForAPI } from '@thd-nucleus/request-manager/client';
import { HelmetProvider as ThdHelmetProvider } from '@thd-nucleus/thd-helmet';
import { ContextBuilder, ExperienceProvider } from '@thd-nucleus/experience-context';
import { DataProviderClient } from '@thd-nucleus/data-sources';
import { MockLink } from '@thd-nucleus/data-sources/dev';
import camelcase from 'camelcase';
import { getServerProps } from './server-to-client';
import { AppProvider } from './Providers/AppProvider';
import { AppErrorHandler } from './components/AppErrorHandler';
import { withBypassComponent } from './withBypassComponent';
import { hashCode } from './app-render.util';

function getChapterMocks({
  useMock = false,
  mocks,
  currentRouteName,
}) {
  if (!useMock || !mocks) return null;

  /**
   * This will be the new way to specify mocks.
   *
   * If they pass an array with the new object shape we expect, we can use that
   * to construct chapter-specific mocks for each page load. The incoming `mocks`
   * should look like:
   *
   * [ { chapterName: 'overview', mocks: [...] }]
   *
   * as opposed to an array of top-level `mockRequestResponse`-returned objects.
   */
  const mock = mocks?.[0];
  if (mock?.chapterName && mock?.mocks) {
    const currentChapter = mocks.find(({ chapterName, mocks: chapterMocks }) => {
      if (chapterName !== camelcase(currentRouteName)) {
        return false;
      }

      return chapterMocks;
    });

    return currentChapter ? new MockLink(currentChapter.mocks, false) : null;
  }

  /**
   * We will still support the old way of doing things for the time being.
   * So, if you don't pass an object that conforms to the new structure outlined above,
   * we will just use the `MockLink` generated in the consumer's `client.js` the same way
   * we have up until now.
   */
  return mocks;
}

class ClientContext {

  constructor({ mockLink, opts = {} } = {}) {
    const serverData = getServerProps();
    const {
      headers, opts: serverOpts, featureVersion, requestedExperience
    } = serverData;
    this.opts = {
      disableLocalization: false,
      ...serverOpts,
      ...opts
    };

    this.authHeaders = {
      ...authHeadersForAPI,
    };
    const expName = window.experienceMetadata?.name;

    this.headers = {
      'x-experience-name': expName || 'error',
      ...headers,
      ...getHeadersForAPI()
    };
    const { name } = serverData.config;
    let { useMock } = serverData.config;
    const [_, env] = /env=(\w+)/.exec(window.location.search) || [];
    if (env) {
      useMock = false;
    }

    if (window.experienceMetadata) {
      window.experienceMetadata.featureVersion = featureVersion;
      window.experienceMetadata.requestedExperience = requestedExperience;
    }

    this.name = name;
    this.serverData = serverData;
    this.disableSSR = window.DISABLE_SSR;
    const { experienceContext } = ContextBuilder.client({ expConfiguration: this.serverData.expConfiguration });
    this.expContext = {
      experienceContext
    };

    this.apollo = {
      useMock,
      clientHost: this.expContext.experienceContext.hosts.apionline,
      link: getChapterMocks({ useMock, mocks: mockLink, currentRouteName: this.serverData.currentRouteName }),
    };
    this.expConfiguration = this.serverData.expConfiguration;
    this.thdSEOBotDetection = this.serverData.thdSEOBotDetection;
    this.layoutManagerRules = {
      expressions: [],
      managers: []
    };

    this.theme = {
      name: serverData.themeName
    };

    this.redirect = {
      ...serverData.redirect
    };

    this.customer = this.serverData.customer;

    this.router = {
      currentRouteName: serverData.currentRouteName,
      enabled: true
    };

    const productPodHoverDelay = parseInt(this.expConfiguration['spa.product-pod-hover-delay'], 10);

    this.featureSwitches = {
      apolloFederation: typeof this.opts.federation !== 'undefined'
        ? this.opts.federation
        : this.expConfiguration['apollo.federation'] === 'true',
      dynamicBotRendering: this.expConfiguration['fs-dynamic-bot-rendering'] === 'true',
      disableCrossExpCheck: this.expConfiguration['spa.disable-cross-exp-check'] === 'true',
      prefetchPip: this.expConfiguration['spa.prefetch-pip'] || 'both',
      useClientApiHost: typeof this.opts.useClientHost !== 'undefined'
        ? this.opts.useClientHost
        : this.expConfiguration['apollo.use-client-host'] === 'true',
      apolloCredentials: this.expConfiguration['apollo.credentials'],
      productPodHoverDelay: Number.isNaN(productPodHoverDelay) ? 500 : productPodHoverDelay
    };
    if (typeof this.opts.useClientHost !== 'undefined' && console.warn) {
      console.warn('ClientContext.useClientHost should only be used for local development');
    }
    if (this.opts.component) {
      this.disableRouter();
    }

    if (window.__FETCH_CACHE) {
      this.fetchBody = window.__FETCH_CACHE;
    }
  }

  disableRouter() {
    this.router.enabled = false;
  }

  disableLocalization() {
    this.opts.disableLocalization = true;
  }

  setExperienceRoutes(routes) {
    this.router.routes = routes;
  }

  setHeader(key, value) {
    this.headers[key] = value;
  }

  setLayoutManagerRules(rules) {
    this.layoutManagerRules = rules;
  }

  setStore(store) {
    this.expContext.experienceContext.store = store;
  }

  setClientStore(store) {
    this.expContext.experienceContext.clientStore = store;
  }

  getRouterConfig() {
    const routingConfig = {
      currentRouteName: this.router.currentRouteName,
      routes: this.router.routes,
      asyncChunks: this.serverData.asyncChunks,
      channel: this.expContext.experienceContext.channel
    };

    return routingConfig;
  }

  setTheme(opts, templateDep) {
    let name;
    let template;
    if (typeof opts === 'string') {
      // eslint-disable-next-line max-len
      console.log('warning, setTheme takes a object with {name, template}. calling setTheme(name, template) is deprecated');
      name = opts;
      template = templateDep;
    } else if (typeof opts === 'object') {
      name = opts.name;
      template = opts.template;
    }

    if (name !== this.theme.name) {
      console.log(`Warning: theme name does not match server: (Server: ${this.theme.name} / Client: ${name} `);
    } else {
      this.theme.template = template;
    }

  }

  async fetch(url, opts = {}, cacheKey) {
    const options = {
      ...opts,
      headers: {
        ...opts.headers,
      }
    };
    if (this.opts.component) {
      options.headers['x-component-name'] = 'dev-component';
    } else {
      options.headers['x-experience-name'] = this.headers['X-Experience-Name'];
    }
    if (this.fetchBody) {
      let code = 'code';
      if (options.body) {
        code = hashCode(options.body);
      }
      let hashKey = `${`${url}:${code}`}`;
      if (typeof cacheKey === 'function') {
        hashKey = cacheKey({ url, hashCode: code });
      }
      const cachedResponse = this.fetchBody[hashKey];
      if (cachedResponse) {
        return Promise.resolve(cachedResponse);
      }
    }
    const response = await fetch(url, options);
    const data = await response.json();
    return data;
  }

  async render(App) {

    const render = this.disableSSR
      ? ReactDOM.render
      : ReactDOM.hydrate;

    const Router = withBypassComponent(BrowserRouter, this.router.enabled);
    const Redirect = withBypassComponent(RedirectProvider, this.router.enabled);
    const LMProvider = withBypassComponent(LayoutManagerProvider, !!this.layoutManagerRules.managers.length);

    const node = document.getElementById('root');

    const thdHelmetContext = {};

    if (this.router.enabled) {
      this.router.currentRouteComponent = await initAsyncRouting(this.getRouterConfig());
    }

    if (Object.keys(this.layoutManagerRules).length === 0) {
      console.warn('No layout manager rules have been specified!');
    }

    return render(
      <AppErrorHandler debug={window.isDebugMode}>
        <AppProvider instance={this}>
          <CustomerProvider>
            <ExperienceProvider
              value={this.expContext.experienceContext}
              disableLocalization={this.opts.disableLocalization}
            >
              <LMProvider featureSwitch={this.expConfiguration} context={this.layoutManagerRules}>
                <HelmetProvider>
                  <ThdHelmetProvider context={thdHelmetContext}>
                    <Router
                      experience={this.name}
                      disableCrossExpCheck={this.featureSwitches.disableCrossExpCheck}
                    >
                      <DataProviderClient
                        debug={window.isDebugMode}
                        link={this.apollo.link}
                        federation={this.featureSwitches.apolloFederation}
                        headers={this.headers}
                        authHeaders={this.authHeaders}
                        host={this.featureSwitches.useClientApiHost ? this.apollo.clientHost : undefined}
                        credentials={this.featureSwitches.apolloCredentials}
                        addTypename={!this.apollo.useMock}
                      >
                        <ThdHelmetProvider context={thdHelmetContext}>
                          <Redirect
                            bypass={this.redirect.bypassRedirect}
                            pathWithQueryParams={this.redirect.path}
                            itemId={this.redirect.itemId}
                            packageId={this.redirect.packageId}
                            navParam={this.redirect.navparam}
                            keyword={this.redirect.keyword}
                            nttKeyword={this.redirect.nett}
                            timeoutRecovery
                          >
                            <ThemeProvider theme={this.theme.template}>
                              {App}
                              <CheckoutPrompts />
                            </ThemeProvider>
                          </Redirect>
                        </ThdHelmetProvider>
                      </DataProviderClient>
                    </Router>
                  </ThdHelmetProvider>
                </HelmetProvider>
              </LMProvider>
            </ExperienceProvider>
          </CustomerProvider>
        </AppProvider>
      </AppErrorHandler>,
      node
    );
  }
}

export { ClientContext };
