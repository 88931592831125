import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';
import {
  NUCLEUS, BROWSE_RESULTS, PLP, STASH, SELF_SERVICE, SELF_SERVICE_PROPANE, EMPTY, LIST, ATC, CART_ADD_LOCATION
} from '../../../constants';

export function selfService() {
  window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('self-service.page-load', ({ output }) => {
    const data = output?.products || {};

    digitalData.content = {
      product: data.map((sku) => {
        return (
          { productInfo: { sku } }
        );
      })
    };

    digitalData.page = {
      category: {
        primaryCategory: SELF_SERVICE,
        subCategory1: SELF_SERVICE_PROPANE,
        subCategory2: SELF_SERVICE_PROPANE,
        headerCrumb: SELF_SERVICE_PROPANE
      },
      displayAttributes: {
        pageNumber: EMPTY,
        viewType: LIST,
        sortBy: EMPTY,
        productsAvailability: LIST,
      },
      pageInfo: {
        platform: NUCLEUS,
        pageLoadEvent: BROWSE_RESULTS,
        pageName: SELF_SERVICE_PROPANE,
        pageType: PLP
      },
      abtest: SELF_SERVICE,
      onsiteSearchInfo: {
        numberOfResults: '2',
        semanticSearchTokens: EMPTY,
        searchTerm: EMPTY,
        searchIntent: EMPTY,
        resultsType: EMPTY,
        resultsCategory: EMPTY,
      }
    };
    setAnalytics(digitalData);
  });

  window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('self-service.add-to-cart', ({ output = {} }) => {
    const { productId = EMPTY } = output;
    const DDOEvent = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: CART_ADD_LOCATION,
      },
      eventMode: STASH,
      stash: {
        pageType: PLP,
        sharedSection: SELF_SERVICE,
        section: EMPTY,
        component: EMPTY,
        target: ATC,
        item: {
          index: EMPTY,
          productId
        }
      },
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });
}