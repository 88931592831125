const TOP_COOKIE_COUNT = 10;
// Ignoring Akamai cookies since these get stripped at Akamai layer
const ignoreList = [
  '_abck',
  'bm_mi'
];

const getTotalCookies = () => (
  document.cookie
    .split(';')
    .reduce((acc, cookie) => {
      const [name, value] = cookie.trim().split('=');

      if (ignoreList.includes(name) || !name) return acc;

      const size = new Blob([name, value]).size;

      return {
        cookies: [...acc.cookies, { name, size }],
        size: acc.size + size
      };
    }, { cookies: [], size: 0 })
);

const getTopCookies = (cookies) => (
  cookies
    .sort((cookieA, cookieB) => cookieB.size - cookieA.size)
    .slice(0, TOP_COOKIE_COUNT)
);

const cookieStringify = (cookies) => (
  cookies
    .map(({ name, size }) => `${name}:${size}`)
    .join(', ')
);

const reportValues = (values) => {
  Object.keys(values).forEach((name) => {
    if (typeof values[name] !== 'undefined') {
      window.newrelic.setCustomAttribute(name, values[name]);
    }
  });
};

export const cookieSizeTracking = () => {
  const totalCookies = getTotalCookies();
  const topCookies = getTopCookies(totalCookies.cookies);
  const topCookiesStringified = cookieStringify(topCookies);
  const [firstCookie, secondCookie, thirdCookie] = topCookies;

  reportValues({
    totalCookieSize: totalCookies.size,
    topCookies: topCookiesStringified,
    '1stCookie': firstCookie?.name,
    '1stCookieSize': firstCookie?.size,
    '2ndCookie': secondCookie?.name,
    '2ndCookieSize': secondCookie?.size,
    '3rdCookie': thirdCookie?.name,
    '3rdCookieSize': thirdCookie?.size
  });
};
