/* eslint-disable no-unused-expressions */
import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import { getPageType } from '../thd-analytics-utils';

export const clearanceProduct = () => {

  window.LIFE_CYCLE_EVENT_BUS.on('clearanceProduct.tooltipEvent', ({ output = {} }) => {
    let { pageType = '' } = getState() || {};
    pageType = pageType === '' && getPageType();
    const isPIP = (digitalData?.page?.pageInfo?.pageType === 'pip') || (pageType === 'pip');
    const isBrowseOrSearch = pageType === 'browse' || pageType === 'search'
    || digitalData?.page?.pageInfo?.pageType === 'search results' || digitalData?.page?.pageInfo?.pageType === 'plp';
    const isCart = (digitalData?.page?.pageInfo?.pageType === 'checkout') || pageType === 'cart';
    const {
      eventName,
      primaryCategory,
      sku
    } = output;

    const ddoEvent = {
      eventInfo: {
        eventName
      },
      category: {
        primaryCategory
      },
      item: [
        {
          productInfo: {
            sku
          }
        }
      ],
    };
    if (isBrowseOrSearch || isPIP || isCart) {
       digitalData?.event?.push(ddoEvent);
    }
  });

};