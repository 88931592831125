import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';
import { MY_PREFERENCE_CENTER_PAGE, MY_PREFERENCE_CENTER } from '../../../constants';

export function myPreferenceCenter() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('myPreferenceCenter.page-load', () => {
    const pageName = MY_PREFERENCE_CENTER;
    const pageType = MY_PREFERENCE_CENTER_PAGE;
    const primaryCategory = MY_PREFERENCE_CENTER;
    const subCategory1 = MY_PREFERENCE_CENTER;
    const subCategory2 = MY_PREFERENCE_CENTER;

    digitalData.page = {
      pageInfo: {
        pageName,
        pageType,
      },
      category: {
        primaryCategory,
        subCategory1,
        subCategory2,
      },

    };

    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('myPreferenceCenter.static.page-load', ({ output = {} }) => {
    const {
      pageName, pageType, primaryCategory, subCategory1, subCategory2
    } = output;

    digitalData.page = {
      pageInfo: {
        pageName,
        pageType,
      },
      category: {
        primaryCategory,
        subCategory1,
        subCategory2,
      },

    };

    setAnalytics(digitalData);
  });
}