import digitalData from 'digital-data';
import { OVERLAY, BUTTON_SUBMISSION, MODULE_CLICK } from '../constants';

export const flooringPicker = () => {
  const defaultPEOPageType = 'flooring estimate your project overlay';
  const trackEvent = (ddoEvent) => {
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  };

  window.LIFE_CYCLE_EVENT_BUS.on('project-estimate-overlay.overlay', () => {
    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY
      },
      eventInfo: {
        eventName: 'flooring estimate your project'
      }
    };
    trackEvent(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('project-estimate-overlay.click-add-area', () => {
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: MODULE_CLICK
      },
      pageType: defaultPEOPageType,
      content: {
        widgets: [{
          action: 'add area',
          campaign: 'n/a',
          component: 'peo',
          element: 'button click',
          id: 'n/a',
          name: 'n/a',
          templates: 'n/a'
        }]
      }
    };
    trackEvent(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('project-estimate-overlay.save-area',
    ({ output: { areaNameEdited, areaType, cta, dimensionType } }) => {
      let campaign;
      if (areaType === 'stairs') {
        campaign = 'n/a';
      } else {
        campaign = areaNameEdited ? 'edit name yes' : 'edit name no';
      }
      const ddoEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION
        },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: defaultPEOPageType,
        content: {
          widgets: [{
            action: cta, // save and finish this area OR save and add another area
            campaign, // edit name yes OR edit name no OR n/a
            component: 'peo',
            element: 'button click',
            id: 'n/a',
            name: areaType, // stairs or room
            templates: dimensionType, // length x width OR square footage OR number of steps
          }]
        }
      };
      trackEvent(ddoEvent);
    }
  );

  window.LIFE_CYCLE_EVENT_BUS.on('project-estimate-overlay.toggle-project-price',
    ({ output: { isToggledOn, pageType } }) => {
      const ddoEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION
        },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType, // plp OR pip
        content: {
          widgets: [{
            action: 'show estimate price',
            campaign: `toggle ${isToggledOn ? 'on' : 'off'}`,
            component: 'peo',
            element: 'toggle',
            id: 'n/a',
            name: 'n/a',
            templates: 'n/a'
          }]
        }
      };
      trackEvent(ddoEvent);
    }
  );
};