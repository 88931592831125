import digitalData from 'digital-data';
import { INTERACTION } from '../constants';

export const listForms = () => {
  LIFE_CYCLE_EVENT_BUS.on('list-forms.settings-form-load', () => {
    const ddoEvent = {
      category: {
        primaryCategory: 'overlay'
      },
      eventInfo: {
        eventName: 'list settings'
      }
    };
    digitalData.event.push(ddoEvent);
  });

  LIFE_CYCLE_EVENT_BUS.on('list-forms.settings-save', ({ output }) => {
    const settings = `lists: list settings: ${output?.type}`;
    const ddoEvent = {
      category: {
        primaryCategory: 'button submission'
      },
      eventInfo: {
        eventName: 'my account settings'
      },
      myAccount: {
        settings
      }
    };
    digitalData.event.push(ddoEvent);
  });

  LIFE_CYCLE_EVENT_BUS.on('list-forms.list-delete', ({ output }) => {
    const ddoEvent = {
      category: {
        primaryCategory: 'button submission'
      },
      eventInfo: {
        eventName: 'delete list'
      }
    };
    digitalData.event.push(ddoEvent);
  });

  LIFE_CYCLE_EVENT_BUS.on('list-forms.create-without-products', ({ output }) => {
    const ddoEvent = {
      category: {
        primaryCategory: INTERACTION
      },
      eventInfo: {
        eventName: 'create list'
      },
      myList: {
        creationLocation: 'my lists'
      }
    };
    digitalData.event.push(ddoEvent);
  });

  LIFE_CYCLE_EVENT_BUS.on('list-forms.save-a-shared-list', () => {
    const ddoEvent = {
      category: {
        primaryCategory: 'button submission'
      },
      eventInfo: {
        eventName: 'my account settings'
      },
      myAccount: {
        settings: 'lists: shared list: save to lists link'
      }
    };
    digitalData.event.push(ddoEvent);
  });
};