export const Media = {
  image: {
    // eslint-disable-next-line id-length
    read(_, { readField }) {
      const images = readField('images');
      if (!images || images.length === 0) {
        return null;
      }
      const primary = images.find((img) => img.subType === 'PRIMARY');

      let image;
      if (primary) {
        image = primary;
      } else {
        const secondary = images.find((img) => img.subType === 'SECONDARY');
        if (secondary) {
          image = secondary;
        } else {
          image = images[0];
        }
      }

      if (!image) return null;

      const { url, sizes } = image;

      if (!sizes) return null;

      let size;
      const index = (sizes || []).indexOf('1000');
      if (index > -1) {
        size = '1000';
      } else {
        size = sizes[sizes?.length - 1];
      }
      if (!size) {
        return null;
      }
      return {
        ...image,
        url: url.replace(/<SIZE>/ig, size)
      };
    }
  }
};
