import digitalData from 'digital-data';
import {
  OVERLAY,
  RECOMMENDATION_EVENTNAME,
  RECOMMENDATION_VERSION,
  NOT_APPLICABLE,
  STASH
} from '../constants';
import { getState } from '../analytics-state';

export const supplementalRecs = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('supplemental-recs.click', ({ output = {} }) => {
    const {
      position, schemaName
    } = output || {};
    const { pageItemId = '', pageType } = getState() || {};
    const ddoEvent = {
      category: {
        primaryCategory: STASH,
      },
      eventInfo: {
        eventName: RECOMMENDATION_EVENTNAME
      },
      eventMode: STASH,
      stash: {
        recommendation: {
          recommendationType: schemaName,
          pageType,
          displayPosition: position,
          anchorProductSku: pageItemId,
          featureVersion: NOT_APPLICABLE,
          strategy: NOT_APPLICABLE,
          recommendationVersion: RECOMMENDATION_VERSION,
          recommendedProduct: NOT_APPLICABLE,
          interval: NOT_APPLICABLE
        }
      },
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('supplemental-recs.shop-the-look-click', ({ output = {} }) => {
    const {
      eventName
    } = output || {};
    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY,
      },
      eventInfo: {
        eventName,
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};