import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';
import { ACCOUNT, PAINT_PURCHASE_HISTORY } from '../../../constants';

export function paintPurchaseHistoryPage() {
  const pageEvents = {
    load: () => {
      const category = `${ACCOUNT}>${PAINT_PURCHASE_HISTORY}`;

      digitalData.page = {
        pageInfo: {
          pageName: category,
          pageType: `maml:${PAINT_PURCHASE_HISTORY}`,
        },
        category: {
          primaryCategory: ACCOUNT,
          subCategory1: category,
          subCategory2: category,
        },
      };
      setAnalytics(digitalData);
    }
  };

  window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('customer-account-paint-purchase-history.page-load', pageEvents.load);
}
