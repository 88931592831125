import digitalData from 'digital-data';
import { ARTICLE_EXPERIENCE, ARTICLELIST_EXPERIENCE, ARTICLE_LIST_PAGE } from '../../../constants';

import {
  getCategoryBreadCrumbsForALP,
  makeEverythingLowerCase,
  setAnalytics,
} from '../../../thd-analytics-utils';

export function articleListingPage() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('article-listing-page.ready', ({ output = {} }) => {
    const variables = output?.variables || '';
    const categoryId = variables?.categoryId || '';
    const topicId = variables?.topicId || '';
    const infoType = variables?.infoType || '';
    const filterArray = variables?.filterArray || [];
    const breadCrumbs = output?.breadcrumbs || '';
    const filterData = output?.filters || {};

    const brandFilters = filterData.brands?.filter((item) => item.isCurrentPage);
    const brandFilterValue = brandFilters
      ?.map((item) => {
        const title = item.title.charAt(0).toUpperCase() + item.title.slice(1);
        return title.trim();
      })
      .join('|');

    const infoTypeFilters = filterData.informationTypeCounts?.filter((item) => item.isCurrentPage);
    const infoTypeValue = infoTypeFilters?.filter((item) => item.title !== 'Video')[0];

    const homeAreaFilters = filterData.homeAreas?.filter((item) => item.isCurrentPage);
    const homeAreaFilterValue = homeAreaFilters
      ?.map((item) => {
        const title = item.title.charAt(0).toUpperCase() + item.title.slice(1);
        return title.trim();
      })
      .join('|');

    const seasonalFilters = filterData.seasons?.filter((item) => item.isCurrentPage);
    const seasonalFilterValue = seasonalFilters
      ?.map((item) => {
        const title = item.title.charAt(0).toUpperCase() + item.title.slice(1);
        return title.trim();
      })
      .join('|');

    const keywordFilters = filterData.keywords?.filter((item) => item.isCurrentPage);
    const videoKeyword = keywordFilters?.filter((item) => item.title === 'Video')[0];
    const otherKeywords = keywordFilters?.filter((item) => item.title !== 'Video');
    const keywordValue = otherKeywords
      ?.map((item) => {
        const title = item.title.charAt(0).toUpperCase() + item.title.slice(1);
        return title.trim();
      })
      .join('|');

    const digitalDataFilters = [
      {
        type: 'Video',
        value: videoKeyword ? 'Yes' : 'No',
      },
    ];

    if (brandFilters?.length) {
      digitalDataFilters.push({
        type: 'Brand Type',
        value: brandFilterValue,
      });
    }

    if (infoTypeValue) {
      digitalDataFilters.push({
        type: 'Information Type',
        value: infoTypeValue.title,
      });
    }

    if (homeAreaFilters?.length) {
      digitalDataFilters.push({
        type: 'Home Area',
        value: homeAreaFilterValue,
      });
    }

    if (seasonalFilters?.length) {
      digitalDataFilters.push({
        type: 'Seasonal',
        value: seasonalFilterValue,
      });
    }

    if (otherKeywords?.length) {
      digitalDataFilters.push({
        type: 'Keyword',
        value: keywordValue,
      });
    }

    digitalData.page = {
      pageInfo: {
        abTest: '',
        pageName: `${ARTICLE_EXPERIENCE}>${ARTICLELIST_EXPERIENCE}`
          + `${(categoryId.length) ? '>' + categoryId : ''}`
          + `${(topicId.length) ? '>' + topicId : ''}`
          + `${(infoType.length) ? '>' + infoType : ''}`
          + `${(filterArray.length) ? '>' + filterArray.join('>') : ''}`,
        pageType: `${ARTICLE_EXPERIENCE}>${ARTICLELIST_EXPERIENCE}`,
        pageLoadEvent: ARTICLE_LIST_PAGE,
      },
      category: getCategoryBreadCrumbsForALP(breadCrumbs),
      filter: digitalDataFilters,
    };
    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);
    setAnalytics(digitalData);
  });
}