import digitalData from 'digital-data';
import { BUTTON_SUBMISSION } from '../constants';
import { pathToPageSection } from '../thd-analytics-utils';

export const trackOOS = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('track-out-of-stock.click', ({ output = {} }) => {
    const ddoEvent = {
      eventInfo: {
        eventName: 'oos notification'
      },
      category: {
        primaryCategory: BUTTON_SUBMISSION
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);

    const {
      message = '',
      path = []
    } = output;

    if (message !== '') {
      const pageErrors = [];
      const errorDetails = {
        type: 'error',
        displayType: 'inline',
        pageSection: pathToPageSection(path),
        message,
        code: null
      };
      pageErrors.push(errorDetails);
      const advisoryEvent = {
        category: {
          primaryCategory: 'error'
        },
        eventInfo: {
          eventName: 'post page error',
          error: pageErrors
        }
      };
      digitalData.event.push(advisoryEvent);
    }
  });
};