import digitalData from 'digital-data';
import {
  makeEverythingLowerCase,
  setAnalytics
} from '../../../thd-analytics-utils';

const parseCategoryInfo = (breadCrumbLabels) => {
  let primaryCategory;
  let subCategory1;
  let subCategory2;

  if (breadCrumbLabels?.length === 1) {
    primaryCategory = breadCrumbLabels[0];
    subCategory1 = breadCrumbLabels[0];
    subCategory2 = breadCrumbLabels[0];

  } else if (breadCrumbLabels?.length > 1) {
    primaryCategory = breadCrumbLabels[0];
    subCategory1 = breadCrumbLabels.filter((breadCrumbLabel, index) => {
      return index < 2;
    }).join('>');
    subCategory2 = breadCrumbLabels.join('>');
  }

  return {
    primaryCategory,
    subCategory1,
    subCategory2
  };
};

export function specialityContent() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('speciality-content.page-load', ({ output = {} }) => {
    const { breadCrumbLabels, pageType } = output;

    const categoryInfo = parseCategoryInfo(breadCrumbLabels);

    const {
      primaryCategory,
      subCategory1,
      subCategory2
    } = categoryInfo;

    digitalData.page = {
      pageInfo: {
        abTest: 'speciality-content:version:gen2',
        pageLoadEvent: 'content',
        pageName: subCategory2,
        pageTemplate: 'default',
        pageType
      },
      category: {
        primaryCategory,
        subCategory1,
        subCategory2
      }
    };

    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData.page.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData.page.category);

    setAnalytics(digitalData);
  });
}