/* globals newrelic */
import { setState, getState } from '../state';

export const experienceSSR = () => {
  if (typeof window.__SSR_ERROR !== 'undefined' && window.__SSR_ERROR && typeof newrelic !== 'undefined') {

    const { pathname, search: queryString } = window.location;
    newrelic.addPageAction('SSRError', {
      ssrErrorMessage: window.__SSR_ERROR.error,
      ssrErrorStack: window.__SSR_ERROR.stack,
      pathname,
      queryString,
    });
  }
};
