export const parseCategoryInfo = (breadCrumbLabels) => {
  let primaryCategory;
  let subCategory1;
  let subCategory2;

  if (breadCrumbLabels?.length) {
    primaryCategory = breadCrumbLabels[0];
    subCategory1 = breadCrumbLabels.filter((breadCrumbLabel, index) => {
      return index < 2;
    }).join('>');
    subCategory2 = breadCrumbLabels.join('>');
  }

  return {
    primaryCategory,
    subCategory1,
    subCategory2
  };
};