import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import {
  STASH, PIP, NOT_APPLICABLE, ATC, PRODUCT_POD
} from '../constants';

export const recsAnalytics = () => {

  window.LIFE_CYCLE_EVENT_BUS.on('recs.add-to-cart', ({ output = {} }) => {
    const {
      items = [],
      scheme
    } = output;

    const { pageType = '', pageItemId = '' } = getState() || {};

    const refinedItems = items.map((item, itemIndex) => {
      const { itemId, productId = '', strategy } = item;
      const sku = itemId ? itemId.toString() : productId.toString();
      if (items?.length > 1 && itemId === pageItemId) {
        return {
          productInfo: {
            sku
          }
        };
      }
      return {
        productInfo: {
          sku
        },
        displayPosition: itemIndex + 1,
        recommendation: {
          recommendationType: scheme,
          pageType: PIP,
          anchorProductSku: pageItemId,
          strategy,
          recommendationVersion: NOT_APPLICABLE,
          featureVersion: NOT_APPLICABLE,
          interval: NOT_APPLICABLE,
        }
      };
    });

    const ddoEvent = {
      eventInfo: {
        eventName: 'recommendation cart addition'
      },
      category: {
        primaryCategory: STASH
      },
      eventMode: STASH,
      stash: {
        component: PRODUCT_POD,
        item: refinedItems,
        pageType,
        section: 'dyn prod rec',
        sharedSection: NOT_APPLICABLE,
        target: ATC
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};