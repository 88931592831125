import digitalData from 'digital-data';
import { BIA_MY_ACCOUNT, BUTTON_SUBMISSION, INTERACTION } from '../constants';
import { getState } from '../analytics-state';

export const productList = () => {
  LIFE_CYCLE_EVENT_BUS.on('product-list.remove-product', ({ output }) => {
    const { products } = output;
    const item = products.map((product) => ({
      quantity: product.quantity,
      price: {
        basePrice: product.price,
      },
      productInfo: {
        sku: product.itemId
      }
    }));
    const ddoEvent = {
      category: {
        primaryCategory: 'list view modification'
      },
      eventInfo: {
        eventName: 'list remove'
      },
      item
    };
    digitalData.event.push(ddoEvent);
  });

  LIFE_CYCLE_EVENT_BUS.on('product-list.increment-quantity', ({ output }) => {
    const { products } = output;
    const item = products.map((product) => ({
      quantity: product.quantity,
      price: {
        basePrice: product.price,
      },
      productInfo: {
        sku: product.itemId
      }
    }));
    const ddoEvent = {
      category: {
        primaryCategory: 'list view modification'
      },
      eventInfo: {
        eventName: 'list add'
      },
      item
    };
    digitalData.event.push(ddoEvent);
  });

  LIFE_CYCLE_EVENT_BUS.on('product-list.atc', ({ output }) => {
    const { pageType = null } = getState() || {};
    const { products } = output;
    const items = products.map((product) => ({
      productID: product.itemId,
      index: product?.index
    }));
    const ddoEvent = {
      category: {
        primaryCategory: 'stash'
      },
      eventInfo: {
        eventName: 'cart add location'
      },
      eventMode: 'stash',
      stash: {
        pageType,
        sharedSection: 'n/a',
        section: 'n/a',
        component: 'n/a',
        target: 'atc',
        items
      }
    };
    digitalData.event.push(ddoEvent);
  });

  LIFE_CYCLE_EVENT_BUS.on('product-list.email-list', () => {
    const ddoEvent = {
      category: {
        primaryCategory: INTERACTION
      },
      eventInfo: {
        eventName: 'email list'
      }
    };
    digitalData.event.push(ddoEvent);
  });

  LIFE_CYCLE_EVENT_BUS.on('product-list.print-list', () => {
    const ddoEvent = {
      category: {
        primaryCategory: INTERACTION
      },
      eventInfo: {
        eventName: 'print list'
      }
    };
    digitalData.event.push(ddoEvent);
  });

  LIFE_CYCLE_EVENT_BUS.on('product-list.pagination', ({ output }) => {
    const { page } = output;
    const settings = `lists: list details: pagination: ${page}`;
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: BIA_MY_ACCOUNT
      },
      myAccount: { settings }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  LIFE_CYCLE_EVENT_BUS.on('product-list.post-load-error', ({ output }) => {
    const {
      type = 'error',
      displayType = 'page',
      pageSection = '',
      message = '',
      code = ''
    } = output || {};
    const ddoEvent = {
      category: {
        primaryCategory: 'error'
      },
      eventInfo: {
        eventName: 'post page error',
        error: [{
          type,
          displayType,
          pageSection,
          message,
          code
        }]
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  LIFE_CYCLE_EVENT_BUS.on('product-list.rearranging-product', () => {
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: 'my account settings'
      },
      myAccount: {
        settings: 'lists: list details: rearrange product'
      }
    };
    digitalData.event.push(ddoEvent);
  });
};