/* globals newrelic */

export const operationsServer = () => {
  if (typeof window.__APOLLO_OPERATIONS !== 'undefined'
    && window.__APOLLO_OPERATIONS
    && typeof newrelic !== 'undefined') {

    const { pathname } = window.location;
    newrelic.addPageAction('operationsServer', {
      operationsServerCount: window.__APOLLO_OPERATIONS.count,
      pathname
    });
  }
};
