import digitalData from 'digital-data';
import { getState, setState } from '../analytics-state';
import { formatCarPickerString } from '../thd-analytics-utils';

export const carPicker = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('car-picker.dropdown-item-click', ({ output }) => {
    const {
      year, yearIndex, make, makeIndex, model, modelIndex
    } = output || {};

    const { currentPage, pageType, sortBy } = getState();

    let {
      data,
      formattedIndexes,
      formattedYearMakeModel,
      formattedYearMakeModelAlt
    } = getState()?.automotive || {};

    // If year is selected, we need to clear make and model
    // If make is selected, we need to clear model
    if (year) {
      data = {
        ...data,
        make: '',
        makeIndex: 'default',
        model: '',
        modelIndex: 'default'
      };
    }

    if (make) {
      data = {
        ...data,
        model: '',
        modelIndex: 'default'
      };
    }

    data = {
      ...data,
      ...(year && { year: year.value, yearIndex }),
      ...(make && { make: make.value, makeIndex }),
      ...(model && { model: model.value, modelIndex }),
    };

    formattedYearMakeModel = formatCarPickerString([data?.year, data?.make, data?.model], ' ');
    formattedYearMakeModelAlt = formatCarPickerString([data?.year, data?.make, data?.model], '>');
    formattedIndexes = formatCarPickerString([data?.yearIndex, data?.makeIndex, data.modelIndex], ':');

    setState({
      automotive:
      {
        data,
        formattedIndexes,
        formattedYearMakeModel,
        formattedYearMakeModelAlt
      }
    });

    const ddoEvent = {
      eventInfo: {
        eventName: 'filter by'
      },
      category: {
        primaryCategory: pageType,
      },
      filter: [{
        selectionMethod: 'default',
        type: 'vehicle',
        value: formattedYearMakeModel,
        position: formattedIndexes
      }],
      displayAttributes: {
        viewType: 'grid',
        pageNumber: currentPage,
        productsAvailability: 'all products',
        sortBy
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('car-picker.select-vehicle-click', () => {
    const { formattedYearMakeModelAlt } = getState()?.automotive || {};

    const ddoEvent = {
      category: {
        primaryCategory: 'stash'
      },
      eventInfo: {
        eventName: 'widget interaction'
      },
      eventMode: 'stash',
      content: {
        widget: {
          type: 'auto',
          value: formattedYearMakeModelAlt
        }
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('car-picker.shop-universal-parts', () => {
    const { currentPage, pageType, sortBy, automotive } = getState() || {};
    const { formattedIndexes } = automotive || {};

    const ddoEvent = {
      eventInfo: {
        eventName: 'filter by'
      },
      category: {
        primaryCategory: pageType,
      },
      filter: [{
        selectionMethod: 'default',
        type: 'automotive fit type',
        value: 'universal',
        position: formattedIndexes
      }],
      displayAttributes: {
        viewType: 'grid',
        pageNumber: currentPage,
        productsAvailability: 'all products',
        sortBy
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('car-picker.dont-see-vehicle', () => {
    const { formattedYearMakeModelAlt } = getState()?.automotive || {};

    const ddoEvent = {
      category: {
        primaryCategory: 'stash'
      },
      eventInfo: {
        eventName: 'widget interaction'
      },
      eventMode: 'stash',
      content: {
        widget: {
          type: 'auto',
          value: formattedYearMakeModelAlt || 'no selection'
        }
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('car-picker.page-load', ({ output }) => {
    if (Object.keys(output).length > 0) {
      const {
        year, yearIndex, make, makeIndex, model, modelIndex
      } = output;

      const data = {
        year, yearIndex, make, makeIndex, model, modelIndex
      };
      const formattedYearMakeModel = formatCarPickerString([year, make, model], ' ');
      const formattedYearMakeModelAlt = formatCarPickerString([year, make, model], '>');
      const formattedIndexes = formatCarPickerString([yearIndex, makeIndex, modelIndex], ':');

      setState({
        automotive: {
          data,
          formattedYearMakeModel,
          formattedYearMakeModelAlt,
          formattedIndexes
        }
      });
    }

    const ddoEvent = {
      content: {
        widget: {
          type: 'auto',
          value: getState()?.automotive?.formattedYearMakeModelAlt || 'no selection',
        }
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};