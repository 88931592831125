import digitalData from 'digital-data';
import { isDuplicatePageError } from '../thd-analytics-utils';

export const alert = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('alert.ready', ({ output }) => {
    const {
      inline,
      message = '',
      path = [],
      title
    } = output;

    const whereDidEventOriginate = (domPath) => {
      const reversedPath = domPath?.slice()?.reverse();
      if (reversedPath.find((name) => name.match(/productpod\[/i))) {
        return 'product pod';
      }
      if (reversedPath.find((name) => name.match(/supersku\[/i))) {
        return 'super sku';
      }
      if (reversedPath.find((name) => name.match(/buybox\[/i))) {
        return 'buybox';
      }
      if (reversedPath.find((name) => name.match(/addtocart\[/i))) {
        return 'add to cart';
      }
      if (reversedPath.find((name) => name.match(/promotionproducts/i))) {
        return 'promotion products';
      }
      if (reversedPath.find((name) => name.match(/frequentlyboughttogether\[/i))) {
        return 'fbt';
      }
      return 'n/a';
    };

    const fullMessage = title ? `${title} ${message}` : message;
    const displayType = inline ? 'inline' : 'page';
    const pageError = {
      type: 'error',
      displayType,
      pageSection: whereDidEventOriginate(path),
      message: fullMessage,
      code: null
    };
    digitalData.page = digitalData.page || {};
    digitalData.page.error = digitalData.page.error || [];
    if (!isDuplicatePageError(digitalData, pageError)) {
      // eslint-disable-next-line no-unused-expressions
      digitalData.page.error.push(pageError);
    }
  });
};
