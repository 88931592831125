/* eslint react/no-find-dom-node: 0 */
import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import { copy as copyUtil } from '@thd-olt-functional/utils';
import { fsToRules } from './util';
import { LayoutProviderContext } from './LayoutProviderContext';

/**
 * Updates the manager object if it finds a matching id. If not it adds to the list.
 * Also loops through `obj.groups` and updates groups in the same manner.
 * @param list
 * @param obj
 * @returns {Array}
 */
export const upsertManager = (list, obj) => {
  const copy = list.slice();
  const index = list.findIndex((item) => item.id === obj.id);
  if (index === -1) {
    copy.push(obj);
    return copy;
  }

  const item = copy[index];
  const groupCopy = (obj.groups || []).slice();
  item.groups = (item.groups || []).slice();
  // update current items
  item.groups = item.groups.map((group) => {
    const grpIndex = groupCopy.findIndex((objGroup) => objGroup.id === group.id);
    if (grpIndex !== -1) {
      const [ret] = groupCopy.splice(grpIndex, 1);
      return ret;
    }
    return group;
  });
  item.groups = [
    ...item.groups,
    ...groupCopy
  ];
  return copy;
};

export class LayoutManagerProvider extends Component {

  static extend = (...args) => {
    let [a, b, c] = args;
    const result = {
      expressions: [],
      managers: [],
      ...a
    };
    const res = copyUtil(b);
    b = res.data;
    (b.managers || []).forEach((bMgr) => {
      result.managers = upsertManager(result.managers, bMgr);
    });
    (b.expressions || []).forEach((bExp) => {
      const index = (result.expressions || []).findIndex((exp) => exp.id === bExp.id);
      if (index === -1) {
        result.expressions.push({
          ...bExp
        });
      } else {
        result.expressions[index] = {
          ...bExp
        };
      }
    });

    if (c) {
      args.shift();
      args.shift();
      return LayoutManagerProvider.extend(result, ...args);
    }

    return result;
  };

  constructor(props = {}) {
    super(props);
    const { context = {}, featureSwitch } = props;

    const rules = this.getRulesFromSwitch(featureSwitch);
    this.state = {
      rules
    };
  }

  componentDidMount() {
    const { context: rules = {} } = this.props || {};
    if (typeof CustomEvent !== 'undefined' && typeof document !== 'undefined') {

      document.addEventListener('LAYOUT_MANAGER_DEVTOOLS_CHANGE', (event) => {
        const { action = {}, state = {} } = event.detail || {};
        if (/@@LAYOUT_MANAGER/.test(action.type)) {
          this.setState({ rules: state });
        }
      });

      if (window.LIFE_CYCLE_EVENT_BUS) {
        window.LIFE_CYCLE_EVENT_BUS.on('LAYOUT_MANAGER.ADD_FS', ({ output }) => {
          const updatedRules = this.getRulesFromSwitch(output);
          this.setState({ rules: updatedRules });
        });
      }

      const evt = new CustomEvent('LAYOUT_MANAGER_PROVIDER_READY', {
        detail: {
          expressions: ((rules || {}).expressions || []).map((exp) => {
            return {
              ...exp,
              fn: null
            };
          }),
          managers: (rules || {}).managers || []
        }
      });
      document.dispatchEvent(evt);
    }
  }

  getRulesFromSwitch(featureSwitch) {
    const { context = {} } = this.props;

    if (featureSwitch) {
      const res = fsToRules(featureSwitch);
      if (res.length) {
        const FS_EXPRESSION_ID = '__fs-always-true__';
        context.expressions = [
          ...context.expressions,
          {
            id: FS_EXPRESSION_ID,
            name: 'fs:always true',
            value: 'true',
            fn: null
          }
        ];
        res.forEach((result) => {
          // go through all current managers and rules and inject
          context.managers = (context.managers || []).map((manager) => {
            if (manager.name === result.managerName) {
              return {
                ...manager,
                groups: (manager.groups || []).map((group) => {
                  if (group.name === result.groupName) {
                    if (result.type === 'disable') {
                      if (!result.value) {
                        return {
                          ...group,
                          filter: (group.filter || []).filter((grp) => {
                            return grp.id !== `fs-disable:${result.managerName}-${result.groupName}`;
                          })
                        };
                      }
                      return {
                        ...group,
                        filter: [...(group.filter || []), {
                          id: `fs-disable:${result.managerName}-${result.groupName}`,
                          expressionId: FS_EXPRESSION_ID,
                          timeStamp: new Date().getTime()
                        }]
                      };
                    }
                    if (result.type === 'prop') {
                      return {
                        ...group,
                        prop: [...group.prop, {
                          id: `fs-prop:${result.managerName}-${result.groupName}-${result.propName}-${result.value}`,
                          expressionId: FS_EXPRESSION_ID,
                          name: result.propName,
                          value: result.value,
                          timeStamp: new Date().getTime()
                        }]
                      };
                    }
                    return group;
                  }
                  return group;
                })
              };
            }
            return manager;
          });
        });
      }
    }

    return context;

  }

  render() {
    const { children, onError } = this.props;
    const { rules = {} } = this.state;
    const context = {
      rules,
      onError
    };

    return (
      <LayoutProviderContext.Provider value={context}>
        {Children.only(children)}
      </LayoutProviderContext.Provider>
    );
  }
}

LayoutManagerProvider.displayName = 'LayoutManagerProvider';

LayoutManagerProvider.propTypes = {
  children: PropTypes.node,
  onError: PropTypes.func,
  featureSwitch: PropTypes.shape({}),
  context: PropTypes.shape({
    managers: PropTypes.arrayOf(PropTypes.shape),
    expressions: PropTypes.arrayOf(PropTypes.shape)
  }),
};

LayoutManagerProvider.defaultProps = {
  children: null,
  onError: null,
  featureSwitch: null,
  context: null,
};
