import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';

export function creditCenter() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('credit-center.page-load', () => {
    digitalData.page = {
      pageInfo: {
        pageName: 'credit center',
        pageType: 'content',
        pageTemplate: 'default',
        pageLoadEvent: 'content',
        abTest: 'content:version:gen2',
      },
      category: {
        primaryCategory: 'credit center',
        subCategory1: 'credit center',
        subCategory2: 'credit center',
      },
    };

    setAnalytics(digitalData);
  });
}