/* global newrelic */

export const invalidDataScenarioListener = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('invalid-data-scenario.found', ({ output }) => {
    const { componentName, erroredSchema, errorDescription } = output;
    newrelic.addPageAction('InvalidDataScenarioIssue', {
      componentName,
      erroredSchema,
      errorDescription
    });
  });
};