import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import { getPageType } from '../thd-analytics-utils';

export const configuratorProjects = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('configuratorProjects.startNewProject', () => {
    let { pageType = '' } = getState() || {};
    pageType = pageType === '' ? getPageType() : pageType;

    const ddoEvent = {
      category: {
        primaryCategory: 'button submission',
      },
      eventInfo: {
        eventName: 'content module click'
      },
      pageType,
      content: {
        widgets: [{
          action: 'button click',
          id: 'configurators creation project',
          name: 'configurators creation project',
          templates: 'n/a',
          campaign: 'start new flooring project',
          component: 'n/a',
          element: 'button'
        }]
      }
    };

    if (!digitalData.event) {
      digitalData.event = [];
    }
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('configuratorProjects.viewEstimate', () => {
    let { pageType = '' } = getState() || {};
    pageType = pageType === '' ? getPageType() : pageType;
    const ddoEvent = {
      category: {
        primaryCategory: 'stash',
      },
      eventInfo: {
        eventName: 'content module click'
      },
      eventMode: 'stash',
      stash: {
        pageType,
        content: {
          widgets: [{
            action: 'button click',
            id: 'configurators creation project',
            name: 'configurators creation project',
            templates: 'n/a',
            campaign: 'view estimate',
            component: 'n/a',
            element: 'button'
          }]
        }
      }
    };

    if (!digitalData.event) {
      digitalData.event = [];
    }
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('configuratorProjects.editProject', () => {
    let { pageType = '' } = getState() || {};
    pageType = pageType === '' ? getPageType() : pageType;

    const ddoEvent = {
      category: {
        primaryCategory: 'stash',
      },
      eventInfo: {
        eventName: 'content module click'
      },
      eventMode: 'stash',
      stash: {
        pageType,
        content: {
          widgets: [{
            action: 'button click',
            id: 'configurators creation project',
            name: 'configurators creation project',
            templates: 'n/a',
            campaign: 'edit project',
            component: 'n/a',
            element: 'button'
          }]
        }
      }
    };

    if (!digitalData.event) {
      digitalData.event = [];
    }
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('configuratorProjects.whyHomeDepotInstallersAccordionClick', () => {
    let { pageType = '' } = getState() || {};
    pageType = pageType === '' ? getPageType() : pageType;

    const ddoEvent = {
      category: {
        primaryCategory: 'button submission',
      },
      eventInfo: {
        eventName: 'content module click'
      },
      pageType,
      content: {
        widgets: [{
          action: 'accordion expand',
          id: 'configurators creation project',
          name: 'configurators creation project',
          templates: 'n/a',
          campaign: 'why home depot installers',
          component: 'n/a',
          element: 'accordion'
        }]
      }
    };

    if (!digitalData.event) {
      digitalData.event = [];
    }
    digitalData.event.push(ddoEvent);
  });
};