import digitalData from 'digital-data';
import { RECOMMENDATION_VERSION, STASH, NOT_APPLICABLE } from '../constants';

export const cardMessaging = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('card-messaging.ready', ({ output }) => {
    const { feature = '', module = 'buy box' } = output;
    const featureStr = String(feature).toLowerCase();
    const contentModule = {
      name: module,
      feature: featureStr,
      type: 'cta'
    };
    digitalData.content = digitalData.content || {};
    digitalData.content.module = digitalData.content.module || [];
    digitalData.content.module.push(contentModule);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('card-messaging.click', ({ output }) => {
    const { feature = '', module = 'buy box' } = output;
    const featureStr = String(feature).toLowerCase();
    const contentModule = {
      name: module,
      feature: featureStr,
      type: 'cta'
    };
    const analyticsEvent = {
      category: {
        primaryCategory: 'button submission'
      },
      eventInfo: {
        eventName: 'content module interaction'
      },
      content: {
        module: [contentModule]
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(analyticsEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('card-messaging.impressionEvent', ({ output }) => {
    const { feature = '', module = 'card' } = output;
    const featureStr = String(feature).toLowerCase();
    const contentModule = {
      name: module,
      feature: featureStr,
      type: 'cta'
    };
    const analyticsEvent = {
      category: {
        primaryCategory: 'stash'
      },
      eventInfo: {
        eventName: 'content module impression'
      },
      eventMode: 'stash',
      stash: {
        content: {
          module: [contentModule]
        }
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(analyticsEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('card-messaging.modalImpressionEvent', ({ output }) => {
    const { feature = '' } = output;
    const featureStr = String(feature).toLowerCase();
    const analyticsEvent = {
      category: {
        primaryCategory: 'overlay'
      },
      eventInfo: {
        eventName: featureStr
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(analyticsEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('card-messaging.modalClickEvent', ({ output }) => {
    const { feature = '' } = output;
    const featureStr = String(feature).toLowerCase();
    const analyticsEvent = {
      category: {
        primaryCategory: 'button submission'
      },
      eventInfo: {
        eventName: 'my account settings'
      },
      myAccount: {
        settings: featureStr
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(analyticsEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('card-messaging.productClick', () => {
    const { pageType } = digitalData?.page?.pageInfo;
    const analyticsEvent = {
      category: {
        primaryCategory: STASH,
      },
      eventInfo: {
        eventName: 'recommendation pip load',
      },
      eventMode: STASH,
      stash: {
        recommendation: {
          recommendationType: 'plcc_recommendations',
          pageType: pageType || 'homepage',
          displayPosition: NOT_APPLICABLE,
          anchorProductSku: NOT_APPLICABLE,
          recommendedProduct: NOT_APPLICABLE,
          strategy: NOT_APPLICABLE,
          recommendationVersion: RECOMMENDATION_VERSION,
          featureVersion: NOT_APPLICABLE,
          interval: NOT_APPLICABLE,
        },
      },
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(analyticsEvent);
  });
};