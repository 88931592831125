import digitalData from 'digital-data';
import {
  BUTTON_SUBMISSION,
  CONTENT,
  MODULE_CLICK,
  NOT_APPLICABLE,
  PATH_TO_PRO,
  PATH_TO_PRO_NETWORK,
  FILTER,
  SORT,
  INTERACTION,
  PATH_TO_PRO_CANDIDATE_PAGE,
  PATH_TO_PRO_JOB_POSTINGS,
  PATH_TO_PRO_MY_DASHBOARD,
  PATH_TO_PRO_PROFILE_SEARCH,
  SUBCONTENT_PTP,
  PTP_COMPONENT,
  OVERLAY,
  PTP_DASHBOARD_WIDGET_ID,
  PATH_TO_PRO_JOB_APPLICANTS,
  PTP_JOB_APPLICANTS_WIDGET_ID,
  PTP_JOB_POSTING_ACTIONS_WIDGET_ID,
  PTP_PROFILE_SEARCH_WIDGET_ID,
  PTP_PROFILE_WIDGET_ID,
  STASH,
  PTP_LANDING_PAGE_WIDGET_ID,
  CLICK,
  PTP_PROFILE_OVERLAY_WIDGET_ID,
  PTP_HIRING_WIDGET_ID,
  ACTION
} from '../../../constants';
import { setAnalytics } from '../../../thd-analytics-utils';

export function pathToProNetwork() {

  /// /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /// /////////////////////////////// B2B DWMW MAML Tech Spec 5.0.1 - full ////////////////////////////////////
  /// /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /** 7.39.2 Welcome to the Path to Pro Network Overlay */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('pathToProNetwork.welcome.overlay', () => {
    digitalData.event = digitalData?.event || [];
    digitalData.event.push({
      category: { primaryCategory: OVERLAY },
      eventInfo: { eventName: 'welcome to the path to pro network' }
    });

    setAnalytics(digitalData);
  });

  /** 7.39.3 Path to Pro My Dashboard */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('pathToProNetwork.my-dashboard.page-load', () => {
    digitalData.page = {
      pageInfo: {
        pageName: `${PATH_TO_PRO}>dashboard`,
        pageType: CONTENT,
      },
      category: {
        primaryCategory: PATH_TO_PRO,
        subCategory1: `${PATH_TO_PRO}>dashboard`,
        subCategory2: `${PATH_TO_PRO}>dashboard`
      }
    };

    setAnalytics(digitalData);
  });

  /** 7.39.5 My Dashboard Accordion Clicks
   *
   * @templates = oneOf["recently contacted", "favorites", "my team", "job postings"]
   * */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('pathToProNetwork.my-dashboard.click-accordion', ({ output: { templates } }) => {
    digitalData.event = digitalData?.event || [];
    digitalData.event.push({
      category: { primaryCategory: BUTTON_SUBMISSION },
      eventInfo: { eventName: MODULE_CLICK },
      pageType: CONTENT,
      content: {
        widgets: [
          {
            action: 'dashboard accordion expand',
            id: PTP_DASHBOARD_WIDGET_ID,
            name: NOT_APPLICABLE,
            templates,
            campaign: SUBCONTENT_PTP,
            component: PTP_COMPONENT
          }
        ]
      }
    });

    setAnalytics(digitalData);
  });

  /** 7.39.6 Recently Contacted Accordion Actions
   *
   * @templates = oneOf["action>view profile", "action>email", "action>call", "action>remove"]
   * */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.my-dashboard.recently-contacted.click-action',
    ({ output: { templates } }
    ) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: ACTION,
              id: PTP_DASHBOARD_WIDGET_ID,
              name: 'find candidate',
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT
            }
          ]
        }
      });

      setAnalytics(digitalData);
    });

  /** 7.39.7 Favorites Accordion Actions
   *
   * @templates = oneOf["action>view profile", "action>email", "action>call", "action>remove"]
   * */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.my-dashboard.favorites.click-action',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: ACTION,
              id: PTP_DASHBOARD_WIDGET_ID,
              name: PATH_TO_PRO_MY_DASHBOARD,
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT
            }
          ]
        }
      });

      setAnalytics(digitalData);
    });

  /** 7.39.8 My Team Accordion Actions
   *
   * @templates = oneOf["actions: view profile", "actions: email", "actions: call", "actions: remove"]
   * */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.my-dashboard.my-team.click-action',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: 'dashboard my team',
              id: PTP_DASHBOARD_WIDGET_ID,
              name: NOT_APPLICABLE,
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT
            }
          ]
        }
      });

      setAnalytics(digitalData);
    });

  /** 7.39.10 Job Applicants page
   *
   */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('pathToProNetwork.job-applicants.page-load', () => {
    digitalData.page = {
      pageInfo: {
        pageName: `${PATH_TO_PRO}>${PATH_TO_PRO_JOB_APPLICANTS}`,
        pageType: PATH_TO_PRO
      },
      category: {
        primaryCategory: PATH_TO_PRO,
        subCategory1: `${PATH_TO_PRO}>${PATH_TO_PRO_JOB_APPLICANTS}`,
        subCategory2: `${PATH_TO_PRO}>${PATH_TO_PRO_JOB_APPLICANTS}`
      }
    };

    setAnalytics(digitalData);
  });

  /** 7.39.12 Favorites: Find Candidates Here Link */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.my-dashboard.favorites.click-find-candidates', () => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: 'dashboard favorites',
              id: PTP_DASHBOARD_WIDGET_ID,
              name: NOT_APPLICABLE,
              templates: 'find candidates here',
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT
            }
          ]
        }
      });

      setAnalytics(digitalData);
    });

  /** 1.6.1.1 Create New Job Posting Overlay */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.my-dashboard.job-postings.create-new.overlay', () => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: {
          primaryCategory: OVERLAY
        },
        eventInfo: {
          eventName: 'create new job posting'
        }
      });

      setAnalytics(digitalData);
    });

  /** 7.39.15 Create New Job Posting Overlay Actions
   *
   * @templates = oneOf["publish", "save as draft", "preview job posting", "cancel", "close"]
   * */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.my-dashboard.job-postings.create-new.click-actions',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: 'create new job posting',
              id: PTP_JOB_POSTING_ACTIONS_WIDGET_ID,
              name: NOT_APPLICABLE,
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT
            }
          ]
        }
      });

      setAnalytics(digitalData);
    });

  /** 7.39.17 Errors
   *
   * page load errors - not implementing for now
   * post-page error tracking -- will just group all into this category ("inline")
   *
   * - “Something went wrong” errors
   * - “Not Authorized” errors
   * - “Oops there are no candidates” advisories
   *
   * @pageSection = window.location.pathname at time of error
   */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.error.post-page',
    ({ output: { message, code } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: {
          primaryCategory: 'error'
        },
        eventInfo: {
          eventName: 'post page error',
          error: [
            {
              displayType: 'inline',
              pageSection: PATH_TO_PRO,
              message,
              code
            }
          ]
        }
      });

      setAnalytics(digitalData);
    });

  /** 7.39.18 Profile Interactions
   *
   * @templates = oneOf[
   * "candidate card: badge tooltip",
   * "profile pop up: badge tooltip",
   * "candidate card: show more",
   * "profile pop up: view my resume"
   * ]
   */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.profile-search.profile-interaction',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: PTP_PROFILE_SEARCH_WIDGET_ID,
              id: PTP_PROFILE_WIDGET_ID,
              name: NOT_APPLICABLE,
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT
            }
          ]
        }
      });

      setAnalytics(digitalData);
    });

  /** 7.39.19 Profile Image Favorite/Unfavorite Interactions
   *
   * @favoritedBool favorite status change, true = favorited, false = unfavorited
   */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.profile-image.favorite-interaction',
    ({ output: { favoritedBool } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: { eventName: MODULE_CLICK },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: PATH_TO_PRO_PROFILE_SEARCH,
              id: PTP_PROFILE_WIDGET_ID,
              name: NOT_APPLICABLE,
              templates: favoritedBool ? 'profile image: favorite' : 'profile image: unfavorite',
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT
            }
          ]
        },
      });

      setAnalytics(digitalData);
    }
  );

  /// /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /// /////////////////////////////// Path to Pro (SLM) Tech Spec 2.0 /////////////////////////////////////////
  /// /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /** 1.7 Call or Email from Profile Overlay
   *
   * @candidateId
   * @templates = oneOf["call me", "email me"]
   */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.profile-details.contact-candidate',
    ({ output: { candidateId, templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: { eventName: MODULE_CLICK },
        pageType: CONTENT,
        content: {
          slmCandidateID: candidateId,
          widgets: [
            {
              action: 'contact',
              id: PTP_PROFILE_OVERLAY_WIDGET_ID,
              name: NOT_APPLICABLE,
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT
            }
          ]
        }
      });

      setAnalytics(digitalData);
    });

  /** 1.9 Hire this Candidate
   *
   * @hiredBool: hired/not hired
   * @location: oneOf["carousel", "profile", "search", "recently contacted", "favorites"]
   * @candidateId
   */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.hiring.toggle-click',
    ({ output: { hiredBool, location, candidateId } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: { eventName: MODULE_CLICK },
        pageType: CONTENT,
        content: {
          slmCandidateID: candidateId,
          widgets: [
            {
              action: CLICK,
              id: PTP_HIRING_WIDGET_ID,
              name: NOT_APPLICABLE,
              templates: hiredBool ? `hired>${location}` : `not hired>${location}`,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT
            }
          ]
        }
      });

      setAnalytics(digitalData);
    }
  );

  /// /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /// /////////////////////////////// Path to Pro (SLM) Tech Spec 3.0 /////////////////////////////////////////
  /// /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /** 1.1 Path to Pro Page View (Available) */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('pathToProNetwork.landing.page-load', () => {
    digitalData.page = {
      pageInfo: {
        pageName: PATH_TO_PRO_NETWORK,
        pageType: CONTENT,
      },
      category: {
        primaryCategory: PATH_TO_PRO_NETWORK,
        subCategory1: PATH_TO_PRO_NETWORK,
        subCategory2: PATH_TO_PRO_NETWORK,
      }
    };

    setAnalytics(digitalData);
  });

  /** 1.1 Path to Pro Page View (Unavailable) */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('pathToProNetwork.landing.page-load>unavailable', () => {
    digitalData.page = {
      pageInfo: {
        pageName: `${PATH_TO_PRO_NETWORK}>unavailable`,
        pageType: CONTENT,
      },
      category: {
        primaryCategory: PATH_TO_PRO_NETWORK,
        subCategory1: `${PATH_TO_PRO_NETWORK}>unavailable`,
        subCategory2: `${PATH_TO_PRO_NETWORK}>unavailable`,
      }
    };

    setAnalytics(digitalData);
  });

  /** 1.2 Your Location Candidate Search */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.shareLocation.findCandidateClick',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: { eventName: MODULE_CLICK },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: CLICK,
              id: 'your location search',
              name: 'path to pro landing page',
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT
            }
          ]
        }
      });

      setAnalytics(digitalData);
    }
  );

  /** 1.3 Find Candidates Page */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('pathToProNetwork.candidate-search.page-load', () => {
    digitalData.page = {
      pageInfo: {
        pageName: `${PATH_TO_PRO_NETWORK}>candidate search`,
        pageType: CONTENT,
      },
      category: {
        primaryCategory: PATH_TO_PRO_NETWORK,
        subCategory1: `${PATH_TO_PRO_NETWORK}>candidate search`,
        subCategory2: `${PATH_TO_PRO_NETWORK}>candidate search`,
      }
    };

    setAnalytics(digitalData);
  });

  /** 1.3.1 Recently Contacted Tab */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.candidateSearch.recentlyContactedAccordionClick',
    () => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: { eventName: MODULE_CLICK },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: 'tab',
              id: 'recently contacted',
              name: 'find candidates',
              templates: 'recently contacted tab',
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT
            }
          ]
        }
      });

      setAnalytics(digitalData);
    }
  );

  /** 1.3.1.1 Recently Contacted Location Filter */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.profile-search.filter-interaction',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: FILTER,
              id: PTP_PROFILE_SEARCH_WIDGET_ID,
              name: 'find candidates',
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT,
            },
          ],
        }
      });

      setAnalytics(digitalData);
    });

  /** 1.3.1.2 Recently Contacted Sort */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.dashboard.recentlyContacted.sortChange',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: SORT,
              id: 'recently contacted',
              name: 'find candidates',
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT,
            },
          ],
        }
      });

      setAnalytics(digitalData);
    });

  /** 1.4.1 Favorites Tab */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.my-dashboard.favoriteTabClick',
    () => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: 'tab',
              id: 'favorites',
              name: PATH_TO_PRO_MY_DASHBOARD,
              templates: 'favorites tab',
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT,
            },
          ],
        }
      });

      setAnalytics(digitalData);
    });

  /** 1.4.1.1 Favorites Sort */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.dashboard.favorites.sortChange',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: SORT,
              id: 'favorites',
              name: PATH_TO_PRO_MY_DASHBOARD,
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT,
            },
          ],
        }
      });

      setAnalytics(digitalData);
    }
  );

  /** 1.5 Candidate Page */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('pathToProNetwork.candidate-search.candidate-page',
    ({ output: { templates } }) => {
      digitalData.page = {
        pageInfo: {
          pageName: `${PATH_TO_PRO_NETWORK}>${templates}>profile details`,
          pageType: CONTENT,
        },
        category: {
          primaryCategory: PATH_TO_PRO_NETWORK,
          subCategory1: `${PATH_TO_PRO_NETWORK}>${templates}`,
          subCategory2: `${PATH_TO_PRO_NETWORK}>${templates}>profile details`,
        }
      };

      setAnalytics(digitalData);
    });

  /** 1.5.1 Candidate Page Interactions */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.candidateDetails.interaction',
    ({ output: { name, templates, slmCandidateID } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          slmCandidateID,
          widgets: [
            {
              action: INTERACTION,
              id: PATH_TO_PRO_CANDIDATE_PAGE,
              name,
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT,
            },
          ],
        }
      });

      setAnalytics(digitalData);
    }
  );

  /** 1.5.2 Tooltip Interactions */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.candidateDetails.tooltip-interaction',
    ({ output: { templates, slmCandidateID } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          slmCandidateID,
          widgets: [
            {
              action: 'tooltip',
              id: PATH_TO_PRO_CANDIDATE_PAGE,
              name: NOT_APPLICABLE,
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT,
            },
          ],
        }
      });

      setAnalytics(digitalData);
    }
  );

  /** 1.6 Job Postings Page */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('pathToProNetwork.dashboard.job-postings-page', () => {
    digitalData.page = {
      pageInfo: {
        pageName: `${PATH_TO_PRO_NETWORK}>job postings`,
        pageType: CONTENT,
      },
      category: {
        primaryCategory: PATH_TO_PRO_NETWORK,
        subCategory1: `${PATH_TO_PRO_NETWORK}>job postings`,
        subCategory2: `${PATH_TO_PRO_NETWORK}>job postings`,
      }
    };

    setAnalytics(digitalData);
  });

  /** 1.6.1 Create New Job Posting Flow */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.my-dashboard.job-postings.click-create-new', ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: CLICK,
              id: 'create job posting flow',
              name: PATH_TO_PRO_JOB_POSTINGS,
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT,
            },
          ],
        }
      });

      setAnalytics(digitalData);
    });

  /** 1.6.1.2 Create New Job Posting Publish and Save */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.jobPostings.create-new.publish-and-save',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: CLICK,
              id: 'create job posting overlay',
              name: PATH_TO_PRO_MY_DASHBOARD,
              templates, // 'publish' or 'save as draft'
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT,
            },
          ],
        }
      });

      setAnalytics(digitalData);
    }
  );

  /** 1.6.2 Job Postings Sort */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.dashboard.jobPostings.sortChange',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: SORT,
              id: PATH_TO_PRO_JOB_POSTINGS,
              name: PATH_TO_PRO_JOB_POSTINGS,
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT,
            },
          ],
        }
      });

      setAnalytics(digitalData);
    }
  );

  /** 1.6.3 Job Postings Actions  */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.my-dashboard.job-postings.click-action',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: ACTION,
              id: PATH_TO_PRO_JOB_POSTINGS,
              name: PATH_TO_PRO_JOB_POSTINGS,
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT,
            },
          ],
        }
      });

      setAnalytics(digitalData);
    });

  /** 1.7 Job Posting Detail Page (Job Applicants Page) */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('pathToProNetwork.dashboard.job-posting-details', () => {
    digitalData.page = {
      pageInfo: {
        pageName: 'path to pro>dashboard>job posting detail',
        pageType: CONTENT,
      },
      category: {
        primaryCategory: 'path-to-pro',
        subCategory1: 'path to pro>dashboard',
        subCategory2: 'path to pro>dashboard>job posting detail'
      },
    };

    setAnalytics(digitalData);
  });

  /** 1.7.1 Job Applicants Tab */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.dashboard.jobApplicants.tab',
    () => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: 'tab',
              id: PTP_JOB_APPLICANTS_WIDGET_ID,
              name: 'job posting detail',
              templates: 'job applicants tab',
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT,
            },
          ],
        }
      });

      setAnalytics(digitalData);
    }
  );

  /** 1.7.1.1 Job Posting Detail Sort */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.dashboard.jobApplicants.sortChange',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: SORT,
              id: 'job applicants tab',
              name: 'job posting detail',
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT,
            },
          ],
        }
      });

      setAnalytics(digitalData);
    });

  /** 1.7.1.2 Job Applicants Tab Actions */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.my-dashboard.job-applicants.click-action',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: ACTION,
              id: 'job applicants tab',
              name: 'job posting detail',
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT,
            },
          ],
        }
      });

      setAnalytics(digitalData);
    });

  /** 1.7.2 Posting Details Tab */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.dashboard.postingDetails.tab',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: 'tab',
              id: 'posting details',
              name: 'job posting detail',
              templates: `posting details tab>${templates}`,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT,
            },
          ],
        }
      });

      setAnalytics(digitalData);
    }
  );

  /** 1.7.2.1 Posting Details Modifications */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.jobPostings.postingDetails.modifications',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: 'modify',
              id: 'posting details tab',
              name: 'job posting detail',
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT,
            },
          ],
        }
      });

      setAnalytics(digitalData);
    }
  );

  /** 1.7.3 Recommendations Tab */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.dashboard.recommendations.tab',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: 'tab',
              id: 'recommendations',
              name: 'job posting detail',
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT
            }
          ]
        }
      });

      setAnalytics(digitalData);
    }
  );

  /** 1.7.3.1 Recommendations Tab Actions
   *
   * @templates = oneOf["invite", "show"]
   * */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.dashboard.recommendations.tab.click-action',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: ACTION,
              id: 'recommendations',
              name: 'job posting detail',
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT,
            },
          ],
        }
      });

      setAnalytics(digitalData);
    }
  );

  /** 1.8 Left Nav Link (Formerly 2.1) */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.landing.nav-link-click', () => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: {
          primaryCategory: STASH
        },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        eventMode: STASH,
        pageType: 'maml:landing',
        content: {
          widgets: [
            {
              action: 'left nav>ptp',
              id: NOT_APPLICABLE,
              name: NOT_APPLICABLE,
              templates: 'path to pro link',
              campaign: SUBCONTENT_PTP,
              component: 'left nav'
            }
          ]
        }
      });

      setAnalytics(digitalData);
    });

  /** 1.9 PTP Homepage Job Seekers Tab */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.homepage.job-seekers-buttons',
    ({ output: { buttonClicked } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: { eventName: MODULE_CLICK },
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: 'link',
              id: buttonClicked,
              name: 'ptp homepage',
              templates: 'job seekers tab',
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT
            }
          ]
        }
      });

      setAnalytics(digitalData);
    });

  /** 2.1 My Account Path to Pro Pod Click */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.landing.pod-click', () => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: {
          primaryCategory: STASH
        },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        eventMode: STASH,
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: 'pod click>ptp',
              id: NOT_APPLICABLE,
              name: NOT_APPLICABLE,
              templates: 'pro xtra benefits',
              campaign: SUBCONTENT_PTP,
              component: 'pod'
            }
          ]
        }
      });

      setAnalytics(digitalData);
    });

  /** 2.2 Landing Page Link Clicks */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.landing.link-click',
    ({ output: { templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: {
          primaryCategory: STASH
        },
        eventInfo: {
          eventName: MODULE_CLICK
        },
        eventMode: STASH,
        pageType: CONTENT,
        content: {
          widgets: [
            {
              action: CLICK,
              id: PTP_LANDING_PAGE_WIDGET_ID,
              name: NOT_APPLICABLE,
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT,
            },
          ],
        }
      });

      setAnalytics(digitalData);
    });

  /** 2.3 Path to Pro Candidate Profile Page */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.profile-details', () => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: {
          primaryCategory: 'page'
        },
        eventInfo: {
          eventName: 'ptp candidate profile page'
        }
      });

      setAnalytics(digitalData);
    });

  /** 2.4 Call or Email from Profile Page */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.profile-details.interactions', () => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: {
          primaryCategory: 'page'
        },
        eventInfo: {
          eventName: 'ptp candidate profile page'
        }
      });

      setAnalytics(digitalData);
    });

  /** 2.5 Links to Outside Profiles or Portfolio
     *
     * @candidateId
     * @socialNetwork = oneOf["linkedin", "instagram", "facebook", "youtube"]
     */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.profile-details.social-link-click',
    ({ output: { candidateId, socialNetwork } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: { eventName: MODULE_CLICK },
        pageType: CONTENT,
        content: {
          slmCandidateID: candidateId,
          widgets: [
            {
              action: CLICK,
              id: PTP_PROFILE_OVERLAY_WIDGET_ID,
              name: NOT_APPLICABLE,
              templates: `profile>${socialNetwork}`,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT
            }
          ]
        }
      });

      setAnalytics(digitalData);
    });

  /** 2.6 Hire this Candidate
       *
       * @candidateId
       * @templates = oneOf["hired>carousel", "hired>profile", "hired>search",
       *                    "hired>recently contacted", "hired>favorites",
       *                    "not hired>carousel", "not hired>profile", "not hired>search",
       *                    "not hired>recently contacted", "not hired>favorites"]
       */
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
    'pathToProNetwork.profile-details.hire-click',
    ({ output: { candidateId, templates } }) => {
      digitalData.event = digitalData?.event || [];
      digitalData.event.push({
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: { eventName: MODULE_CLICK },
        pageType: CONTENT,
        content: {
          slmCandidateID: candidateId,
          widgets: [
            {
              action: CLICK,
              id: PTP_HIRING_WIDGET_ID,
              name: NOT_APPLICABLE,
              templates,
              campaign: SUBCONTENT_PTP,
              component: PTP_COMPONENT
            }
          ]
        }
      });

      setAnalytics(digitalData);
    });
}
