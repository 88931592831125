import digitalData from 'digital-data';
import {
  PRODUCT_COMPARISON
} from '../../../constants';
import {
  setAnalytics
} from '../../../thd-analytics-utils';

export function compare() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('compare.new-products', ({ output: compareData }) => {
    if (!compareData) return;

    const {
      products = []
    } = compareData;

    digitalData.page = {
      pageInfo: {
        pageName: PRODUCT_COMPARISON,
        pageLoadEvent: PRODUCT_COMPARISON,
        pageType: PRODUCT_COMPARISON
      },
      category: {
        primaryCategory: PRODUCT_COMPARISON,
        subCategory1: PRODUCT_COMPARISON,
        subCategory2: PRODUCT_COMPARISON
      },
      myAccount: {},
      onsiteSearchInfo: {},
      myList: {}
    };

    digitalData.product = products.map((product) => {
      return {
        productInfo: {
          sku: product?.itemId
        }
      };
    });

    setAnalytics(digitalData);
  });
}