import digitalData from 'digital-data';
import { ARTICLE_EXPERIENCE } from '../../../constants';
import {
  getCategoryBreadCrumbsForArticle,
  makeEverythingLowerCase,
  setAnalytics
} from '../../../thd-analytics-utils';

export function articleExperience() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('article-experience.page-load', ({ output = {} }) => {
    const articleGuid = output?.articleGUID || '';
    const breadCrumbs = output?.breadCrumbs || '';
    digitalData.page = {
      pageInfo: {
        abTest: '',
        pageName: ARTICLE_EXPERIENCE + '>' + articleGuid,
        pageType: ARTICLE_EXPERIENCE,
        pageLoadEvent: '',
      },
      category: getCategoryBreadCrumbsForArticle(breadCrumbs), // TODO: check if any of these null cases ever triggers
    };
    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);
    setAnalytics(digitalData);
  });
}