/* global newrelic */

export const noResultsListener = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('no-results.loaded', ({ output }) => {
    const { keyword } = output;
    if (typeof keyword !== 'undefined') {
      newrelic.addPageAction('NoResultsLoaded', {
        noResultsKeyword: keyword
      });
    }
  });
};
