import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import {
  STASH,
  RECOMMENDATION_EVENTNAME,
  NOT_APPLICABLE,
  MERCHANDISE,
  MAJOR_APPLIANCES_UPPERCASE
} from '../constants';

function isPackage(path) {
  return path.some((name) => name.indexOf('Package') > -1);
}

export const quickView = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('quick-view.click-section', ({ output }) => {
    const { title, productType, path } = output;
    if ((productType === MERCHANDISE && isPackage(path)) || productType === MAJOR_APPLIANCES_UPPERCASE) {
      digitalData.event = digitalData.event || [];
      digitalData.event.push({
        category: {
          primaryCategory: 'interaction'
        },
        eventInfo: {
          eventName: 'pip sub-content'
        },
        subContent: {
          component: 'product-details',
          section: 'gen-merch-package',
          target: title
        }
      });
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('quick-view.click-footer', ({ output }) => {
    const { pageItemId = '', pageType = null } = getState() || {};
    const { productType, isLaundryBundle } = output;
    const recommendationType = !isLaundryBundle
      && productType === MAJOR_APPLIANCES_UPPERCASE ? 'kitchen package' : 'product bundle';
    if (productType === MAJOR_APPLIANCES_UPPERCASE || productType === MERCHANDISE) {
      digitalData.event = digitalData.event || [];
      digitalData.event.push({
        category: {
          primaryCategory: STASH
        },
        eventInfo: {
          eventName: RECOMMENDATION_EVENTNAME
        },
        eventMode: STASH,
        stash: {
          recommendation: {
            recommendationType,
            pageType,
            displayPosition: NOT_APPLICABLE,
            anchorProductSku: pageItemId,
            recommendedProduct: NOT_APPLICABLE,
            strategy: NOT_APPLICABLE,
            recommendationVersion: NOT_APPLICABLE,
            featureVersion: NOT_APPLICABLE,
            interval: NOT_APPLICABLE
          }
        }
      });
    }
  });
};