import digitalData from 'digital-data';

export const catalogPromo = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('hdhome-catalog-promo.submit', ({ output }) => {
    const ddoStashEvent = {
      eventInfo: {
        eventName: 'email sign up'
      },
      category: {
        primaryCategory: 'button submission',
        emailListType: 'hd home email subscription'
      }
    };
    digitalData.event.push(ddoStashEvent);
  });
};