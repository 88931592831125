import digitalData from 'digital-data';
import { makeEverythingLowerCase, setAnalytics } from '../../../thd-analytics-utils';

export function colorWall() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('color-wall.ready', ({ output = {} }) => {
    const { colorFamily } = output;

    digitalData.page = {
      pageInfo: {
        pageName: `paint>color wall>${colorFamily || 'popular'}`,
        pageType: 'category',
      },
      category: {
        primaryCategory: 'paint',
        subCategory1: 'paint>color wall',
        subCategory2: 'paint>color wall',
      },
    };

    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);

    setAnalytics(digitalData);
  });
}