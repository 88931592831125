import digitalData from 'digital-data';
import {
  STASH, CART_ADD_LOCATION, PIP, ATC, CART_ADD
} from '../constants';

export const toolsMaterials = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('tools-materials.get-started-click', () => {
    const getStartedButtonEvent = {
      category: {
        primaryCategory: 'overlay',
      },

      eventInfo: {
        eventName: 'project shopping drawer',
        abTest: 'get started ps drawer',
      },
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(getStartedButtonEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on(
    'tools-materials.ps-entry-cart-add-location',
    ({ output = {} }) => {
      const { projectId, category } = output;
      const cartAddLocationEvent = {
        category: {
          primaryCategory: STASH,
        },

        eventInfo: {
          eventName: CART_ADD_LOCATION,
        },

        eventMode: STASH,

        stash: {
          component: projectId,
          pageType: PIP,
          section: 'get started project shopping drawer',
          sharedSection: category?.toLowerCase(),
          target: ATC,
        },
      };
      digitalData.event = digitalData.event || [];
      digitalData.event.push(cartAddLocationEvent);
    }
  );

  window.LIFE_CYCLE_EVENT_BUS.on('tools-materials.ps-entry-cart-add-click', ({ output = {} }) => {
    const { product = {}, cartResponse = {}, fulfillmentOptions = {} } = output;
    const { pricing, itemId, identifiers, info } = product;
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const basePrice = formatter.format(pricing?.value);
    const fulfillment = {
      method: fulfillmentOptions?.method,
      ...(fulfillmentOptions?.store && { store: fulfillmentOptions?.store }),
      ...(fulfillmentOptions?.zip && { zip: fulfillmentOptions?.zip }),
    };
    const etaObj = !!fulfillmentOptions?.sthEndDate
      && !!fulfillmentOptions?.sthStartDate && {
      eta: {
        sthEndDate: fulfillmentOptions?.sthEndDate,
        sthStartDate: fulfillmentOptions?.sthStartDate,
      },
    };

    const cartAddClickEvent = {
      category: {
        primaryCategory: 'add to cart click',
      },

      eventInfo: {
        eventName: CART_ADD,
      },

      item: [
        {
          productInfo: {
            sku: itemId,
          },
          quantity: '1',
          price: {
            basePrice,
          },
          fulfillment,
        },
      ],

      cart: {
        cartID: cartResponse?.CartModel?.orderId
      }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(cartAddClickEvent);

    const cartObj = {
      cartID: cartResponse?.CartModel?.orderId,
      price: {
        cartTotal: cartResponse?.CartModel?.cartTotal,
      },
    };
    const cartItem = {
      fulfillment,
      price: {
        basePrice,
      },
      productInfo: {
        manufacturer: identifiers?.brandName,
        productName: identifiers?.productLabel,
        sku: itemId,
        subCategory2: info?.categoryHierarchy?.join('>'),
        ...(identifiers?.isSuperSku && { superSKU: itemId }),
        ...etaObj,
      },
      quantity: '1',
    };

    if (digitalData?.cart?.item
      && Array.isArray(digitalData?.cart?.item)) {
      // Overwrite existing cart object properties and push new item to it
      digitalData.cart = { ...digitalData.cart, ...cartObj };
      digitalData.cart.item.push(cartItem);
      return;
    }

    digitalData.cart = { ...cartObj, item: [cartItem] };
  });
};