import digitalData from 'digital-data';
import { setAnalytics, isDuplicatePageError } from '../../../thd-analytics-utils';
import { NUCLEUS, MY_HOMEPAGE, PAGE_DEFAULT_TEMPLATE } from '../../../constants';

const handleVisibilitychange = () => {
  window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('page.visibilitychange', () => {
    if (window?.__EXPERIENCE_CONTEXT__?.isConsumerApp && !document.hidden) {
      setAnalytics(digitalData);
    }
  });
};

export function myHomepage() {
  window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('guest-homepage.page-load', (output) => {
    digitalData.page = {
      pageInfo: {
        pageName: MY_HOMEPAGE,
        pageLoadEvent: '',
        abTest: 'guest_hp',
        platform: NUCLEUS,
        pageType: MY_HOMEPAGE,
        pageTemplate: PAGE_DEFAULT_TEMPLATE,
      },
      category: {
        primaryCategory: MY_HOMEPAGE,
        subCategory1: MY_HOMEPAGE,
        subCategory2: MY_HOMEPAGE,
      }
    };

    setAnalytics(digitalData);
    handleVisibilitychange();
  });

  window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('eprocurement-homepage.page-load', (output) => {
    digitalData.page = {
      pageInfo: {
        pageName: MY_HOMEPAGE,
        pageLoadEvent: '',
        abTest: 'epr_hp',
        platform: NUCLEUS,
        pageType: MY_HOMEPAGE,
        pageTemplate: PAGE_DEFAULT_TEMPLATE,
      },
      category: {
        primaryCategory: MY_HOMEPAGE,
        subCategory1: MY_HOMEPAGE,
        subCategory2: MY_HOMEPAGE,
      }
    };

    setAnalytics(digitalData);
    handleVisibilitychange();
  });

  window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('my-homepage.page-load', (output) => {
    digitalData.page = {
      pageInfo: {
        pageName: MY_HOMEPAGE,
        pageLoadEvent: '',
        abTest: 'recognized_hp',
        platform: NUCLEUS,
        pageType: MY_HOMEPAGE,
        pageTemplate: PAGE_DEFAULT_TEMPLATE,
      },
      category: {
        primaryCategory: MY_HOMEPAGE,
        subCategory1: MY_HOMEPAGE,
        subCategory2: MY_HOMEPAGE,
      }
    };

    setAnalytics(digitalData);
    handleVisibilitychange();
  });

  window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('pro-homepage.page-load', (output) => {
    digitalData.page = {
      pageInfo: {
        pageName: MY_HOMEPAGE,
        pageType: MY_HOMEPAGE,
      },
      category: {
        primaryCategory: MY_HOMEPAGE,
        subCategory1: MY_HOMEPAGE,
        subCategory2: MY_HOMEPAGE,
      }
    };

    setAnalytics(digitalData);
    handleVisibilitychange();
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('my-homepage.page-error', ({ output }) => {
    const {
      type = 'error',
      displayType = 'page',
      pageSection = '',
      message = '',
      code = ''

    } = output || {};

    const pageError = {
      type,
      displayType,
      pageSection,
      message,
      code
    };
    digitalData.page = digitalData.page || {};
    digitalData.page.error = digitalData.page.error || [];
    if (!isDuplicatePageError(digitalData, pageError)) {
      digitalData.page.error.push(pageError);
    }
  });
}
