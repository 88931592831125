import digitalData from 'digital-data';
import { BUTTON_SUBMISSION } from '../constants';

const sortChange = ({ output = {} }) => {
  const {
    sortByLabel,
    sortOrderLabel
  } = output;
  const formattedSortByLabel = typeof sortByLabel === 'string' ? sortByLabel.toLowerCase() : sortByLabel;
  const formattedSortOrderLabel = typeof sortOrderLabel === 'string' ? sortOrderLabel.toLowerCase() : sortOrderLabel;
  const thdAnalyticsEvent = {
    category: {
      primaryCategory: BUTTON_SUBMISSION
    },
    eventInfo: {
      eventName: 'my account settings'
    },
    myAccount: {
      settings: 'paint history: sort',
      searchTerm: `${formattedSortByLabel}:${formattedSortOrderLabel}`
    }
  };

  digitalData.event = digitalData.event || [];
  digitalData.event.push(thdAnalyticsEvent);
};

export const sortDropdown = () => {
  LIFE_CYCLE_EVENT_BUS.on('sort-dropdown.sort-change', sortChange);
};
