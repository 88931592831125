import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';
import {
  GIFT_CARD_EVENT, GIFT_CARD, GIFT_CARD_TYPE, GIFT_CARD_PAGE_TYPE,
  GIFT_CARD_EVENT_MALL, GIFT_CARD_MALL_SPECIALITY, GIFT_CARD_MALL_FIRST,
  GIFT_CARD_BALANCE, GIFT_CARD_BALANCE_TYPE, GIFT_CARD_BALANCE_EVENT
} from '../../../constants';

export function giftcards() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('giftcards.page-load', () => {
    const pageName = GIFT_CARD;
    const pageType = GIFT_CARD_PAGE_TYPE;
    const primaryCategory = GIFT_CARD;
    const subCategory1 = GIFT_CARD;
    const subCategory2 = GIFT_CARD;

    digitalData.page = {
      pageInfo: {
        pageName,
        pageType,
      },
      category: {
        primaryCategory,
        subCategory1,
        subCategory2,
      },
    };
    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('giftcards.check-balance', () => {
    const pageLoadEvent = GIFT_CARD_BALANCE_EVENT;
    const pageName = GIFT_CARD_BALANCE;
    const pageType = GIFT_CARD_BALANCE_TYPE;
    const primaryCategory = GIFT_CARD_BALANCE;
    const subCategory1 = GIFT_CARD_BALANCE;
    const subCategory2 = GIFT_CARD_BALANCE;
    digitalData.page = {
      pageInfo: {
        pageLoadEvent,
        pageName,
        pageType
      },
      category: {
        primaryCategory,
        subCategory1,
        subCategory2
      }
    };
    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('giftcards.reload-balance', (args) => {
    digitalData.page = { ...args?.output };
    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('giftcards.page-load-pip', (args) => {
    const { cardName } = args?.output;
    const pageLoadEvent = GIFT_CARD_EVENT;
    const pageName = GIFT_CARD + '>' + cardName;
    const pageType = GIFT_CARD_TYPE;
    const primaryCategory = GIFT_CARD;
    const subCategory1 = GIFT_CARD;
    const subCategory2 = GIFT_CARD;
    digitalData.page = {
      pageInfo: {
        pageLoadEvent,
        pageName,
        pageType
      },
      category: {
        primaryCategory,
        subCategory1,
        subCategory2
      }
    };
    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('gift-cards-list.ready', (jsonType) => {
    let GIFT_CARD_JSON_TYPE;
    let GIFT_CARD_CATEGORY_TYPE;
    if (jsonType.output === 2) {
      GIFT_CARD_JSON_TYPE = GIFT_CARD_MALL_SPECIALITY;
      GIFT_CARD_CATEGORY_TYPE = GIFT_CARD_JSON_TYPE;
    } else {
      GIFT_CARD_JSON_TYPE = GIFT_CARD_MALL_FIRST;
      GIFT_CARD_CATEGORY_TYPE = 'gift card ' + GIFT_CARD_JSON_TYPE;
    }
    const pageLoadEvent = GIFT_CARD_EVENT_MALL;
    const pageName = GIFT_CARD_EVENT_MALL + '>' + GIFT_CARD_JSON_TYPE;
    const pageType = GIFT_CARD + ': ' + GIFT_CARD_JSON_TYPE;
    const primaryCategory = GIFT_CARD_CATEGORY_TYPE;
    const subCategory1 = GIFT_CARD_CATEGORY_TYPE;
    const subCategory2 = GIFT_CARD_CATEGORY_TYPE;
    digitalData.page = {
      pageInfo: {
        pageLoadEvent,
        pageName,
        pageType
      },
      category: {
        primaryCategory,
        subCategory1,
        subCategory2
      }
    };
    setAnalytics(digitalData);
  });
}
// # sourceMappingURL=giftcards.js.map