import digitalData from 'digital-data';
import { BUTTON_SUBMISSION, STASH } from '../constants';

const chooseOptions = () => {
  const thdAnalyticsEvent = {
    category: {
      primaryCategory: STASH
    },
    eventMode: STASH,
    eventInfo: {
      eventName: 'my account settings',
    },
    stash: {
      myAccount: {
        settings: 'paint purchase history: choose options',
      }
    }
  };

  digitalData.event = digitalData.event || [];
  digitalData.event.push(thdAnalyticsEvent);
};

const paginate = () => {

  const thdAnalyticsEvent = {
    category: {
      primaryCategory: BUTTON_SUBMISSION
    },
    eventInfo: {
      eventName: 'my account settings',
    },
    myAccount: {
      settings: 'paint purchase history: pagination',
    }
  };

  digitalData.event = digitalData.event || [];
  digitalData.event.push(thdAnalyticsEvent);
};

export const paintReorderResults = () => {
  LIFE_CYCLE_EVENT_BUS.on('paint-reorder-results.choose-options', chooseOptions);
  LIFE_CYCLE_EVENT_BUS.on('paint-reorder-results.paginate', paginate);
};