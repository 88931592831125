export const Fulfillment = {
  isBODFS: {
    read(props) {
      console.log('isBODFS resolver is deprecated');
      return null;
      // return !!Fulfillment.bodfs(props);
    }
  },
  bodfs: {
    read() {
      console.log('bodfs resolver is deprecated');
      return null;
      // const deliveryService = (fulfillmentOptions || []).find((option) => option.type === 'delivery');
      // eslint-disable-next-line max-len
      // const expressFulfillment = (deliveryService?.services || []).find((service) => service.type === 'express delivery');
      // return expressFulfillment || null;
    }
  },
  isBOPIS: {
    read() {
      console.log('isBOPIS resolver is deprecated');
      return null;
      // return !!Fulfillment.bopis(props);
    }
  },
  bopis: {
    read() {
      console.log('bopis resolver is deprecated');
      return null;
      // const pickupService = (fulfillmentOptions || []).find((option) => option.type === 'pickup');
      // const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');
      // return bopisFulfillment || null;
    }
  },
  isBOSS: {
    read() {
      console.log('isBOSS resolver is deprecated');
      return null;

      // return !!Fulfillment.boss(props);
    }
  },
  boss: {
    read() {
      console.log('boss resolver is deprecated');
      // const pickupService = (fulfillmentOptions || []).find((option) => option.type === 'pickup');
      // const bossFulfillment = (pickupService?.services || []).find((service) => service.type === 'boss');
      // return bossFulfillment || null;
      return null;
    }
  },
  isCurrentlyUnavailable: {
    read() {
      console.log('isCurrentlyUnavailable resolver is deprecated');
      return null;
      // return !((fulfillmentOptions || [])
      //   .find((option) => (option.services || [])
      //     .find((service) => (service.locations || [])
      //       .find((location) => location.inventory?.isInStock))));
    }
  },
  isOutOfStockOnline: {
    read() {
      console.log('isOutOfStockOnline resolver is deprecated');
      return null;
      // const deliveryService = (fulfillmentOptions || []).find((option) => option.type === 'delivery');
      // const shippingFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
      //
      // const locations = shippingFulfillment?.locations;
      //
      // if (locations?.length) {
      //   return !!(locations[0]?.inventory?.isOutOfStock);
      // }
      //
      // if (!fulfillmentOptions) {
      //   return true;
      // }
      //
      // return false;
    }
  },
  isSTH: {
    read() {
      console.log('isSTH resolver is deprecated');
      return null;
      // return !!Fulfillment.shipToHome(props);
    }
  },
  shipToHome: {
    read() {
      console.log('shipToHome resolver is deprecated');
      return null;
      // const deliveryService = (fulfillmentOptions || []).find((option) => option.type === 'delivery');
      // const shippingFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
      // return shippingFulfillment || null;
    },
  },
  isFulfillable: {
    read() {
      console.log('isFulfillable resolver is deprecated');
      return null;
      // return !!(fulfillmentOptions || []).find((option) => option.fulfillable);
    }
  },
  storeType: {
    read() {
      console.log('storeType resolver is deprecated');
      return null;
      // const pickupService = (fulfillmentOptions || []).find((option) => option.type === 'pickup');
      // const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');
      // if (bopisFulfillment && bopisFulfillment?.locations?.length > 1) {
      //   return 'nearby';
      // }
      // return 'anchor';
    }
  }
};
