import { useContext, useEffect, useState } from 'react';

import ExperienceContext from '../components/ExperienceContext';

const parse = (input) => {
  let val = input;
  if (val === 'true') val = true;
  if (val === 'false') val = false;
  if (/^\d+$/.test(val)) val = parseInt(val, 10);
  if (/^\d+\.\d+$/.test(val)) val = parseFloat(val);

  return val;
};

export const useConfigService = (key) => {
  const { configService: csContext = {} } = useContext(ExperienceContext);
  const [configService, setConfigService] = useState(csContext);

  const lifeCycleCB = ({ output }) => {
    setConfigService((existing) => ({
      ...existing,
      ...output,
    }));
  };

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('CONFIG_SERVICE_CHANGE', lifeCycleCB);
    window.LIFE_CYCLE_EVENT_BUS.on('CONFIG_SERVICE_CHANGE', lifeCycleCB);
    return () => {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.off('CONFIG_SERVICE_CHANGE', lifeCycleCB);
      window.LIFE_CYCLE_EVENT_BUS.off('CONFIG_SERVICE_CHANGE', lifeCycleCB);
    };
  }, []);

  if (key) {
    return parse(configService[key]);
  }

  const ret = Object.keys(configService).reduce((acc, cur) => {
    return {
      ...acc,
      [cur]: parse(configService[cur]),
    };
  }, {});

  return ret;

};
