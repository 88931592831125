import deepExtend from 'deep-extend';

const { take, race, delay, all } = LIFE_CYCLE_EVENT_BUS.effects;

const CONTEXT_KEY = Symbol.for('@thd-olt-component-react/thd-analytics/state');
const globalSymbols = Object.getOwnPropertySymbols(global);
const hasState = (globalSymbols.indexOf(CONTEXT_KEY) > -1);

if (!hasState) {
  global[CONTEXT_KEY] = {};
}

const state = global[CONTEXT_KEY];

state.store = {};

export const getState = () => {
  return state.store;
};

const parseSelectorState = (selector, localState) => {
  const parts = selector.split('.');
  let current = {
    ...localState
  };

  for (let i = 0; i < parts.length; i++) {//eslint-disable-line
    const key = parts[i];
    if (typeof current[key] !== 'undefined') {
      current = current[key];
      if (i === parts.length - 1) return current;
    } else {
      break;
    }
  }

  return undefined;
};

export function* selectSingleState(selector, opts = {}) {
  const localState = getState();
  const result = parseSelectorState(selector, localState);

  if (typeof result !== 'undefined') return result;
  while (true) {
    const { timeout } = yield race({
      event: take('thd-analytics.state-change'),
      timeout: delay(opts.timeout || 10000)
    });

    if (timeout) return null;

    const res = parseSelectorState(selector, getState());
    if (res) return res;
  }
}

// 'dataSources.products.12345'
export function* selectState(selector, opts = {}) {
  if (typeof selector === 'string') {
    return yield selectSingleState(selector, opts);
  }
  return yield all(selector.map((sel) => selectSingleState(sel, opts)));
}

export const resetState = () => {
  state.store = {};
  return state.store;
};

export const setState = (newState) => {
  deepExtend(state.store, newState);
  LIFE_CYCLE_EVENT_BUS.trigger('thd-analytics.state-change', state.store);
  return state.store;
};
