import React from 'react';
import { node, shape, bool } from 'prop-types';
import { HelmetContext } from './HelmetContext';

const HelmetProvider = ({ children, context, canUseDom }) => {

  const value = {
    context,
    canUseDom,
  };
  return (
    <HelmetContext.Provider value={value}>
      {children}
    </HelmetContext.Provider>
  );
};

HelmetProvider.propTypes = {
  children: node.isRequired,
  context: shape({}).isRequired,
  canUseDom: bool
};

HelmetProvider.defaultProps = {
  canUseDom: true
};

export { HelmetProvider };
