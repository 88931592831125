import digitalData from 'digital-data';

const defaultPrimaryCategory = 'button submission';
const pushVideoPlayerAnalyticsData = ({ output, defaultEventName }) => {
  const {
    videoId = '',
    videoName = '',
    experienceName = '',
    primaryCategory = defaultPrimaryCategory,
    eventName = defaultEventName
  } = output;

  const analyticsEvent = {
    category: {
      primaryCategory
    },
    eventInfo: {
      eventName
    },
    video: {
      id: videoId,
      name: videoName
    },
  };

  switch (experienceName) {
  case 'article':
    analyticsEvent.video.type = output.articleType;
    break;
  case 'PIP Gallery':
    analyticsEvent.video.type = output.galleryType;
    if (eventName === 'pip sub-content') {
      analyticsEvent.subContent = {
        section: output.section || '',
        component: output.component || '',
        position: output.position || '',
        target: output.target || 'video'
      };
    }
    break;
  default:
    analyticsEvent.video.type = '';
    break;
  }

  digitalData.event.push(analyticsEvent);
};

export const videoPlayer = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('video-player.start', ({ output = {} }) => {
    pushVideoPlayerAnalyticsData({ output, defaultEventName: 'video start' });
  });

  window.LIFE_CYCLE_EVENT_BUS.on('video-player.complete', ({ output = {} }) => {
    pushVideoPlayerAnalyticsData({ output, defaultEventName: 'video complete' });
  });
};