import digitalData from 'digital-data';

export const productSpecifications = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('product-specifications.accordion-open', () => {
    digitalData.event = digitalData.event || [];
    digitalData.event.push({
      category: {
        primaryCategory: 'interaction'
      },
      eventInfo: {
        eventName: 'pip sub-content'
      },
      subContent: {
        component: 'accordion',
        section: 'zone-b',
        target: 'specifications'
      },
    });
  });
};