/* global newrelic */
export const tntListener = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('tnt.enabled', ({ output }) => {
    const {
      testName: tntTestName,
      cookieName: tntCookieName,
      startDate: tntStartDate,
      endDate: tntEndDate,
    } = output;
    newrelic.addPageAction('tntEnabled', {
      tntTestName,
      tntCookieName,
      tntStartDate,
      tntEndDate,
    });
    newrelic.setCustomAttribute('tntTestName', tntTestName);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('tnt.expired', ({ output }) => {
    const {
      testName: tntTestName,
      cookieName: tntCookieName,
      startDate: tntStartDate,
      endDate: tntEndDate,
    } = output;
    newrelic.addPageAction('tntExpired', {
      tntTestName,
      tntCookieName,
      tntStartDate,
      tntEndDate
    });
  });

};
