import digitalData from 'digital-data';
import {
  setAnalytics
} from '../../../thd-analytics-utils';
import { REBATE_CENTER, NUCLEUS } from '../../../constants';

export function rebateCenter() {
  const REBATE_CENTER_DDO = {
    pageInfo: {
      abTest: '',
      pageName: REBATE_CENTER,
      pageType: 'tool:rebate center:landing',
      platform: NUCLEUS
    },
    category: {
      primaryCategory: REBATE_CENTER,
      subCategory1: REBATE_CENTER,
      subCategory2: REBATE_CENTER
    }
  };

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('rebate-center.page-load', () => {
    digitalData.page = REBATE_CENTER_DDO;
    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('rebate-center.page-load-error', ({ output }) => {
    const { status } = output;
    digitalData.page = {
      error: [
        {
          type: 'advisory',
          displayType: 'page',
          pageSection: REBATE_CENTER,
          message: `Unable to pull in rebate data - ${status}`,
          errorCode: status
        }
      ]
    };
    setAnalytics(digitalData);
  });
}