export const getHdppDisplayedPreloadEvent = () => {
  return {
    eventMode: 'preload',
    category: {
      primaryCategory: 'hdpp content'
    },
    eventInfo: {
      eventName: 'hdpp display'
    },
    item: [{
      productInfo: {
        displayedProtectionPlan: true
      }
    }]
  };
};

export const hasPathNameChanged = ({ location, routerChangeEvent }) => {
  const previousPathName = location?.pathname;
  const newPathName = routerChangeEvent?.output?.location?.pathname;
  return previousPathName !== newPathName;
};