/* global newrelic */
export const experienceHandler = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('app-render.uncaught_error', ({ output }) => {
    const { error } = output || {};
    newrelic.noticeError(error);
    newrelic.addPageAction('ExperienceError', {
      errorMessage: error?.message,
      errorStack: error?.stack
    });
  });
};
