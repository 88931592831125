import digitalData from 'digital-data';
import {
  BUTTON_SUBMISSION, OVERLAY, INSTANT_CHECKOUT,
} from '../constants';

export const instantCheckoutPageOverlay = () => {
  const trackEvent = (event) => {
    digitalData.event = digitalData.event || [];
    digitalData.event.push(event);
  };

  const instantChekoutPageEvents = {
    modifyICAddressPaymentOverlay: ({ output }) => {
      const { value } = output;
      const DDOEvent = {
        category: {
          primaryCategory: OVERLAY
        },
        eventInfo: {
          eventName: value,
        }
      };

      trackEvent(DDOEvent);
    },

    instantChekoutPageButtonSubmission: ({ output }) => {
      const { overlayType, action } = output;
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        myAccount: {
          settings: `${INSTANT_CHECKOUT}: ${overlayType} overlay: ${action}`,
        }
      };

      trackEvent(DDOEvent);
    },

    addressPaymentUpdateSuccess: ({ output }) => {
      const { action, item } = output;
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        myAccount: {
          settings: `${INSTANT_CHECKOUT}: ${action} ${item}: ${item} updated: success`,
        },
      };
      trackEvent(DDOEvent);
    },

    continueShoppingLinkClick: ({ output }) => {
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        myAccount: {
          settings: `${INSTANT_CHECKOUT}: ${output} updated: success: continue shopping`,
        },
      };
      trackEvent(DDOEvent);
    },

    pagination: ({ output }) => {
      const { overlayName, action, pageNumber } = output;
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        myAccount: {
          settings: `${INSTANT_CHECKOUT}: ${overlayName}: ${action}: ${pageNumber}`,
        },
      };
      trackEvent(DDOEvent);
    },

    postPageErrors: ({ output }) => {
      const {
        pageSection, message, code, type, displayType
      } = output;
      const pageErrors = [];
      const errorDetails = {
        eventName: 'post page error',
        type,
        displayType,
        pageSection,
        message,
        code
      };
      pageErrors.push(errorDetails);
      const DDOEvent = {
        category: {
          primaryCategory: 'error'
        },
        eventInfo: {
          eventName: 'post page error',
          error: pageErrors
        }
      };
      trackEvent(DDOEvent);
    },
  };

  window.LIFE_CYCLE_EVENT_BUS.on('instant-checkout-page-modify-overlay',
    instantChekoutPageEvents.modifyICAddressPaymentOverlay);
  window.LIFE_CYCLE_EVENT_BUS.on('instant-checkout-page-button',
    instantChekoutPageEvents.instantChekoutPageButtonSubmission);
  window.LIFE_CYCLE_EVENT_BUS.on('instant-checkout-page-update-success',
    instantChekoutPageEvents.addressPaymentUpdateSuccess);
  window.LIFE_CYCLE_EVENT_BUS.on('instant-checkout-post-page-error',
    instantChekoutPageEvents.postPageErrors);
  window.LIFE_CYCLE_EVENT_BUS.on('instant-checkout-continue-shopping',
    instantChekoutPageEvents.continueShoppingLinkClick);
  window.LIFE_CYCLE_EVENT_BUS.on('instant-checkout-pagination',
    instantChekoutPageEvents.pagination);
};
