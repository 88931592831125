import digitalData from 'digital-data';

export const price = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('price.click', ({ output = {} }) => {
    const { location = '' } = output;
    const locationStr = String(location).toLowerCase();
    let category;
    let name;

    // @todo do with path
    if (locationStr === 'map-link') {
      category = 'tooltip';
      name = 'map disclosure';
    } else if (locationStr === 'instant-rebate') {
      category = 'tooltip';
      name = 'instant rebate details';
    }

    const ddoEvent = {
      category: {
        primaryCategory: category
      },
      eventInfo: {
        eventName: name
      }
    };
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('price.rebates_click', () => {
    const ddoEvent = {
      category: {
        primaryCategory: 'overlay'
      },
      eventInfo: {
        eventName: 'available rebates'
      }
    };
    digitalData.event.push(ddoEvent);
  });

};