import digitalData from 'digital-data';
import {
  CLICKTHROUGH,
  MODULE_CLICK
} from '../constants';

export const revjetBundle = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('revjet-bundle.click', ({ output = {} }) => {
    const {
      properties = {},
      data = {},
      parent = {},
      clickID = ''
    } = output;

    const ddoEvent = {
      eventInfo: {
        eventName: MODULE_CLICK
      },
      category: {
        primaryCategory: CLICKTHROUGH
      },
      content: {
        clickID,
        revjetProperties: {
          ...properties
        },
        container: {
          ...parent
        },
        component: {
          ...data
        }
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};