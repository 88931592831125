/* global newrelic */

export const kpiListener = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('component-selector.selected', ({ output }) => {
    const { loadTime } = output;
    newrelic.addPageAction('KeyPerformanceIndicator', {
      kpiName: 'componentSelectorLoadTime',
      kpiValue: loadTime,
      kpiComponentName: '@thd-olt-component-react/component-selector'
    });
  });

  window.LIFE_CYCLE_EVENT_BUS.on('component-selector.default-render', ({ output }) => {
    const { renderDefault } = output;
    newrelic.addPageAction('KeyPerformanceIndicator', {
      kpiName: 'componentSelectorDefaultRendered',
      kpiValue: renderDefault,
      kpiComponentName: '@thd-olt-component-react/component-selector'
    });
  });

  window.LIFE_CYCLE_EVENT_BUS.on('component-selector.variation-render', ({ output }) => {
    const { componentId } = output;
    newrelic.addPageAction('KeyPerformanceIndicator', {
      kpiName: 'componentSelectorIsVariationRendered',
      kpiValue: componentId,
      kpiComponentName: '@thd-olt-component-react/component-selector'
    });
  });

  window.LIFE_CYCLE_EVENT_BUS.on('component-selector.timeout', ({ output }) => {
    newrelic.addPageAction('KeyPerformanceIndicator', {
      kpiName: 'componentSelectorTimeout',
      kpiValue: output,
      kpiComponentName: '@thd-olt-component-react/component-selector'
    });
  });
  window.LIFE_CYCLE_EVENT_BUS.on('component-selector.adobeError', ({ output }) => {
    const { error } = output;
    newrelic.addPageAction('KeyPerformanceIndicator', {
      kpiName: 'componentSelectorAdobeError',
      kpiValue: error,
      kpiComponentName: '@thd-olt-component-react/component-selector'
    });
  });
  window.LIFE_CYCLE_EVENT_BUS.on('component-selector.deError', ({ output }) => {
    const { error } = output;
    newrelic.addPageAction('KeyPerformanceIndicator', {
      kpiName: 'componentSelectorDEError',
      kpiValue: error,
      kpiComponentName: '@thd-olt-component-react/component-selector'
    });
  });
  window.LIFE_CYCLE_EVENT_BUS.on('component-selector.error', ({ output }) => {
    const { error } = output;
    newrelic.addPageAction('KeyPerformanceIndicator', {
      kpiName: 'componentSelectorError',
      kpiValue: error,
      kpiComponentName: '@thd-olt-component-react/component-selector'
    });
  });
  window.LIFE_CYCLE_EVENT_BUS.on('sticky-nav.toggle', ({ output }) => {
    const { hidden } = output;
    newrelic.addPageAction('KeyPerformanceIndicator', {
      kpiName: 'stickyNavToggle',
      kpiValue: hidden,
      kpiComponentName: '@thd-olt-component-react/sticky-nav'
    });
  });
  window.LIFE_CYCLE_EVENT_BUS.on('sticky-nav.click', ({ output }) => {
    const { hash } = output;
    newrelic.addPageAction('KeyPerformanceIndicator', {
      kpiName: 'stickyNayClick',
      kpiValue: hash,
      kpiComponentName: '@thd-olt-component-react/sticky-nav'
    });
  });
  window.LIFE_CYCLE_EVENT_BUS.on('super-sku.ready', ({ output }) => {
    const { height } = output || {};
    if (typeof height !== 'undefined') {
      newrelic.addPageAction('KeyPerformanceIndicator', {
        kpiName: 'superSkuHeight',
        kpiValue: height,
        kpiComponentName: '@thd-olt-component-react/super-sku'
      });
    }
  });
  window.LIFE_CYCLE_EVENT_BUS.on('withDynamicImport.error', ({ output }) => {
    const { name, message, stack } = output || {};
    if (name) {
      newrelic.addPageAction('KeyPerformanceIndicator', {
        kpiName: `DynamicImportError:${name}`,
        kpiValue: message,
        kpiComponentName: '@thd-nucleus/app-render'
      });
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('product-pod.click', ({ output }) => {
    const { sponsoredProduct } = output || {};

    newrelic.addPageAction('KeyPerformanceIndicator', {
      kpiName: 'ProductPodClick',
      kpiValue: sponsoredProduct ? 'sponsored' : 'organic',
      kpiComponentName: '@thd-olt-component-react/product-pod'
    });
  });

  window.LIFE_CYCLE_EVENT_BUS.on('product-pod.load', ({ output }) => {
    const { sponsoredProduct } = output || {};
    newrelic.addPageAction('KeyPerformanceIndicator', {
      kpiName: 'ProductPodLoad',
      kpiValue: sponsoredProduct ? 'sponsored' : 'organic',
      kpiComponentName: '@thd-olt-component-react/product-pod'
    });
  });

  window.LIFE_CYCLE_EVENT_BUS.on('product-pod.add-to-cart', ({ output }) => {
    const { sponsoredProduct } = output || {};

    newrelic.addPageAction('KeyPerformanceIndicator', {
      kpiName: 'ProductPodATC',
      kpiValue: sponsoredProduct ? 'sponsored' : 'organic',
      kpiComponentName: '@thd-olt-component-react/product-pod'
    });
  });

  window.LIFE_CYCLE_EVENT_BUS.on('@@ADD-TO-CART/LOCATION', ({ output }) => {
    const { section, items, parentId } = output || {};
    const [item] = items || [];
    if (section === 'buybox') {
      newrelic.addPageAction('KeyPerformanceIndicator', {
        kpiName: `BuyboxATC:${parentId ? 'SuperSku' : 'Item'}`,
        kpiValue: parentId || item?.productID,
        kpiComponentName: '@thd-olt-component-react/buybox'
      });
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('sponsored-content.event', ({ output }) => {
    const { adType, event } = output;
    if (typeof adType !== 'undefined') {
      newrelic.addPageAction('KeyPerformanceIndicator', {
        kpiName: adType,
        kpiValue: event,
        kpiComponentName: '@thd-olt-component-react/sponsored-content'
      });
    }
  });

};