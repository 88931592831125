import digitalData from 'digital-data';

export const bulkFulFillment = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('bulkFulfillment.click', () => {
    const bulkFFMEventData = {
      category: {
        primaryCategory: 'overlay',
      },
      eventInfo: {
        eventName: 'bulk change fulfillment method',
      }
    };
    digitalData.event.push(bulkFFMEventData);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('bulkFulfillment.change', ({ output = {} }) => {
    const {
      items = []
    } = output;

    const bulkFFMItemChangeData = {
      category: {
        primaryCategory: 'cart view modification',
      },
      eventInfo: {
        eventName: 'bulk cart fulfillment change',
      },
      item: items
    };
    if (items.length > 0) {
      digitalData.event.push(bulkFFMItemChangeData);
    }
  });
};
