import { useEffect, useState, useMemo } from 'react';
import debounce from '../private/utils/debounce';

const isBrowser = typeof window !== 'undefined';

const screens = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1440
};

const screenValues = Object.values(screens);

export const useBreakpoint = (target, options = {}) => {

  const {
    ssr = false,
    current: currentProp = false,
  } = options;

  const [screen, setScreen] = useState(ssr || !isBrowser ? false : window.innerWidth);

  useEffect(() => {
    const setSideScreen = debounce(() => {
      setScreen(window.innerWidth);
    });
    setSideScreen();
    window.addEventListener('resize', setSideScreen);
    return () => {
      setSideScreen.clear();
      window.removeEventListener('resize', setSideScreen);
    };
  }, []);

  const [exact, greater, lesser, current] = useMemo(() => {
    if (!screen) return [false, false, false, currentProp && 'unprefixed'];

    const targetValue = screens[target];

    const currentValue = screenValues.reduce((acc, value) => {
      if (screen >= value) {
        return value;
      }
      return acc;
    }, 0);

    let breakpoint;

    if (currentProp) {
      [breakpoint = 'unprefixed'] = Object.entries(screens)
        .find(([key, val]) => val === currentValue)
        || [];
    }

    return [
      currentValue === targetValue,
      screen >= targetValue,
      screen < targetValue,
      breakpoint
    ];
  }, [target, screen, currentProp]);

  return {
    exact,
    greater,
    lesser,
    current
  };
};