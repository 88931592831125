import digitalData from 'digital-data';
import { BUTTON_SUBMISSION, UGC_CLICK, QUESTIONS_AND_ANSWERS, NOT_APPLICABLE } from '../constants';

export const questionsAndAnswers = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('questions-and-answers.click', ({ output = {} }) => {
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      content: {
        ugc: [{
          module: QUESTIONS_AND_ANSWERS,
          component: output?.component,
          element: output?.element || NOT_APPLICABLE
        }]
      },
      eventInfo: {
        eventName: UGC_CLICK
      }
    };
    if (digitalData && digitalData.event) {
      digitalData.event.push(ddoEvent);
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('questions-and-answers.accordion-open', () => {
    digitalData.event = digitalData.event || [];
    digitalData.event.push({
      category: {
        primaryCategory: 'interaction'
      },
      eventInfo: {
        eventName: 'pip sub-content'
      },
      subContent: {
        component: 'accordion',
        section: 'zone-b',
        target: 'questions-and-answers'
      },
    });
  });
};