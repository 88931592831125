import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';
import {
  DD_PAGE_NAME, DD_SITE_NAME, DD_PAGE_EVENT, EMPTY_CART, NUCLEUS, PAGE_DEFAULT_TEMPLATE
} from '../../../constants';

export function myCart() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('myCart.page-load', ({ output = null }) => {
    let url;
    let logout;
    let categoryArray;
    let subCategory2;
    let page;
    const isEmptyCart = output?.isEmptyCart || false;
    page = isEmptyCart ? EMPTY_CART : DD_PAGE_NAME;
    let siteName = DD_SITE_NAME;
    let pageEvent = isEmptyCart ? EMPTY_CART : DD_PAGE_EVENT;
    url = window.location.href;
    logout = url.indexOf('/Logout');
    if (logout !== -1) {
      page = 'Logout';
    }
    categoryArray = page.split('>');
    subCategory2 = '';

    if (categoryArray.length > 1) {
      subCategory2 = '>' + categoryArray[1];
    }

    if (output?.cart) {
      digitalData.cart = output.cart;
    }

    if (output?.content) {
      digitalData.content = output.content;
    }

    digitalData.page = {
      pageInfo: {
        pageName: siteName + '>' + page,
        pageType: siteName,
        abTest: 'mycart v1',
        pageLoadEvent: (pageEvent !== '' || page === 'quick add' ? pageEvent : page),
        pageTemplate: PAGE_DEFAULT_TEMPLATE,
        platform: NUCLEUS
      },
      category: {
        primaryCategory: siteName,
        subCategory1: siteName + '>' + categoryArray[0],
        subCategory2: siteName + '>' + categoryArray[0] + subCategory2
      },
      error: output?.pageErrors || []
    };

    if (typeof output?.validate === 'function') {
      digitalData.page = output.validate(digitalData.page) || digitalData.page;
    }

    setAnalytics(digitalData);
  });
}