import React from 'react';
import { node, shape } from 'prop-types';
import { AppContext } from './AppContext';

const AppProvider = ({ children, instance }) => {

  const value = {
    instance
  };
  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: node.isRequired,
  instance: shape({}).isRequired
};

export { AppProvider };
