import digitalData from 'digital-data';
import { INTERACTION, PIP_SUB_CONTENT, BIP_PAGE } from '../constants';

export const removeItem = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('remove-item.success', ({ output = { position: 'n/a' } }) => {
    const { position } = output;
    digitalData.event = digitalData.event || [];
    const DDOEvent = {
      category: {
        primaryCategory: INTERACTION,
      },
      eventInfo: {
        eventName: PIP_SUB_CONTENT,
      },
      subContent: {
        component: 'remove',
        position,
        section: BIP_PAGE,
        target: 'drawer',
      },
    };
    digitalData.event.push(DDOEvent);
  });
};