import digitalData from 'digital-data';
import {
  makeEverythingLowerCase,
  setAnalytics
} from '../../../thd-analytics-utils';

export function projectCalculators() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('project-calculator.page-load', ({ output }) => {
    const { calculatorType } = output;
    digitalData.page = {
      pageInfo: {
        pageName: `calculator>${calculatorType}`,
        pageType: `tool:${calculatorType}:calculator`,
      },
      category: {
        primaryCategory: 'calculator',
        subCategory1: `calculator>${calculatorType}`,
        subCategory2: `calculator>${calculatorType}`
      }
    };
    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData?.page?.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData?.page?.category);
    setAnalytics(digitalData);
  });

  LIFE_CYCLE_EVENT_BUS.on('project-calculator.calculate', ({ output }) => {
    const { calculatorType } = output;
    const thdAnalyticsEvent = {
      category: {
        primaryCategory: 'button submission'
      },
      eventInfo: {
        eventName: 'calculate'
      },
      calculate: {
        type: calculatorType
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(thdAnalyticsEvent);
  });
}