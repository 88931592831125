import digitalData from 'digital-data';
import {
  OVERLAY,
  BUTTON_SUBMISSION,
  MY_ACCOUNT_SETTINGS,
} from '../../../constants';

// https://github.com/one-thd/ocm_pd_experience_customer-account-accept-invite
export const acceptInvitePage = () => {
  const trackEvent = (event) => {
    digitalData.event = digitalData.event || [];
    digitalData.event.push(event);
  };

  const eventName = 'pro xtra invite:';

  const acceptInviteEvents = {
    acceptInviteStep: () => {
      const DDOEvent = {
        category: {
          primaryCategory: OVERLAY,
        },
        eventInfo: {
          eventName: `${eventName} accept invite`,
        },
      };
      trackEvent(DDOEvent);
    },

    verifyIdentitySendOTPStep: () => {
      const DDOEvent = {
        category: {
          primaryCategory: OVERLAY,
        },
        eventInfo: {
          eventName: `${eventName} verify your identity`,
        },
      };
      trackEvent(DDOEvent);
    },

    verifyIdentityIncorrect: () => {
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: MY_ACCOUNT_SETTINGS,
        },
        myAccount: {
          settings: `${eventName} verify your identity: code entered is incorrect error`,
        },
      };
      trackEvent(DDOEvent);
    },

    verifyIdentitySubmit: () => {
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: MY_ACCOUNT_SETTINGS,
        },
        myAccount: {
          settings: `${eventName} verify your identity: verify button`,
        },
      };
      trackEvent(DDOEvent);
    },

    verifyIdentitySuccess: () => {
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: MY_ACCOUNT_SETTINGS,
        },
        myAccount: {
          settings: `${eventName} verify your identity: verify success`,
        },
      };
      trackEvent(DDOEvent);
    },

    verifyIdentityEmailLinkClick: () => {
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: MY_ACCOUNT_SETTINGS,
        },
        myAccount: {
          settings: `${eventName} verify your identity: email me a new code link`,
        },
      };
      trackEvent(DDOEvent);
    },

    createPasswordStep: () => {
      const DDOEvent = {
        category: {
          primaryCategory: OVERLAY,
        },
        eventInfo: {
          eventName: `${eventName} create a password`,
        },
      };
      trackEvent(DDOEvent);
    },

    createPasswordSubmit: () => {
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: MY_ACCOUNT_SETTINGS,
        },
        myAccount: {
          settings: `${eventName} create a password: submit button`,
        },
      };
      trackEvent(DDOEvent);
    },

    createPasswordRequirementsNotMet: () => {
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: MY_ACCOUNT_SETTINGS,
        },
        myAccount: {
          settings: `${eventName} create a password: password does not meet requirements`,
        },
      };
      trackEvent(DDOEvent);
    },
  };

  window.LIFE_CYCLE_EVENT_BUS.on(
    'accept-invite-step',
    acceptInviteEvents.acceptInviteStep,
  );
  window.LIFE_CYCLE_EVENT_BUS.on(
    'verify-identity-send-otp-step',
    acceptInviteEvents.verifyIdentitySendOTPStep,
  );
  window.LIFE_CYCLE_EVENT_BUS.on(
    'verify-identity-incorrect',
    acceptInviteEvents.verifyIdentityIncorrect,
  );
  window.LIFE_CYCLE_EVENT_BUS.on(
    'verify-identity-submit',
    acceptInviteEvents.verifyIdentitySubmit,
  );
  window.LIFE_CYCLE_EVENT_BUS.on(
    'verify-identity-success',
    acceptInviteEvents.verifyIdentitySuccess,
  );
  window.LIFE_CYCLE_EVENT_BUS.on(
    'verify-identity-email-link-click',
    acceptInviteEvents.verifyIdentityEmailLinkClick,
  );
  window.LIFE_CYCLE_EVENT_BUS.on(
    'create-password-step',
    acceptInviteEvents.createPasswordStep,
  );
  window.LIFE_CYCLE_EVENT_BUS.on(
    'create-password-submit',
    acceptInviteEvents.createPasswordSubmit,
  );
  window.LIFE_CYCLE_EVENT_BUS.on(
    'create-password-requirements-not-met',
    acceptInviteEvents.createPasswordRequirementsNotMet,
  );
};
