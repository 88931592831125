import React, { useState, useEffect } from 'react';
import { node, func } from 'prop-types';
import { ThemeContext } from './ThemeContext';

const ThemeProvider = ({ children, theme, ...props }) => {

  const value = {
    theme,
    props
  };
  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: node.isRequired,
  theme: func
};

ThemeProvider.defaultProps = {
  theme: null
};

ThemeProvider.displayName = 'ThemeProvider';

export { ThemeProvider };
