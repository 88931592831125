import digitalData from 'digital-data';
import { isOutOfStockOnlinePod, isDiscontinued } from '../thd-analytics-utils';
import { SBR_COLLECTION } from '../constants';

export const productPodGroup = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('product-see-similar-items.click', ({ output = {} }) => {
    let unfulfillableType = '';
    const { product, parent } = output;

    const oosOnline = isOutOfStockOnlinePod(product);
    const discontinued = isDiscontinued(product);

    if (oosOnline) {
      unfulfillableType = 'out of stock';
    } else if (discontinued) {
      unfulfillableType = 'discontinued';
    }
    if (parent === SBR_COLLECTION) {
      const ddoEvent = {
        category: {
          primaryCategory: 'button submission'
        },
        eventInfo: {
          eventName: 'content module click'
        },
        pageType: 'collections:space'
      };

      ddoEvent.content = {
        widgets: [{
          action: 'expand',
          id: 'n/a',
          name: 'subcontent_decor',
          templates: 'zone b',
          component: 'vis sim',
          element: unfulfillableType
        }]
      };
      digitalData.event = digitalData.event || [];
      digitalData.event.push(ddoEvent);
    }
  });
};