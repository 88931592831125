import digitalData from 'digital-data';

export const aisleBay = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('aisle-bay.open', ({ output }) => {
    const DDOEvent = {
      category: {
        primaryCategory: 'button submission'
      },
      eventInfo: {
      }
    };
    DDOEvent.category.primaryCategory = 'interaction';
    DDOEvent.eventInfo.eventName = 'pip sub-content';
    DDOEvent.subContent = {
      section: 'buybox',
      component: 'custom options',
      target: 'text me'
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('aisle-bay.success', ({ output }) => {
    const DDOEvent = {
      category: {
        primaryCategory: 'button submission'
      },
      eventInfo: {
      }
    };
    DDOEvent.category.primaryCategory = 'button submission';
    DDOEvent.eventInfo.eventName = 'text me';
    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('aisle-bay.error', ({ output }) => {
    const { type, data } = output;
    const DDOEvent = {
      category: {
        primaryCategory: 'button submission'
      },
      eventInfo: {
      }
    };
    const isClientError = data?.code >= 400 && data?.code < 500;
    DDOEvent.category.primaryCategory = 'error';
    DDOEvent.eventInfo.error = {
      code: data?.code || '',
      displayType: 'inline',
      message: data?.message || '',
      pageSection: 'aisle and bay',
      type: isClientError ? 'client' : 'system'
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });
};