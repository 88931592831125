import digitalData from 'digital-data';
import { getState, setState } from '../analytics-state';
import { SORT } from '../constants';

export const sortRefinements = () => {
  let sortInformation = null;
  const getSortAnalyticsEvent = () => {
    const { name, currentPage, page } = sortInformation;
    return {
      category: {
        primaryCategory: page
      },
      eventInfo: {
        eventName: 'sort by'
      },
      displayAttributes: {
        pageNumber: currentPage,
        productsAvailability: 'all products',
        sortBy: name,
        viewType: 'grid'
      }
    };
  };

  window.LIFE_CYCLE_EVENT_BUS.on('sort-refinements.publish', () => {
    if (sortInformation) {
      digitalData.event = digitalData.event || [];
      digitalData.event.push(getSortAnalyticsEvent());
      sortInformation = null;
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('sort-refinements.click', function ({ output }) {
    sortInformation = output;
    setState({
      ...getState(),
      pageTransitionEvent: SORT
    });
  });
};