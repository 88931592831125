import digitalData from 'digital-data';
import { STASH } from '../constants';

const publishStashLink = () => {
  const page = digitalData?.page.pageInfo.pageName || 'unknown';
  const settings = page + ': addresses: view all';
  const DDO = {
    category: {
      primaryCategory: STASH
    },
    eventMode: STASH,
    eventInfo: {
      eventName: 'my account settings',
    },
    stash: {
      myAccount: { settings }
    }
  };

  digitalData.event = digitalData.event || [];
  digitalData.event.push(DDO);
};

// https://github.com/one-thd/ocm_pd_component_shipping-address-card
export const shippingAddressCard = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('shipping-address-card-view-all.click', publishStashLink);
};
