import digitalData from 'digital-data';

export const saveToFavoritesAnalytics = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('save-to-favorites.track-click', () => {
    const DDOEvent = {
      category: { primaryCategory: 'interaction' },
      eventInfo: { eventName: 'pip sub-content' },
      subContent: { section: 'sharing', component: 'system', target: 'save', position: 'n/a' }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('save-to-favorites.track-overlay', () => {
    const DDOEvent = {
      category: { primaryCategory: 'overlay' },
      eventInfo: { eventName: 'choose a list' }
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('save-to-favorites.create-a-list-with-item', ({ output }) => {
    const { creationLocation = 'n/a', quantity = 'n/a', price = 'n/a', sku = 'n/a' } = output;

    const DDOEvent = {
      category: { primaryCategory: 'interaction' },
      eventInfo: { eventName: 'create list' },
      myList: { creationLocation },
      item: [{
        listLocation: window?.digitalData?.page?.pageInfo?.pageType,
        quantity: quantity || 'n/a',
        price: { basePrice: price || 'n/a' },
        productInfo: { sku: sku || 'n/a' }
      }]
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('save-to-favorites.create-a-list-with-products', ({ output }) => {
    const { creationLocation = 'n/a', listItem = [] } = output;

    const DDOEvent = {
      category: { primaryCategory: 'interaction' },
      eventInfo: { eventName: 'create list' },
      myList: { creationLocation },
      item: listItem || []
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('save-to-favorites.track-add-item', ({ output }) => {
    const { quantity = 'n/a', price = 'n/a', sku = 'n/a' } = output;

    const DDOEvent = {
      category: { primaryCategory: 'button submission' },
      eventInfo: { eventName: 'list add' },
      item: [{
        listLocation: window?.digitalData?.page?.pageInfo?.pageType,
        quantity: output.quantity ? quantity : 'n/a',
        price: { basePrice: price || 'n/a' },
        productInfo: { sku: sku || 'n/a' }
      }]
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('save-to-favorites.track-add-products', ({ output }) => {
    const { listItem = [] } = output;

    const DDOEvent = {
      category: { primaryCategory: 'button submission' },
      eventInfo: { eventName: 'list add' },
      item: listItem
    };

    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });
};
