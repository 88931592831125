import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';

export function exchange() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('exchange.ready', ({ output = {} }) => {
    setAnalytics(digitalData);
    if (digitalData.site) {
      const allowedDomains = window.cookieUtils?.readBrowserCookie('allowed_domains');
      if (allowedDomains) {
        digitalData.site.integration = allowedDomains.toLowerCase();
      }
    }
  });
}
