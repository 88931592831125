/* globals newrelic */
import { setState, getState } from '../state';

export const timeouts = () => {
  if (typeof window.__TIMEOUT_ERRORS !== 'undefined' && window.__TIMEOUT_ERRORS.length) {

    const { pathname } = window.location;
    window.__TIMEOUT_ERRORS.forEach((timeoutMessage) => {
      let message = '';
      let traceHeader = '';
      if (typeof timeoutMessage === 'string') {
        message = timeoutMessage;
      } else if (typeof timeoutMessage === 'object') {
        message = timeoutMessage.message;
        traceHeader = timeoutMessage.traceHeader;
      }
      newrelic.addPageAction('TIMEOUT_ERROR', {
        timeoutMessage: message,
        traceHeader,
        pathname
      });
    });

  }
};
