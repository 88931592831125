import digitalData from 'digital-data';
import { isDuplicatePageError, isDuplicateEventError, pathToPageSection } from '../thd-analytics-utils';

export const fulfillment = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('fulfillment.advisory', ({ output = {} }) => {
    const {
      message = '',
      path = []
    } = output;

    const pageError = {
      type: 'error',
      displayType: 'inline',
      pageSection: pathToPageSection(path),
      message,
      code: null
    };
    digitalData.page = digitalData?.page || {};
    digitalData.page.error = digitalData?.page?.error || [];
    if (!isDuplicatePageError(digitalData, pageError)) {
      // eslint-disable-next-line no-unused-expressions
      digitalData?.page?.error?.push(pageError);
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('fulfillment.postPageAdvisoryEvent', ({ output = {} }) => {
    const {
      message = '',
      path = []
    } = output;

    if (message !== '' && path.length) {
      const pageErrors = [];
      const errorDetails = {
        type: 'error',
        displayType: 'inline',
        pageSection: pathToPageSection(path),
        message,
        code: null
      };
      pageErrors.push(errorDetails);
      const advisoryEvent = {
        category: {
          primaryCategory: 'error'
        },
        eventInfo: {
          eventName: 'post page error',
          error: pageErrors
        }
      };
      if (!isDuplicateEventError(digitalData, pageErrors)) {
        digitalData.event = digitalData.event || [];
        (digitalData.event).push(advisoryEvent);
      }
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('fulfillment.click', ({ output = {} }) => {
    const {
      eventName,
      primaryCategory
    } = output;

    const ddoEvent = {
      eventInfo: {
        eventName
      },
      category: {
        primaryCategory
      }
    };
    digitalData.event.push(ddoEvent);
  });
};