/* global newrelic */
import { setState, getState } from '../state';

export const layoutManager = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('layout-manager.error', ({ output }) => {
    const { error, id, name, componentStack } = output;
    const state = getState() || {};
    if (typeof newrelic !== 'undefined' && error) {
      const { products = {} } = state;
      const { currentItemId: itemId } = products;
      newrelic.noticeError(error);
      window.newrelic.addPageAction('ComponentError', {
        id: id || name,
        name: error.name,
        message: error.message,
        stack: error.stack
      });
    }
  });
};
