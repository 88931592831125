import digitalData from 'digital-data';

const reportSelectStore = () => {
  const thdAnalyticsEvent = {
    category: {
      primaryCategory: 'button submission'
    },
    eventInfo: {
      eventName: 'make this my store'
    }
  };

  digitalData.event = digitalData.event || [];
  digitalData.event.push(thdAnalyticsEvent);
};

export const storePod = () => {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('store-pod.select-store', reportSelectStore);
};