import deepExtend from 'deep-extend';

const CONTEXT_KEY = Symbol.for('@thd-olt-component-react/layout-manager/state');
const globalSymbols = Object.getOwnPropertySymbols(global);
const hasState = (globalSymbols.indexOf(CONTEXT_KEY) > -1);

if (!hasState) {
  global[CONTEXT_KEY] = {};
}

const state = global[CONTEXT_KEY];

export const getState = () => {
  return state;
};

export const setState = (newState) => {
  deepExtend(state, newState);
  return state;
};
