import digitalData from 'digital-data';

import {
  STASH, BUTTON_SUBMISSION, MODULE_CLICK, PIP, CLICKTHROUGH, SUBCONTENTDECOR
} from '../constants';

export const educationalSummary = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('educational-summary.click', ({ output = {} }) => {
    const {
      location, text
    } = output;

    let eventCategory;

    if (location === 'video') {
      eventCategory = BUTTON_SUBMISSION;
    } else {
      eventCategory = STASH;
    }

    const ddoEvent = {
      category: {
        primaryCategory: eventCategory
      },
      eventInfo: {
        eventName: MODULE_CLICK
      },
      pageType: PIP,
      content: {
        widgets: [
          {
            action: CLICKTHROUGH,
            id: null,
            name: SUBCONTENTDECOR,
            templates: 'zone b',
            component: 'educational link',
            element: text
          }
        ]
      }
    };
    digitalData.event.push(ddoEvent);
  });
};