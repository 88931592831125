/* global newrelic */
import { setState, getState } from '../state';

export const performanceListener = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('performance.component-load', ({ output }) => {
    const { startTime, endTime, componentName } = output;
    newrelic.addPageAction('ComponentPerformance', {
      componentStart: startTime,
      componentEnd: endTime,
      componentName
    });
  });
};
