import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';
import { BDS, BDS_PAGE_TYPE } from '../../../constants';

export function bathLandingPage() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('bathLandingPage.page-load', () => {
    digitalData.page = {
      pageInfo: {
        pageName: BDS,
        pageType: BDS_PAGE_TYPE
      },
      category: {
        primaryCategory: BDS,
        subCategory1: BDS,
        subCategory2: BDS
      }
    };
    setAnalytics(digitalData);
  });
}