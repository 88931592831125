import digitalData from 'digital-data';
import { setAnalytics } from '../../../thd-analytics-utils';

export const shopByRoom = () => {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('sbr.page-load', () => {
    digitalData.page = {
      pageInfo: {
        pageName: 'shop by room',
        pageType: 'clp'
      },
      category: {
        primaryCategory: 'shop by room',
        subCategory1: 'shop by room',
        subCategory2: 'shop by room'
      }
    };
    setAnalytics(digitalData);
  });

  // Uncomment to add analytics for filter selection.
  //   LIFE_CYCLE_EVENT_BUS.on('select-filter', (options) => {
  //     const { styleOptions, colorOptions, roomOptions } = options.output;
  //     let optionsObj = {};
  //     // eslint-disable-next-line no-restricted-syntax
  //     for (const [key, value] of Object.entries(options.output)) {
  //       if (value.length > 0) {
  //         let appendObj = { key, value };
  //         optionsObj = { ...optionsObj, ...appendObj };
  //       }
  //     }
  //     const filterObj = {
  //       eventInfo: {
  //         eventName: 'filter'
  //       },
  //       category: {
  //         primaryCategory: 'button submission'
  //       },
  //       filter: [
  //         { name: 'room', values: roomOptions },
  //         { name: 'style', values: styleOptions },
  //         { name: 'color', values: colorOptions }
  //       ]
  //     };
  //     digitalData.event.push(filterObj);
  //   });
};