/* eslint-disable camelcase */
import digitalData from 'digital-data';

export const decisionEngine = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('decision-engine.api-call', ({ output }) => {
    const {
      loading,
      error,
      post_de
    } = output;

    // This currently only supports one DE campaign per page
    // This will need to be reworked when we have multiple campaigns on a page
    if (!loading && !error && post_de) {
      digitalData.page = digitalData.page || {};
      digitalData.page.decisionEngine = { post_de };
    }
  });
};