import digitalData from 'digital-data';
import {
  SIZE_GUIDE,
  PIP,
  CLICKTHROUGH,
  SUBCONTENTDECOR,
  DROPDOWN,
  NOT_APPLICABLE,
  BUTTON_SUBMISSION,
  MODULE_CLICK
} from '../constants';

export const sizeGuide = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('size-guide.click', ({ output = {} }) => {
    const {
      productType = ''
    } = output;

    const ddoEvent = {
      category: {
        primaryCategory: productType ? BUTTON_SUBMISSION : 'overlay'
      },
      eventInfo: {
        eventName: productType ? MODULE_CLICK : 'size guide'
      }
    };

    if (productType) {
      ddoEvent.pageType = PIP;
      ddoEvent.content = {
        widgets: [{
          action: CLICKTHROUGH,
          id: NOT_APPLICABLE,
          name: SUBCONTENTDECOR,
          templates: SIZE_GUIDE,
          component: DROPDOWN,
          element: productType
        }]
      };
    }

    digitalData.event = digitalData.event || [];
    (digitalData.event).push(ddoEvent);
  });
};