import digitalData from 'digital-data';
import {
  PIP_SUB_CONTENT,
  INTERACTION
} from '../constants';

export const stickyNav = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('sticky-nav.click', ({ output = {} }) => {
    const { target = '' } = output;
    const ddoEvent = {
      eventInfo: {
        eventName: PIP_SUB_CONTENT
      },
      category: {
        primaryCategory: INTERACTION
      },
      subContent: {
        section: 'sticky-nav',
        component: 'nav-menu-item',
        target
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};