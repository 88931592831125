import digitalData from 'digital-data';

export const instantCheckout = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('instant-checkout.load', (payload) => {
    const { ICTemplate = '' } = payload?.output || {};
    const instantCheckoutDisplayed = ICTemplate === 'IC' ? 'y' : 'n';
    digitalData.content = digitalData.content || {};
    digitalData.content.feature = { instantCheckoutDisplayed };
  });

  window.LIFE_CYCLE_EVENT_BUS.on('instant-checkout.click', (payload) => {
    digitalData.event = digitalData.event || {};
    const event = {
      category: {
        primaryCategory: 'button submission'
      },
      eventInfo: {
        eventName: 'instant checkout'
      }
    };
    if (payload?.cartViewType) {
      event.cartViewType = payload?.cartViewType;
    }
    digitalData.event.push(event);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('instant-checkout.viewModified', () => {
    digitalData.event = digitalData.event || {};
    const event = {
      category: {
        primaryCategory: 'content display'
      },
      eventInfo: {
        eventName: 'instant checkout impression'
      }
    };
    digitalData.event.push(event);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('instant-checkout.error', (payload) => {
    digitalData.page = digitalData.page || {};
    digitalData.page.error = digitalData.page.error || [];
    const { code = '', message = '', pageSection = '' } = payload?.output || {};
    const error = {
      code,
      displayType: 'inline',
      message,
      pageSection,
      type: 'error'
    };
    digitalData.page.error.push(error);
  });
};