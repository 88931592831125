import digitalData from 'digital-data';

export const movingCalculator = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('moving-calculator.calculate', ({ output = {} }) => {
    const { calculatorType } = output;
    const thdAnalyticsEvent = {
      category: {
        primaryCategory: 'button submission'
      },
      eventInfo: {
        eventName: 'calculate'
      },
      calculate: {
        type: calculatorType
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(thdAnalyticsEvent);
  });
};