import React, { Component } from 'react';
import { oneOfType, node, arrayOf, bool } from 'prop-types';
import { AppError } from './AppError';

class AppErrorHandler extends Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
      LIFE_CYCLE_EVENT_BUS.trigger('app-render.uncaught_error', { error, errorInfo });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children, debug } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return <AppError debug={debug} />;
    }

    return children;
  }
}

AppErrorHandler.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node
  ]).isRequired,
  debug: bool,
};

AppErrorHandler.defaultProps = {
  debug: false,
};

export { AppErrorHandler };
