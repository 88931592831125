import digitalData from 'digital-data';
import { BUTTON_SUBMISSION } from '../constants';

export const leadGeneration = () => {
  window.LIFE_CYCLE_EVENT_BUS.on(
    'lead-generation.request-a-quote-action', ({ output: { programCategory = '' } = {} }) => {

      const leadGenerationEvent = {
        category: { primaryCategory: BUTTON_SUBMISSION },
        eventInfo: { eventName: 'request a quote' },
        form: { formName: programCategory, formType: 'installation services and repair' },
      };
      digitalData.event = digitalData.event || [];
      (digitalData.event).push(leadGenerationEvent);
    }
  );
};

export function articlesLeadGen() {
  window.LIFE_CYCLE_EVENT_BUS.on('article-lead-generation.get-a-free-consultation', ({ output = {} }) => {
    const { leadgen } = output;
    digitalData.page.pageInfo.abTest = leadgen;
  });
}