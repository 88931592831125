const matchPath = require('./matchPath');

function getCurrentRouteName({ path, routes, channel }) {
  const keys = Object.keys(routes);

  for (let i = 0; i < keys.length; i += 1) {
    let key = keys[i];
    let route = routes[key];
    if (matchPath(path, { path: route.path, strict: false })) {
      if (route.channel && route.channel === channel) return route.name;
      if (!route.channel) return route.name;
    }
  }

  return null;
}

function createPrefetchTag(url) {
  if (url.split('.').slice(-1)[0] !== 'js') return;

  const linkTag = document.createElement('link');
  linkTag.rel = 'prefetch';
  linkTag.href = url;
  linkTag.as = 'script';
  document.head.appendChild(linkTag);
}

function getRouteKeysToPrefetch({ desiredRouteKeysToPrefetch, routes, currentRouteName, channel }) {
  return desiredRouteKeysToPrefetch.filter((routeKey) => {
    const route = routes[routeKey];
    if (!route) return false;
    if (route.name === currentRouteName) return false;
    if (route.channel && route.channel !== channel) return false;
    return true;
  });
}

async function addPrefetchTagsToHead({
  currentRouteName, routes, asyncChunks, channel, currentRouteKey, delay
}) {
  const desiredRouteKeysToPrefetch = routes?.[currentRouteKey]?.prefetch || [];

  if (!desiredRouteKeysToPrefetch.length) return;
  setTimeout(() => {
    // eslint-disable-next-line no-new
    new Promise((resolve) => {
      const routeKeysToPrefetch = getRouteKeysToPrefetch({
        desiredRouteKeysToPrefetch,
        routes,
        currentRouteName,
        channel
      });
      const routeNamesToPrefetch = routeKeysToPrefetch.map((key) => routes[key]?.name);
      const asyncChunkPaths = routeNamesToPrefetch.map((name) => asyncChunks[name] || []).flat(1);
      const deDupedAsyncChunkPaths = [...new Set(asyncChunkPaths)];

      deDupedAsyncChunkPaths.forEach(createPrefetchTag);

      resolve();
    });
  }, delay);
}

async function initAsyncRouting({
  currentRouteName, routes, asyncChunks, channel, delay = 2000
} = {}) {
  const currentRouteKey = Object.keys(routes).find((key) => routes[key].name === currentRouteName);
  const currentRoute = routes[currentRouteKey];
  const componentModule = await currentRoute.chunk();

  addPrefetchTagsToHead({
    currentRouteName, routes, asyncChunks, channel, currentRouteKey, delay
  });

  return componentModule[currentRoute.componentName];
}

module.exports = {
  getCurrentRouteName,
  initAsyncRouting
};
