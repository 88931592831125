import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import {
  INTERACTION, INSTANT_RECOMMENDATION, STASH, RECOMMENDATION_EVENTNAME, INSTANT_RECS_SCHEMA,
  NOT_APPLICABLE, RECOMMENDATION_VERSION
} from '../constants';

export const instantRecs = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('instant-recs-like-dislike.click', ({ output }) => {
    const { sku, status } = output || {};
    const { pageItemId = '' } = getState() || {};
    const instantRecommendationLikeEvent = {
      category: {
        primaryCategory: INTERACTION
      },
      eventInfo: {
        eventName: INSTANT_RECOMMENDATION
      },
      recommendation: {
        anchorProductSku: pageItemId,
        sku,
        status
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(instantRecommendationLikeEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('instant-recs-image.click', ({ output }) => {
    const {
      position
    } = output || {};
    const { pageItemId = '', pageType = null } = getState() || {};
    const instantRecsClickEvent = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: RECOMMENDATION_EVENTNAME
      },
      eventMode: STASH,
      stash: {
        recommendation: {
          recommendationType: INSTANT_RECS_SCHEMA,
          pageType,
          displayPosition: position,
          anchorProductSku: pageItemId,
          featureVersion: NOT_APPLICABLE,
          strategy: NOT_APPLICABLE,
          recommendationVersion: RECOMMENDATION_VERSION,
          recommendedProduct: NOT_APPLICABLE,
          interval: NOT_APPLICABLE
        }
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(instantRecsClickEvent);
  });
};