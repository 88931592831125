import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import { getCollectionPageType } from '../experience/pages/collections/collections';
import {
  STASH,
  MODULE_CLICK,
  CLICKTHROUGH,
  SUBCONTENTDECOR,
  BUTTON_SUBMISSION,
  NOT_APPLICABLE,
  COLLECTION
} from '../constants';

export const tabs = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('tabs.click', ({ output = {} }) => {
    const {
      text,
      // path,
    } = output;

    const category = 'tab';
    const state = getState();
    const { pageType, dataSources } = state || {};
    const isCollection = pageType === COLLECTION;
    const type = dataSources?.collection?.metadata?.type;

    const ddoEvent = {
      category: {
        primaryCategory: isCollection ? BUTTON_SUBMISSION : STASH
      },
      eventInfo: {
        eventName: MODULE_CLICK
      },
      pageType: isCollection ? `collections:${getCollectionPageType({ type })}` : pageType,
      content: {
        widgets: [
          {
            action: CLICKTHROUGH,
            id: NOT_APPLICABLE,
            name: SUBCONTENTDECOR, // @todo figure out analytics requirements
            templates: 'zone b', // @todo figure out from path
            component: category,
            element: text
          }
        ]
      }
    };
    // @todo need validate we can delete
    // looks like was always passed as false
    // if (stash) {
    //   ddoEvent.eventMode = 'stash';
    //   ddoEvent.stash = {
    //     content: ddoEvent.content
    //   };
    //   delete ddoEvent.content;
    // }
    if (digitalData && digitalData.event) digitalData.event.push(ddoEvent);
  });
};